import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { StylesProvider } from "@mui/styles";
import { Provider } from "react-redux";
import {
    createTheme,
    ThemeProvider,
    responsiveFontSizes,
    StyledEngineProvider,
} from "@mui/material/styles";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { CssBaseline } from "@mui/material";

import "./App.css";
import Root from "./root";
import store from "./store/redux-saga";
import SingletonModule from "./SingletonModule";

const darkTheme = createTheme({
    components: {
        MuiMenu: {
            styleOverrides: {
                paper: {
                    background: "none !important",
                    backdropFilter: "blur(20px)",
                },
            },
        },
    },
    palette: {
        text:
            {
                labelBright: "white",
                label: "#e0e0e0",
                labelDull: "grey",
            },
        success: {
            main: "#5AC491",
        },
        warning: {
            main: "#E8C342",
        },
        error: {
            main: "#E84242",
        },
        update2Palette: {
            successClr: "#5AC491",
            warning: "#E8C342",
            error: "#E84242",
            secondaryColor: "#2A4C4B", //dark greenish
            secondaryColorLight: "#76D0D5", //light turquise
            grayClr: "#A9A9A9",

            // Dark Mode
            mainBgGradient2Dark:
        "linear-gradient(180deg, #2E0035 0%, #160A1D 50%, #2A4C4B 100%)",
            selectedTabBgGradient2Dark:
        "linear-gradient(45deg, #68576a, #68576a,  #68576a, #452b4b) padding-box, linear-gradient(45deg, rgba(21, 21, 21, 1), rgba(231, 206, 74, 1), rgba(255, 255, 255, 0.2)) border-box",
            bgGradient2BcDrop: {
                background:
          "linear-gradient(40deg, rgb(236 229 229 / 13%), rgb(0 0 0 / 0%)) !important",
                backdropFilter: "blur(20px) !important",
            },
            bgGradient2:
        "linear-gradient(40deg, rgb(236 229 229 / 13%), rgb(0 0 0 / 0%)) !important",

            // Light Mode
            mainBgGradient2Light:
        "linear-gradient(180deg, rgba(153, 14, 174, 0.15) 0%, rgba(255, 255, 255, 0.15) 50%, rgba(118, 208, 213, 0.15) 100%) !important",
           
        },
       
    },
});

SingletonModule.loadRuntimeData();

const _darkTheme = responsiveFontSizes(darkTheme);
const cache = createCache({
    key: "css",
    prepend: true,
});
// <StylesProvider injectFirst> //import from @mui/styles
// StyledEngineProvider injectFirst
function App() {
    const endAtDev = window.location.href.slice(-3);
    // if (
    //   window.location.href != "http://localhost:3000/" &&
    //   !window.location.href.includes(
    //     "glitter-finance-explorer-frontend.pages.dev"
    //   ) &&
    //   endAtDev != "dev"
    // ) {
    //   window.location.href = "https://bridge.glitterfinance.org/";
    // }
    return (
        <CacheProvider value={cache}>
            <CssBaseline />
            <ThemeProvider theme={_darkTheme}>
                {/* <StylesProvider> */}
                <Provider store={store}>
                    <BrowserRouter>
                        <Routes>
                            <Route path="*" element={<Root />} />
                        </Routes>
                    </BrowserRouter>
                </Provider>
                {/* </StylesProvider> */}
                <NotificationContainer />
            </ThemeProvider>
        </CacheProvider>
    );
}

export default App;
