import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme, { isDark }) => ({
  backContainer: {
    display: "flex",
    justifyContent: "start",
    alignItems: "start",
    flexDirection: "column",
  },
  sourceClass: {
    fontWeight: "800",
    fontStyle: "normal",
    fontSize: "1.8rem !important",
    fontFamily: "ProximaNova",
    lineHeight: "41px",
    textAlign: "left",
    marginBottom: "20px",

    [theme.breakpoints.down("md")]: {
      fontSize: "1.4rem",
      lineHeight: "1.3",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "26px",
      lineHeight: "31px",
      textAlign: "left",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      lineHeight: "1",
      marginBottom: "20px",
    },
  },
  chipItem: {
    color: "rgba(118, 208, 213, 1)",
    border: "1px solid rgba(118, 208, 213, 1)",
    "&:hover": {
      background: "rgba(118, 208, 213, 0.1)", 
      cursor: "pointer",
    }
  },
  tileTitle: {
    color: "aliceblue",
    opacity: 0.6,
    fontSize: "14px",
    fontStyle: "normal",
    fontFamily: "Barlow",
    fontWeight: 600,
    lineHeight: "16px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px",
      lineHeight: "10px",
    },
  },
  tokenName: {
    color: "rgba(255, 255, 255, 0.5)",
    fontWeight: "400 !important",
  },
  viewTxBtn: {
    height: "30px",
    width: "30px",
    background: isDark
      ? "linear-gradient(145deg, rgb(255 255 255 / 13%) 7.41%, rgb(22 22 22 / 18%) 93.56%)"
      : "",
    border: !isDark ? "1px solid #62686e" : "1px solid rgb(118 208 213 / 29%)",
    color: "#76D0D5",
    borderRadius: "10px",
    marginRight: "10px",
  },
  loaderIcon: {
    width: "33px !important",
    height: "15px !important",
    color: isDark ? "rgba(118, 208, 213, 1) !important" : "#000 !important",
    padding: "0px 5px !important",
  },
}));

export default styles;
