import React from 'react';
import { Box, Typography, useTheme, CardMedia, Tooltip } from '@mui/material';
import { CardTemplate, useCardStyles } from "./common";
import SingletonModule from "../../SingletonModule";
import { 
    getStatusIcon,
    getStatusIconClass,
    getStatusSubText,
    getStatusTime,
    getAverageTimeText,
    checkStatusTimeColor } from "../../utils/apiutils";

export default function StatusCard({ tx }) {
    const theme = useTheme();
    const classes = useCardStyles(theme);

    return (
        <CardTemplate cardLabel="Status:">

            <Box sx={{ display: 'flex', flexDirection: 'column', padding: "8px" }}>

                {/* Protocol panel */}
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>

                    {/* Status logo */}
                    <Tooltip title={tx.status} >
                        <div>
                            <CardMedia className={getStatusIconClass(tx.status)}
                                component="img"
                                sx={{ width: 48, height: 48, padding: "8px" }}
                                image={getStatusIcon(tx.status)}
                                alt="Live from space album cover"
                            />
                        </div>
                    </Tooltip>
              
                    {/* Status Text + Time */}
                    <Box sx={{ display: 'flex', flexDirection: 'column', padding:"4px" }}>
                        <Typography paddingBottom="4px" className={classes.cardLabel}>
                            {getStatusSubText(tx.status, tx.percent)}
                        </Typography>
                        <Typography color={checkStatusTimeColor(tx.status, tx.initDate, tx.baseToken, tx.initNetwork, tx.completedNetwork)} minHeight="16px" fontSize="0.85em">
                            {getStatusTime(tx.status, tx.timeToCompletion, tx.initDate, tx.baseToken, tx.initNetwork, tx.completedNetwork)} &nbsp;
                        </Typography>
                    </Box>                  

                </Box>

                {/* Average Time */}
                <Box className = {classes.textBoxRow} >                     
                    <Typography className={classes.cardTextDull} >
                    [Avg {tx.baseToken} Tx from {tx.initNetwork} to {tx.completedNetwork} takes {getAverageTimeText(tx.baseToken, tx.initNetwork, tx.completedNetwork)}]
                    </Typography>
                </Box>
                
                {/* Details */}
                <Box className = {classes.textBoxRow} >                                                                  
                    <Typography className={classes.cardText} color={SingletonModule.designColors.lightGrey} lineHeight= '1.4em'>Details:  </Typography>
                    <Typography className={classes.cardText} >  
                        {'\u00A0'}{tx.statusDescription}  &nbsp;
                    </Typography>    
                                
                </Box>
            </Box>

        </CardTemplate>
    );
}
