import { createSlice } from "@reduxjs/toolkit";
import { NotificationManager } from "react-notifications";

const slice = createSlice({
    name: "allTxs",
    initialState: {
        loading: false,
        accounts: [],
        txDetails: {
            txs: [],
            totalCount: 0,
        },
        error: "",
    },
    reducers: {
        getAddressProfile: (state) => {
            state.loading = true;
        },
        getAddressProfileSuccess: (state, { payload }) => {
            state.loading = false;
            if (payload) {
                state.accounts = payload?.addresses;
                state.txDetails.txs = payload?.txDetails?.transactions;
                state.txDetails.totalCount = payload?.txDetails?.totalCount;
            }
        },
        getAddressProfileFailed: (state) => {
            state.loading = false;
            state.accounts = [];
            state.txDetails = { txs: [], totalCount: 0 };
        },
    },
});

export const {
    getAddressProfile,
    getAddressProfileSuccess,
    getAddressProfileFailed,
} = slice.actions;

export default slice.reducer;
