import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Card, CardContent, Typography, useMediaQuery, useTheme, CardMedia } from '@mui/material';

const baseText = {
    fontSize: '14px',
    textAlign: 'left',
    fontStyle: 'normal',
    fontFamily: 'Barlow'
};
const textBox = {
    display: 'flex',
    padding:"4px"
};

export const useCardStyles = makeStyles((theme) => {
    return {
        transparentCard: {
            background: 'transparent',
            borderBottom: `1px solid ${"#76d0d582"}`,
            borderLeft: 'none',
            borderRight: 'none',
            borderTop: 'none',
            boxShadow: 'none',
            borderRadius: 0,
        },
        cardLabel: {
            ...baseText,
            color: 'white',
            fontWeight: 600,
            lineHeight: '16px',
            wordWrap: 'break-word',
        },
        cardText: {
            ...baseText,
            color: theme.palette.text.label,
        },
        cardTextDull: {
            ...baseText,
            color: theme.palette.text.labelDull,
        },
        cardTextBright: {
            ...baseText,
            color: theme.palette.text.labelBright,
            fontWeight: 550,
        },
        cardLink: {
            ...baseText,
            color: theme.palette.success.main,
            fontWeight: 500,
            '&:hover': {
                color: 'SkyBlue !important',
            },
        },
        
        textBoxRow:{
            ...textBox,
            flexDirection: 'row'
        },
        textBoxColumn:{
            ...textBox,
            flexDirection: 'column'
        }
    }
});

export function CardTemplate({ cardLabel, children }) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useCardStyles(theme);

    return (
        <Card sx={{ display: 'flex', padding: '12px' }} className={classes.transparentCard}>

            {/* Left Label (Non-Mobile) */}
            {!isMobile && (
                <CardContent sx={{ width: '125px', minWidth: '125px' }}>
                    <Typography className={classes.cardLabel}>{cardLabel}</Typography>
                </CardContent>
            )}

            {/* Main Content */}
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {/* Top Label (Mobile) */}
                {isMobile && (
                    <CardContent sx={{ padding: '4px' }}>
                        <Typography className={classes.cardLabel}>{cardLabel}</Typography>
                    </CardContent>
                )}

                {/* Protocol panel */}
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    {children}
                </Box>
            </Box>
        </Card>
        
    );
  
}
