import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme, { isDark }) => ({
    backContainer: {
        display: "flex",
        justifyContent: "start",
        alignItems: "start",
        flexDirection: "column",
    },
    sourceClass: {
        fontWeight: "800",
        fontStyle: "normal",
        fontSize: "1.8rem !important",
        fontFamily: "ProximaNova",
        textAlign: "left",
        marginBottom: "1px",

        [theme.breakpoints.down("lg")]: {
            fontSize: "1.1rem !important",
            lineHeight: "1.3",
        },
        [theme.breakpoints.down("md")]: {
            fontSize: "26px !important",
            lineHeight: "31px",
            textAlign: "left",
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "18px !important",
            lineHeight: "1",
            marginBottom: "20px",
        },
    },
    dateText: {
        color: "#8186a2 !important",
        fontSize: "12px !important",
    },
    statusIcon: {
        fontSize: "4.5rem",
    },
    statusIcons: {
        fontSize: "1.3rem",
        margin: "0 0.5rem",
        color: "gray",
    },
    statusTitle: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        fontSize: "1rem",
        lineHeight: "1.5",
        fontWeight: "700 !important",
    },

    // Status Bar
    statusContainer: {
        padding: "20px",
        borderBottom: "1px solid rgba(118, 208, 213, 0.51)",
        [theme.breakpoints.down("sm")]: {
            padding: "15px 0px",
        },
    },
    statusBar: {
        height: "50px",
        borderRadius: "5px",
        width: "100%",
    },
    loaderIcon: {
        width: "21px !important",
        height: "21px !important",
        color: isDark ? "#fff !important" : "#000 !important",
        padding: "2px !important",
        border: "1px solid #ffffff69 !important",
        borderRadius: "50% !important",
    },
    // activeBtn: {
    //     fontFamily: "Montserrat",
    //     fontSize: "12px",
    //     //color: "#76D0D5",
    //     //fontWeight: "700",
    //     //border: "1px solid transparent",
    //     borderRadius: 25,
    //     textAlign: "left",
    //     paddingRight: "10px",
    //     margin: "2px 0px",
    //     fontWeight: 500,
    //     background: "transparent",
    //     border: !isDark ? "1px solid #62686e" : "1px solid rgb(118 208 213 / 29%)",
    //     color: "#76D0D5",
    //     textDecoration: "none !important",
    //     [theme.breakpoints.down("md")]: {
    //         fontSize: "16px",
    //     },
    //     [theme.breakpoints.down("sm")]: {
    //         fontSize: "8px",
    //         height: "20px",
    //         padding: "2px 0px",
    //         paddingRight: "8px",
    //     },
    // },
    backicon: {
        [theme.breakpoints.down("sm")]: {
            fontSize: "1rem",
            padding: "0px",
            paddingLeft: "-1px",
            margin: "0px",
        },
    },
    backLink: {
        textDecoration: "none !important",
    },
    activeBtn: {
        marginTop: "1%",
        fontFamily: "Montserrat",
        fontSize: "15px",
        lineHeight: "21px",
        color: "#76D0D5",
        fontWeight: "700",
        padding: "7px",
        paddingRight: "20px",
        paddingLeft: "20px",
        border: "1px solid transparent",
        borderRadius: 25,
        marginLeft: 10,
        [theme.breakpoints.down("sm")]: {
            marginLeft: 0,
            marginRight: "0 !important",
            fontSize: "16px",
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: 10,
            padding: "6px",
            paddingRight: "14px",
            paddingLeft: "14px",
            width: "39px",
            height: "30px",
        },
    },
    viewTxBtn: {
        background:
      "linear-gradient(145deg, rgb(255 255 255 / 13%) 7.41%, rgb(22 22 22 / 18%) 93.56%)",
        border: "1px solid rgb(118 208 213 / 29%)",
        color: "#76D0D5",
        marginTop: 20,
    },
}));

export default styles;
