import { Grid, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";

import useStyles from "./TxProfileStyles";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getTxProfile } from "./reducers";
import Others from "../../reuseable/Others/Others";
import TxOverview from "./components/TxOverview";
import { truncateStr } from "../../utils/apiutils";
import { StandardSkeleton } from "../../reuseable/TblChunks";
import { ProgressBar } from "react-loader-spinner";

export default function TxProfile({ isDark = true }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const { BackButton, CopyToClipboard } = Others({ isDark });
    const { classes } = useStyles({ isDark });
    const smallPoint = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    const { tx, loading: txLoading } = useSelector((state) => state.txProfile);
    const [isLoading, setIsLoading] = useState(false);
    const [network] = useState("mainnet");

    useEffect(() => {
        setIsLoading(true);
        if (params?.id.length > 0) {
            dispatch(getTxProfile({ txHash: params?.id }));
            setTimeout(() => {
                setIsLoading(false);
            }, 1000);
        } else {
            navigate(`/notfound/${params?.id}`);
        }
    }, []);

    if (isLoading) {
        return (
            <ProgressBar
                height="80"
                width="80"
                ariaLabel="progress-bar-loading"
                wrapperStyle={{}}
                wrapperClass="progress-bar-wrapper"
                borderColor="rgb(118, 208, 213)"
                barColor="#ffffff"
            />
        );
    }

    return (
        <>
            <Grid className={classes.backContainer}>
                <BackButton newTab={false} text="Back" />
            </Grid>
            <Typography
                variant="h6"
                align="center"
                textTransform="uppercase"
                className={classes.sourceClass}
                sx={{
                    color: isDark ? "#ffffff" : "#000",
                    marginTop: "10px",
                }}
            >
        Transaction Details:
            </Typography>
            <Grid sx={{ textAlign: "left" }}>
                <Typography
                    component="span"
                    variant="p"
                    className={classes.tileTitle}
                    style={{ color: "#fff" }}
                >
          Tx Hash: &nbsp;
                </Typography>
                {txLoading ? (
                    <StandardSkeleton h={40} />
                ) : (
                    <>
                        <Typography
                            component="span"
                            variant="p"
                            className={classes.tileTitle}
                            // sx={{ wordWrap: "break-word" }}
                        >
                            {smallPoint ? truncateStr(params?.id || "", 25) : params?.id}
                        </Typography>
                        <CopyToClipboard text={params?.id} />
                    </>
                )}
            </Grid>

            {/* TRANSACTION Overview */}
            <TxOverview
                isDark={isDark}
                tx={tx}
                txLoading={txLoading}
                network={network}
            />
        </>
    );
}
