import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LogarithmicScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useMediaQuery } from "@mui/material";

ChartJS.register(
  LogarithmicScale,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const barOptions = {
  maintainAspectRatio: false,
  indexAxis: "y",
  responsive: true,
};

const BarChart = ({ chartData, type, from }) => {
  const smallPoint = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <Bar
      style={{
        maxHeight: from == "ALL_CHARTS" && !smallPoint ? "100%" : "300px",
        width: from == "ALL_CHARTS" && !smallPoint ? "100%" : "400px",
      }}
      options={barOptions}
      data={chartData}
    />
  );
};

export default BarChart;
