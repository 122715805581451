import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme, { isDark, txs, browserName }) => {
    return {
        paper: {
            width: "100%",
            margin: "auto",
            padding: "0px",
            marginTop: "0%",
            marginBottom: "5%",
            borderRadius: "17px",
            boxShadow: isDark ? "" : "0px 1px 12px rgb(0 0 0 / 15%)",
            [theme.breakpoints.down("md")]: {
                width: "100%",
            },
            [theme.breakpoints.down("sm")]: {
                width: "300px",
            },
        },
        tblHead: {
            height: "50px",
            background:
        browserName === "safari"
            ? "linear-gradient(0deg, rgb(255 254 254 / 13%), rgb(0 0 0 / 0%)) !important"
            : "linear-gradient(2deg, rgb(255 254 254 / 13%), rgb(0 0 0 / 0%)) !important",
        },
        firstCell: {
            borderBottomLeftRadius: "10px",
            borderTopLeftRadius: "10px",
        },
        lastCell: {
            borderBottomRightRadius: "10px",
            borderTopRightRadius: "10px",
        },
        tableContainer: {
            width: "100%",
            margin: "auto",
        },
        tableRoot: {
            postion: "relative",
        },
        tableHeader: {
            paddingRight: "2px ",
            paddingLeft: "2px ",
            paddingBottom: "10px",
            fontWeight: "bold ",
            fontSize: "16px ",
            fontFamily: "Barlow",
            color: isDark ? "#fff" : "#000",
            borderBottom: `0px solid ${isDark ? "#fff" : "#D8DADA"}`,
            textTransform: "uppercase",
            [theme.breakpoints.down("md")]: {
                width: "85px",
                minWidth: "85px",
            },
            "& .MuiTableSortLabel-icon": {
                color: isDark ? "#fff !important" : "#000 !important",
                opacity: "1 !important",
            },
        },

        filterIcon: {
            color: "red !important",
        },
        sortLabelLight: {
            color: "#fff !important",
            "&:hover": {
                color: "#fff !important",
            },
            transition: "all",
            duration: "200ms"
        },
        sortLabelDark: {
            color: "#000 !important",
            "&:hover": {
                color: "#000 !important",
            },
            transition: "all",
            duration: "200ms"
        },
        tableCell: {
            fontSize: 13,
            color: isDark ? "#fff" : "#000",
            padding: "0px 2px",
            borderBottom: `1px solid ${isDark ? "#76d0d582" : "#D8DADA"}`,
            fontFamily: "Barlow",
            textTransform: "capitalize",
            [theme.breakpoints.down("md")]: {
                width: "85px",
                minWidth: "85px",
            },
        },
        buttonIcon: {
            height: "25px",
        },
        tablePaginationRoot: {
            fontFamily: "Barlow",
            marginTop: txs.length ? "0px" : "10%",
            "& .MuiTablePagination-spacer": {
                [theme.breakpoints.down("md")]: {
                    display: "block",
                },
            },
            "& .MuiTablePagination-displayedRows": {
                [theme.breakpoints.down("sm")]: {
                    marginTop: "5px",
                    margin: "0px",
                },
            },
            "& .MuiTablePagination-caption": {
                fontFamily: "Barlow !important",
            },
            "& .MuiTablePagination-actions": {
                [theme.breakpoints.down("sm")]: {
                    marginLeft: "0px",
                },
                "& .MuiButtonBase-root": {
                    background:
            "linear-gradient(145deg, rgb(255 255 255 / 13%) 7.41%, rgb(22 22 22 / 18%) 93.56%)",
                    border: "1px solid rgb(118 208 213 / 29%)",
                    borderRadius: "12px",
                    color: "white",
                    width: "30px",
                    height: "30px",
                    margin: "0px 3px",
                    [theme.breakpoints.down("sm")]: {
                        width: "25px",
                        height: "25px",
                    },
                },
            },
            [theme.breakpoints.down("md")]: {
                marginTop: (props) => (props.txs.length ? "0px" : "20%"),
            },
        },
        tablePaginationToolbar: {
            [theme.breakpoints.down("sm")]: {
                flexDirection: "column",
            },
        },
        tablePaginationLabel: {
            display: "flex",
            alignItems: "center",
            [theme.breakpoints.down("sm")]: {
                margin: 0,
            },
        },
        tablePaginationRootDark: {
            color: "#000000",
            "& .MuiIconButton-root": {
                color: "#000000",
            },
        },
        tablePaginationRootLight: {
            color: "#ffffff",
            "& .MuiIconButton-root": {
                color: "#fff",
            },
        },
        loaderIcon: {
            width: "24px !important",
            height: "24px !important",
            color: isDark ? "#fff" : "#000",
            // padding: "0px 10px !important",
        },
        eyeIcon: {
            padding: "1px !important",
            color: isDark ? "#fff !important" : "#000 !important",
        },
        tileTitle: {
            color: "white",
            fontSize: "14px",
            fontStyle: "normal",
            fontFamily: "Barlow",
            fontWeight: 400,
            lineHeight: "16px",
            [theme.breakpoints.down("md")]: {
                fontSize: "13px",
                lineHeight: "10px",
            },
        },
        forwardBackIcon: {
            background:
        "linear-gradient(145deg, rgb(255 255 255 / 13%) 7.41%, rgb(22 22 22 / 18%) 93.56%)",
            border: "1px solid rgb(118 208 213 / 29%)",
            borderRadius: "12px",
            color: "white",
            width: "30px",
            height: "30px",
            margin: "0px 3px",
            [theme.breakpoints.down("sm")]: {
                width: "25px",
                height: "25px",
            },
        },
        tablePaginationExtremes: {
            background:
        "linear-gradient(145deg, rgb(255 255 255 / 13%) 7.41%, rgb(22 22 22 / 18%) 93.56%)",
            border: "1px solid rgb(118 208 213 / 29%)",
            borderRadius: "12px",
            color: "white",
            width: "50px",
            padding: "5px",
            height: "30px",
            margin: "0px 3px",
            fontSize: "14px",
            [theme.breakpoints.down("sm")]: {
                width: "40px",
                height: "22px",
                borderRadius: "7px",
                fontSize: "10px",
            },
        },
        pagingButton: {
            padding: "2px 2px !important",
        },
    };
});

export default styles;
