import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Grid,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BarChart from "../../components/Charts/BarChart";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Graph } from "../Home/components/graph";
import TokenUsersImage from "../../assets/images/chainusers.png";
import ChainUsersImage from "../../assets/images/chainusers.png";
import TransactionHistoryImage from "../../assets/images/transactionhistory.png";
import TimeVs1 from "../../assets/images/timevstxn.png";
import TimeVs2 from "../../assets/images/timevsvol.png";
import RightArrowButton from "../../assets/images/viewButton.png";

import useStyles from "../Home/HomeStyles";
import Others from "../../reuseable/Others/Others";

const barOptions = {
    maintainAspectRatio: false,
    indexAxis: "y",
    responsive: true,
};

export default function AllCharts({ isDark = true }) {
    const cardsData = [
        {
            title: "Transaction history",
            image: TransactionHistoryImage,
            name: "TRANSACTION_HISTORY",
        },
        {
            title: "Token Users",
            image: TokenUsersImage,
            name: "TOKEN_USERS",
        },
        {
            title: "Chain Users",
            image: ChainUsersImage,
            name: "CHAIN_USERS",
        },
        {
            title: "Time vs Transactions",
            image: TimeVs1,
            name: "TIME_AND_TXN",
        },
        {
            title: "Time vs Volume USD ",
            image: TimeVs2,
            name: "TIME_AND_VOL_USD",
        },
    ];
    const { BackButton } = Others({ isDark });

    const { classes, cx } = useStyles({ isDark });

    const navigate = useNavigate();
    const {
        txs,
        totalTxsCount,
        xgli,
        txsHistory = [],
        loading,
        historyData,
    } = useSelector((state) => state.home);

    const [chartData, setChartData] = useState({});
    const [tokenChartData, setTokenChartData] = useState({});
    const [selectedChart, setSelectedChart] = useState("TRANSACTION_HISTORY"); // TRANSACTION_HISTORY, TOKEN_USERS, CHAIN_USERS
    const [isLoading, setIsLoading] = useState(true);

    function capitalizeFirstLetter(str) {
        const strMappings = {
            usdc: "USDC",
            sol: "SOL",
            algo: "ALGO",
            euroc: "EUROC",
            nosec: "NOSEC",
        };

        if (str in strMappings) {
            return strMappings[str];
        } else {
            return str.charAt(0).toUpperCase() + str.slice(1);
        }
    }

    useEffect(() => {
        console.log("Prepping historyData", historyData);
        if (historyData) {
            setIsLoading(true);
            const chainData = historyData?.byChain;
            const labels = chainData?.map((d) => capitalizeFirstLetter(d.chain));
            console.log("labels", labels)
            const values = chainData?.map((d) => d.uniqueAddresses);
            console.log("values", values)
            const formattedChartData = {
                labels: labels,
                datasets: [
                    {
                        label: "Unique Users",
                        data: values,
                        backgroundColor: [
                            "rgba(255, 99, 132, 0.2)",
                            "rgba(54, 162, 235, 0.2)",
                            "rgba(255, 206, 86, 0.2)",
                            "rgba(75, 192, 192, 0.2)",
                        ],
                        borderColor: [
                            "rgba(255, 99, 132, 1)",
                            "rgba(54, 162, 235, 1)",
                            "rgba(255, 206, 86, 1)",
                            "rgba(75, 192, 192, 1)",
                        ],
                        borderWidth: 1,
                    },
                ],
            };
            console.log("formattedChartData", JSON.stringify(formattedChartData));
            setChartData(formattedChartData);
            const tokenData = historyData?.byToken;
            const tokenLabels = tokenData?.map((d) => capitalizeFirstLetter(d.token));
            const tokenValues = tokenData?.map((d) => d.uniqueAddresses);
            console.log("tokenLabels", tokenLabels);
            const formattedTokenChartData = {
                labels: tokenLabels,
                datasets: [
                    {
                        label: "Unique Users",
                        data: tokenValues,
                        backgroundColor: [
                            "rgba(255, 99, 132, 0.2)",
                            "rgba(54, 162, 235, 0.2)",
                            "rgba(255, 206, 86, 0.2)",
                            "rgba(75, 192, 192, 0.2)",
                        ],
                        borderColor: [
                            "rgba(255, 99, 132, 1)",
                            "rgba(54, 162, 235, 1)",
                            "rgba(255, 206, 86, 1)",
                            "rgba(75, 192, 192, 1)",
                        ],
                        borderWidth: 1,
                    },
                ],
            };
            setTokenChartData(formattedTokenChartData);
            setTimeout(() => {
                setIsLoading(false);
            }, 1000);
        }
    }, [historyData]);

    return (
        <>
            <Grid
                sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "start",
                    flexDirection: "column",
                }}
            >
                <BackButton text="Back to Glitter Explorer" href="/" />
            </Grid>
            <Typography
                variant="h6"
                align="center"
                textTransform="uppercase"
                className={classes.sourceClass}
            >
        Deep Analytics
            </Typography>

            <Grid container spacing={2} style={{ justifyContent: "center" }}>
                {cardsData.map((card) => (
                    <Grid item xs={12} sm={6} md={3} key={card.title}>
                        <div
                            onClick={() => {
                                navigate(`/charts/${card?.name}`);
                            }}
                            style={{
                                backgroundColor: "transparent",
                                cursor: "pointer",
                                display: "flex",
                                flexDirection: "column",
                                border: "1px solid #76D0D5",
                                borderRadius: 35,
                                boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                            }}
                        >
                            <img
                                alt="green iguana"
                                height="200"
                                width="100%"
                                src={card?.image}
                                style={{ borderTopLeftRadius: 35, borderTopRightRadius: 35 }}
                            />
                            <div
                                style={{
                                    border: "1px solid #76D0D5",
                                    width: "100%",
                                    borderBottomLeftRadius: 35,
                                    borderBottomRightRadius: 35,
                                    padding: 24,
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                }}
                            >
                                <Typography variant="h8" component="div">
                                    {card.title}
                                </Typography>
                                <div
                                    style={{
                                        flexDirection: "row",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                        display: "flex",
                                    }}
                                >
                                    <img
                                        alt="green iguana"
                                        height="30px"
                                        src={RightArrowButton}
                                        style={{ marginTop: 24 }}
                                    />
                                </div>
                            </div>
                        </div>
                    </Grid>
                ))}
            </Grid>
        </>
    );
}
