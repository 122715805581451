import { takeLatest, call, put } from "redux-saga/effects";
import request from "./utils/apisauce";
import { sleep } from "./utils/apiutils";

const SingletonModule = {
    mainNetUrl: "https://api-v2.glitterfinance.org/api/",    
    AverageTimes: null,
    graphColors: 
            [
                "#ff00cccc",
                "#ee34d2cc",
                "#9c27b0cc",
                "#50bfe6cc",
                "#16d0cbcc",
                "#aaf0d1cc",
                "#66ff66cc",
                "#ccff00cc",
                "#ffff66cc",
                "#ffcc33cc",
                "#ff9933cc",
                "#ff9966cc",
                "#ff6037cc",
                "#fd5b78cc",
                "#ff355ecc",

            ],

    textColors: 
            [
                "#BDB2FF",
                "#A0C4FF",
                "#9BF6FF",
                "#CAFFBF",
                "#FDFFB6",
                "#FFD6A5",
                "#FFADAD",
            ],

    statusColors:
            {
                success: "#5AC491",
                normal: "#b7edd3",
                caution: "#E8C342",
                warning: "#E84242",
                refund: "#F2784B",
            },

    designColors:
            {
                lightGrey: "#bfbfbf",
                mediumGrey: "grey"
            },
            
    async loadRuntimeData(){
        callEndpoint("txns/averateTransferTimes");
    },

    getAverageTime(token, fromChain, toChain){        
        //console.log(`getAverageTime: ${token}, ${fromChain}, ${toChain}`)
        if (SingletonModule.AverageTimes == null) return 0;
        if (!token) return 0;
        if (!fromChain) return 0;
        if (!toChain) return 0;

        const paths = SingletonModule.AverageTimes.get(token.toLowerCase());
        if (paths == null) return 0;

        for (let i = 0; i < paths.length; i++) {
            const element = paths[i];
            //
            if (element.fromChain.toLowerCase() === fromChain.toLowerCase() && element.toChain.toLowerCase() === toChain.toLowerCase()){
                //console.log(`token: ${token} from: ${element.fromChain}, to: ${element.toChain}`, element.timeToCompletion)
                return element.timeToCompletion;
            }
        }
        return 0;
       
    },

    getOfficialChainName(chainName){
        if (!chainName) return "";
        switch (chainName.toLowerCase()) {
            case "ethereum":
                return "Ethereum";
            case "binance":
                return "BNB Chain";
            case "avalanche":
                return "Avalanche C-chain";
            case "arbitrum":
                return "Arbitrum One";
            case "polygon":
                return "Polygon PoS";
            case "optimism":
                return "OP Mainnet";
            case "algorand":
                return "Algorand";
            case "solana":
                return "Solana";
            case "tron":
                return "Tron";
            case "zkevm":
                return "Zkevm";
            default:
                return chainName;
        }
    }
};

async function callEndpoint(endpoint) {
    let count = 0;
    const retries = 10;
    const sleepTime = 1000;

    while (count < retries) {
        try {
            const response = await request("GET", endpoint);
            console.log(`Response from ${endpoint}: ${response}`)

            //parse response array into a map
            SingletonModule.AverageTimes = new Map();

            response.forEach(element => {              
                SingletonModule.AverageTimes.set(element["name"].toLowerCase(), element["paths"]);                
            });

            // console.log(JSON.stringify(SingletonModule.AverageTimes));

            // const value = SingletonModule.getAverageTime("USDC", "algorand", "solana");
            // console.log(`Average time: ${value}`);
            
            break;
        } catch (e) {
            count++;
            if (count >= retries) return;

            await sleep(sleepTime);
            continue;
        }
    }
}

// Export the singleton module
export default SingletonModule;