import React from "react";
import { Grid, Typography, Box, useTheme, Tooltip } from "@mui/material";

import { StandardSkeleton } from "../../../reuseable/TblChunks.js";
import { capitalize, commaSeperated } from "../../../utils/apiutils.js";
import useStyles from "../ServerStatusStyles.js";

const STATUS = [
  "operational",
  "degraded",
  "downtime",
  "maintenance",
  "explorerDowntime",
];
const EXPLORER_DOWNTIME_MSG = "No information from explorer";

export default function StatusBar({
  isDark = true,
  name,
  status,
  lastPing,
  loading,
}) {
  const theme = useTheme();
  const { classes } = useStyles({ isDark });

  const checkColor = (status) => {
    switch (status) {
      case STATUS[0]:
        return {
          color: theme.palette.success.main,
        };
      case STATUS[1]:
        return {
          color: "#f49342",
        };
      case STATUS[2]:
        return {
          color: theme.palette.error.main,
        };
      case STATUS[3]:
        return {
          color: "#5F59F7",
        };
      case STATUS[4]:
        return {
          color: "gray !important",
        };
      default:
        return {
          color: isDark ? "#fff" : "#000",
        };
    }
  };

  return (
    <Grid
      className={classes.statusContainer}
      sx={{
        borderBottom:
          name === "Solana Server"
            ? "none !important"
            : "1px solid rgba(118, 208, 213, 0.51)",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between", py: 1 }}>
        <Typography
          sx={{
            color: isDark ? "#ffffff" : "#000",
            fontWeight: "bold",
          }}
        >
          {name}
        </Typography>
        <Box className="flexCenter">
          &nbsp;
          <Typography
            sx={{
              color: isDark ? "#ffffff" : "#000",
              fontSize: "0.7rem",
              paddingTop: "0.2rem",
            }}
          >
            Last pinged: {lastPing} sec(s)
          </Typography>
        </Box>
      </Box>
      {loading ? (
        <StandardSkeleton h={50} w={"100%"} />
      ) : (
        <Tooltip
          title={capitalize(
            status === "explorerDowntime" ? EXPLORER_DOWNTIME_MSG : status
          )}
          placement="top"
        >
          <Box
            className={classes.statusBar}
            sx={{ backgroundColor: checkColor(status)?.color }}
          ></Box>
        </Tooltip>
      )}
    </Grid>
  );
}
