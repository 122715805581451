import {
    Box,
    Grid,
    Typography,
    Button,
    Tooltip,
    useTheme,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    CheckCircle,
    Warning,
    RemoveCircle,
    Settings,
} from "@mui/icons-material";
import IdleTimer from "react-idle-timer";

import useStyles from "./ServerStatusStyles";
import Others from "../../reuseable/Others/Others";
import SleakBar from "../../reuseable/SleakBar/SleakBar";
import StatusBar from "./components/StatusBar";
import { getServerStatus } from "./reducers";
import { dateTimeFormateByPattern } from "../../utils/apiutils";
import config from "../../config.json";
import { StandardSkeleton } from "../../reuseable/TblChunks";
import { EXPLORER_STATUS } from "../../utils/constants";
import Progress from "./components/Progress";
import moment from "moment";

const MIN = 60 * 1000;

export default function ServerStatus({ isDark = true }) {
    const dispatch = useDispatch();
    const theme = useTheme();
    const { BackButton, checkColor } = Others({ isDark });
    const { classes, cx } = useStyles({ isDark });

    const [isIdle, setIsIdle] = useState(false);
    const { serverStatus, loading } = useSelector((state) => state.serverStatus);

    useEffect(() => {
        dispatch(getServerStatus());
    }, []);

    useEffect(() => {
        if (!isIdle) {
            const statusPingInterval = setInterval(async () => {
                console.log("ping");
                dispatch(getServerStatus());
            }, config.settings.polling_interval);

            return () => {
                clearInterval(statusPingInterval);
            };
        }
    }, [isIdle]);

    const checkServiceStatus = (service) => {
        let status = EXPLORER_STATUS[0];

        if (service?.available === true) status = EXPLORER_STATUS[0];
        if (service?.degraded === true) status = EXPLORER_STATUS[1];
        if (service?.maintenance === true) status = EXPLORER_STATUS[3];
        if (service?.available === false) status = EXPLORER_STATUS[2];
        return status;
    };

    const serviceNamePicker = (name) => {
        if (name === "explorerServer") {
            return "Explorer Server";
        } else if (name === "usdcServer") {
            return "USDC Server";
        } else if (name === "usdcDB") {
            return "USDC Database";
        } else if (name === "algorand") {
            return "Algorand Server";
        } else if (name === "solana") {
            return "Solana Server";
        } else {
            return "";
        }
    };

    const renderStatus = () => {
        const servicesArr = [];
        for (const [key, value] of Object.entries(serverStatus)) {
            if (key === "overall") continue;
            if (key === "algorand" || key === "solana") continue;

            servicesArr.push(
                <StatusBar
                    key={"" + key}
                    loading={loading}
                    name={serviceNamePicker("" + key)}
                    status={
                        key === "explorerServer" &&
            serverStatus[key]?.["available"] === false
                            ? EXPLORER_STATUS[2]
                            : checkServiceStatus(serverStatus[key])
                    }
                    lastPing={serverStatus[key]?.secondsSincePing}
                    // last_block={"lastBlockValue" in value ? value["lastBlockValue"] : 0}
                />
            );
        }
        return servicesArr;
    };

    const handleOnIdle = () => {
        console.log("idle");
        setIsIdle(true);
    // setProgress(0);
    };
    const handleOnActive = () => {
        console.log("active");
        setIsIdle(false);
    };

    return (
        <>
            <IdleTimer
                ref={(ref) => {
                    // eslint-disable-next-line no-self-assign
                    ref = ref;
                }}
                element={document}
                onIdle={() => handleOnIdle()}
                onActive={() => handleOnActive()}
                timeout={5 * MIN}
            />
            <Grid className={classes.backContainer}>
                <Link to="/" className={classes.backLink}>
                    <BackButton newTab={true} text="Back to Explorer" />
                </Link>
                <BackButton
                    newTab={true}
                    text="Back to Glitter Bridge"
                    href="https://bridge.glitterfinance.org/"
                />
                <BackButton
                    newTab={true}
                    text="Back to Widget (Bridge)"
                    href="https://widget.glitterfinance.org/"
                />
            </Grid>
            <Typography
                variant="h6"
                align="center"
                textTransform="uppercase"
                className={classes.sourceClass}
                sx={{
                    color: isDark ? "#ffffff" : "#000",
                    marginTop: "10px",
                }}
            >
        Services Status:
            </Typography>
            <Grid sx={{ display: "grid", justifyItems: "center" }}>
                {loading ? (
                    <>
                        <StandardSkeleton h={70} w={70} type="circular" />
                        <br />
                        <StandardSkeleton h={50} w={"70%"} />
                        <br />
                        <StandardSkeleton h={20} w={"70%"} />
                    </>
                ) : (
                    <>
                        {checkColor(checkServiceStatus(serverStatus?.overall))?.icon}
                        <Typography
                            pt={3}
                            variant="h6"
                            align="center"
                            textTransform="uppercase"
                            className={classes.sourceClass}
                            sx={{
                                color: isDark ? "#ffffff" : "#000",
                                textAlign: "center !important",
                            }}
                        >
                            {checkColor(checkServiceStatus(serverStatus?.overall))?.msg}
                        </Typography>
                        <Typography
                            variant="h6"
                            align="center"
                            textTransform="uppercase"
                            className={classes.dateText}
                            sx={{
                                color: isDark ? "#ffffff" : "#000",
                            }}
                        >
              As of {moment.utc().format("MMM DD, yyyy hh:mm a")}
                            {" UTC"}
                        </Typography>
                    </>
                )}
            </Grid>

            <Grid mt={8}>
                <SleakBar my={10} px={0} py={2}>
                    <Grid sx={{ width: "100%" }}>
                        <SleakBar my={0} px={20} py={2}>
                            <Box className={classes.statusTitle}>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        color: isDark ? "#ffffff" : "#000",
                                    }}
                                >
                  Current status by Glitter services
                                </Typography>
                                <Box>
                                    <Tooltip title="Operational" placement="top">
                                        <CheckCircle
                                            className={classes.statusIcons}
                                            sx={{
                                                "&:hover": {
                                                    color: theme.palette.success.main + " !important",
                                                },
                                            }}
                                        />
                                    </Tooltip>
                                    <Tooltip title="Degraded" placement="top">
                                        <Warning
                                            className={classes.statusIcons}
                                            sx={{
                                                "&:hover": {
                                                    color: "#f49342",
                                                },
                                            }}
                                        />
                                    </Tooltip>
                                    <Tooltip title="Downtime" placement="top">
                                        <RemoveCircle
                                            className={classes.statusIcons}
                                            sx={{
                                                "&:hover": {
                                                    color: theme.palette.error.main,
                                                },
                                            }}
                                        />
                                    </Tooltip>
                                    <Tooltip title="Maintenance" placement="top">
                                        <Settings
                                            className={classes.statusIcons}
                                            sx={{
                                                "&:hover": {
                                                    color: "#5F59F7",
                                                },
                                            }}
                                        />
                                    </Tooltip>

                                    <Progress isDark={isDark} isIdle={isIdle} loading={loading} />
                                </Box>
                            </Box>
                        </SleakBar>
                        <Grid sx={{ width: "100%" }}>
                            {renderStatus()?.map((item) => item)}
                        </Grid>
                    </Grid>
                </SleakBar>

                <Button
                    href="https://docs.glitterfinance.org/introduction/bridging-security"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                    size="small"
                    className={cx(classes.activeBtn, classes.viewTxBtn)}
                >
          Legal Information
                </Button>
            </Grid>
        </>
    );
}
