import { takeLatest, call, put } from "redux-saga/effects";
import {
    getAddressProfile,
    getAddressProfileSuccess,
    getAddressProfileFailed,
} from "./reducers";

import request from "../../utils/apisauce";

function* getAddressProfileSaga({ payload }) {
    let action;

    let addressString = "";

    addressString = payload?.addresses?.join("_");

    console.log("addressString", addressString)

    let url =
    payload?.addresses?.length === 1
        ? `/addresses/${addressString}?take=20`
        : `/addresses?addresses=${addressString}&take=20`;

    if (payload?.status && payload?.status !== "all") {
        url += `&status=${payload?.status}`;
    }
    if (payload?.newPage && payload?.rowsPerPage) {
        url += `&skip=${payload?.newPage * payload?.rowsPerPage}`;
    }
    if (payload?.sort) {
        url += `&sort=${payload?.sort}`;
    }

    try {
        const response = yield call(request, "GET", url);
        action = getAddressProfileSuccess(response);
    } catch (e) {
        action = getAddressProfileFailed(e);
    }
    yield put(action);
}

export default function* saga() {
    yield takeLatest(getAddressProfile, getAddressProfileSaga);
}
