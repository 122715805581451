import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme, { isDark }) => ({
    myAlert: {
        display: "flex",
        alignItems: "center",
        justifyContent: "left",
        textAlign: "left",
        minHeight: "45px",
        paddingTop: "0px",
        paddingBottom: "0px",
        borderRadius: "13px",
        borderColor: "transparent",
        margin: "20px 0px",
        [theme.breakpoints.down("xs")]: {
            minHeight: "auto",
        },
    },
    paperBgDark: {
        background:
      "linear-gradient(40deg, rgb(255 252 252 / 13%), rgb(0 0 0 / 0%)) !important",
        "& .MuiAlert-icon": {
            color: "#FFFFFF",
        },
    },
    paperBgLight: {
        background: "rgba(255, 255, 255, 0.3) !important",
        boxShadow: "0px 1px 12px rgb(0 0 0 / 15%)",
        "& .MuiAlert-icon": {
            color: "#000000",
        },
    },

    activeBtn: {
        fontFamily: "Montserrat",
        fontSize: "12px",
        //color: "#76D0D5",
        //fontWeight: "700",
        //border: "1px solid transparent",
        borderRadius: 25,
        textAlign: "left",
        paddingRight: "10px",
        margin: "2px 0px",
        fontWeight: 500,
        background: "transparent",
        border: !isDark ? "1px solid #62686e" : "1px solid rgb(118 208 213 / 29%)",
        color: "#76D0D5",
        [theme.breakpoints.down("md")]: {
            fontSize: "16px",
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "8px",
            height: "20px",
            padding: "2px 0px",
            paddingRight: "8px",
        },
    },
    backicon: {
        [theme.breakpoints.down("sm")]: {
            fontSize: "1rem",
            padding: "0px",
            paddingLeft: "-1px",
            margin: "0px",
        },
    },
    tileTitle: {
        color: "rgb(118, 208, 213)",
        fontSize: "14px",
        fontStyle: "normal",
        fontFamily: "Barlow",
        fontWeight: 600,
        lineHeight: "16px",
        [theme.breakpoints.down("sm")]: {
            fontSize: "13px",
            lineHeight: "10px",
        },
    },
    statusIcon: {
        fontSize: "4.5rem",
    },
}));

export default styles;
