import React, { useEffect, useMemo, useState } from "react";
import {
    IconButton,
    Tooltip,
    TableSortLabel,    
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
import { FilterList, Refresh } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import useTableStyles from "../common";
import { detect } from "detect-browser";
import { makeStyles } from '@mui/styles';
import useTxnTableStyles from "../common";
import { TableSort, } from "../tableSort";

import {
    abbreviateAddress,
    checkTypeColor,
    convertUTCtoLocal,
    getStatusIcon,
    getStatusIconClass,
    getStatusSubText,
    getStatusTime,
    getTransferIconClass,
    checkStatusTimeColor, getStatusColor } from "../../../utils/apiutils";

const headCells = [{
    id: "protocol",
    numeric: false,
    align: "center",
    disablePadding: true,
    label: "Protocol",
}, 
{
    id: "status",
    numeric: false,
    align: "center",
    disablePadding: false,
    label: "Status",
},
{
    id: "fromTimestamp",
    align: "center",
    numeric: true,
    disablePadding: false,
    label: "Time Ago",
},
{
    id: "network",
    align: "center",
    numeric: true,
    disablePadding: false,
    label: "Transfer Details",
},
{
    id: "fromAmount",
    align: "center",
    numeric: true,
    disablePadding: false,
    label: "Amount",
},
{
    id: "txn",
    align: "center",
    numeric: true,
    disablePadding: false,
    label: "Txn Details",
},
];

export default function TxnTableHeader ({
    _txs,
    order,
    orderBy,
    isDark,
    filterToggle,
    filter,
    onRequestSort
}) {

    const browser = detect();
    
    const { classes, cx } = useTxnTableStyles({
        isDark,
        _txs,
        browserName: browser?.name ?? "",
    });
    
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    
    return (
        <TableHead className={classes.tblHead}>
            <TableRow>
                {headCells?.map((headCell, index) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.align}
                        className={cx(
                            classes.tableHeader,
                            headCell.id == "protocol"
                                ? classes.firstCell
                                : headCell.id == "txLinks"
                                    ? classes.lastCell
                                    : ""
                        )}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {headCell.id === "status" ? (
                            <span style={{ display: "flex", justifyContent: "center" }}>
                                {headCell.label}
                                <Tooltip title="Filter by Status">
                                    <IconButton style={{ padding: 0 }} onClick={filterToggle}>
                                        <FilterList
                                            style={{ color: `${getStatusColor(filter) ?? "#fff"} ` }}
                                        />
                                    </IconButton>
                                </Tooltip>
                            </span>
                        ) : headCell.id === "fromTimestamp" ||
              headCell.id === "fromAmount" ? (
                                <Tooltip title={`Sort by ${headCell.label}`}>
                                    <TableSortLabel
                                        active={orderBy === headCell.id}
                                        direction={orderBy === headCell.id ? order : "asc"}
                                        onClick={createSortHandler(headCell.id)}
                                        className={
                                            isDark ? classes.sortLabelLight : classes.sortLabelDark
                                        }
                                    >
                                        <span>{headCell.label}</span>
                                    </TableSortLabel>
                                </Tooltip>
                            ) : (
                                headCell.label
                            )}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}