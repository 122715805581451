import moment from "moment";
import { Tooltip } from "@mui/material";
import React from 'react';

import algorandLogo from "../assets/images/ChainLogos/algorand.png";
import solanaLogo from "../assets/images/ChainLogos/solana.png";
import ethLogo from "../assets/images/ChainLogos/ethereum.png";
import avalancheLogo from "../assets/images/ChainLogos/avalanche.png";
import tronWalletLogo from "../assets/images/ChainLogos/tron.png";
import polygonLogo from "../assets/images/ChainLogos/polygon.png";
import arbitrumLogo from "../assets/images/ChainLogos/arbitrum.png";
import optimismLogo from "../assets/images/ChainLogos/optimism.png";
import bscLogo from "../assets/images/ChainLogos/bsc.png";
import casperLogo from "../assets/images/ChainLogos/casper.svg";
import zkevmLogo from "../assets/images/ChainLogos/polygon.png";
import seiLogo from "../assets/images/ChainLogos/avalanche.png";
import unknownLogo from "../assets/images/StatusIcons/question_icon.png";
import { TRON } from "./constants";

import processing_icon from "../assets/images/StatusIcons/processing_icon.png";
import success_icon from "../assets/images/StatusIcons/success_icon.png";
import refund_icon from "../assets/images/StatusIcons/refund_icon.png";
import question_icon from "../assets/images/StatusIcons/question_icon.png";

import SingletonModule from "../SingletonModule";

const microAlgoCorrection = 1000000; // 6 zeros
const microSolCorrection = 1000000000; // 9 zeros

export const capitalize = (input) => {
    if (
        input !== null &&
    input !== "" &&
    input !== undefined &&
    typeof input === "string"
    ) {
        var words = input?.split("_");
        var CapitalizedWords = [];
        words.forEach((element) => {
            CapitalizedWords.push(
                element[0]?.toUpperCase() + element?.slice(1, element?.length)
            );
        });
        return CapitalizedWords?.join(" ");
    }
    input = "";
    return input;
};
export const stringCheck = (input) => {
    return input !== null &&
    input !== "" &&
    input !== undefined &&
    typeof input === "string"
        ? true
        : false;
};

export const isDatePassed = (date) => {
    const str = date;
    const isDatePassed = moment(str).isBefore(moment());
    return isDatePassed;
};
export const dateTimeFormate = (stringIso) => {
    const str = stringIso;
    const date = moment(str);
    const dateComponent = date.format("YYYY-MM-DD, hh:mm A");
    return dateComponent;
};
export const dateTimeFormateByPattern = (stringIso, pattern) => {
    const str = stringIso;
    const date = moment(str);
    const dateComponent = date.format(pattern);
    return dateComponent;
};
export const dateFormate = (stringIso) => {
    const str = stringIso;
    const date = moment(str);
    const dateComponent = date.format("MMM DD, YYYY");
    return dateComponent;
};
export const timeFormate = (stringIso) => {
    const str = stringIso;
    const date = moment(str);
    const timeComp = date.format("hh:mm A");
    return timeComp;
};
export const idToName = (arr, id, key) => {
    if (arr?.length > 0) {
        const obj = arr.filter((item) => item._id == id)[0];
        const out = obj[key];
        return out;
    }
    return true;
};
export const lowerSpaceJoinStr = (input) => {
    if (
        input !== null &&
    input !== "" &&
    input !== undefined &&
    typeof input === "string"
    ) {
        const out = input.toLocaleLowerCase().replace(/\s\s+/g, " ");
        return out.split(" ").join("");
    }
    return input;
};
export const allWordsCapitalize = (input) => {
    if (
        input !== null &&
    input !== "" &&
    input !== undefined &&
    typeof input === "string"
    ) {
        input = input.trim().replace(/\s\s+/g, " ");
        var words = input?.split(" ");
        var CapitalizedWords = [];
        words.forEach((element) => {
            CapitalizedWords.push(
                element[0]?.toUpperCase() + element?.slice(1, element?.length)
            );
        });
        return CapitalizedWords?.join(" ");
    }
    input = "";
    return input;
};
export const trimString = (input, len) => {
    if (stringCheck(input)) {
        input = capitalize(input).replace(/\s\s+/g, " ");
        return input.length > len ? input.substring(0, len) + "..." : input;
    }
    input = "";
    return input;
};
export const tooltipTrim = (input, len) => {
    if (stringCheck(input)) {
        input = allWordsCapitalize(input);
        return input.length > len ? (
            <Tooltip title={input} placement="top">
                <span>{input.substring(0, len) + "..."}</span>
            </Tooltip>
        ) : (
            input
        );
    }
    return input;
};
export const tooltipNumber = (input, len) => {
    input = input + "";
    return input.length > len ? (
        <Tooltip title={input} placement="top">
            <span>{input.substring(0, len) + "..."}</span>
        </Tooltip>
    ) : (
        input
    );
};
export const getExtension = (filename) => {
    var parts = filename.split(".");
    return parts[parts.length - 1];
};

export const isImage = (filename) => {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
        case "jpg":
        case "jpeg":
        case "gif":
        case "bmp":
        case "png":
            //etc
            return true;
    }
    return false;
};

export const isVideo = (filename) => {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
        case "m4v":
        case "avi":
        case "mpg":
        case "mp4":
            // etc
            return true;
    }
    return false;
};
export const parseJwt = (token) => {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split("")
            .map(function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
    );

    return JSON.parse(jsonPayload);
};
export const lowerDashJoinStr = (input) => {
    if (
        input !== null &&
    input !== "" &&
    input !== undefined &&
    typeof input === "string"
    ) {
        const out = input.toLocaleLowerCase();
        return out.split(" ").join("-");
    }
    return input;
};
export const getDifferenceInSeconds = (date1, date2) => {
    const diffInMs = date1 > date2;
    return diffInMs;
};
export const truncateStr = (fullStr, strLen) => {
    if (fullStr.length <= strLen) return fullStr;

    const separator = "...";
    const seperatorLength = separator.length;
    const charsToShow = strLen - seperatorLength;
    const frontChars = Math.ceil(charsToShow / 2);
    const backChars = Math.floor(charsToShow / 2);
    return (
        fullStr.substring(0, frontChars) +
    separator +
    fullStr.substring(fullStr.length - backChars)
    );
};
export const abbreviateAddress = (fullStr, bookendCharacters = 4) => {
    if (!fullStr) return fullStr;
    if (fullStr.length <= bookendCharacters*2) return fullStr;
    const separator = "..";
    return fullStr.substring(0, bookendCharacters) + separator + fullStr.substring(fullStr.length - bookendCharacters);   
};

export const formateAmount = (amount, token) => {
    if (token === "algo" || token === "xALGO") {
        return (amount / microAlgoCorrection)?.toFixed(4);
    } else if (token === "sol" || token === "xSOL")
        return (amount / microSolCorrection)?.toFixed(4);
    else return amount;
};

export const commaSeperated = (amount) => {
    // commaSeperated before decimal point
    if (typeof amount === "number" && amount.toString().includes(".")) {
        const amountStr = amount + "";
        const amountArr = amountStr.split(".");
        const amountArr1 = amountArr[0];
        const amountArr2 = amountArr[1];

        return (
            amountArr1.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
      "." +
      amountArr2
        );
    } else {
        return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
};

export const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
};

export const getChainIcon = (chain) => {
    if (!chain) return unknownLogo;

    switch (chain.toLocaleLowerCase()) {
        case "algorand":
            return algorandLogo;
        case "solana":
            return solanaLogo;
        case "ethereum":
            return ethLogo;
        case "avalanche":
            return avalancheLogo;
        case "polygon":
            return polygonLogo;
        case "tron":
            return tronWalletLogo;
        case "arbitrum":
            return arbitrumLogo;
        case "optimism":
            return optimismLogo;
        case "binance":
        case "bsc":
            return bscLogo;
        case "casper":
            return casperLogo;
        case "zkevm":
            return zkevmLogo;
        case "sei":
            return seiLogo;
        default:
            return unknownLogo;
    }

    // return chain === "algorand"
    //     ? algorandWallet
    //     : chain === "solana"
    //         ? solanaWallet
    //         : chain === "ethereum"
    //             ? ethWallet
    //             : chain === "avalanche"
    //                 ? avalancheWallet
    //                 : chain === "polygon"
    //                     ? polygonWallet
    //                     : chain === TRON
    //                         ? tronWalletIcon
    //                         : "--";
};

export const checkTypeColor = (type) => {
    if (!type) return SingletonModule.textColors[0];
    switch (type.toLocaleLowerCase()) {
        case "circle":
            return SingletonModule.textColors[0];
        case "tokenv1":
            return SingletonModule.textColors[1];
        case "tokenv2":
            return SingletonModule.textColors[2];
        default:
            return "#000"
    }
}
export const convertUTCtoLocal = (utcDateString) => {
    const date = new Date(utcDateString);
    const locale = navigator.language; // Retrieve the browser's locale
    const localDateString = date.toLocaleString(locale);

    return localDateString;
};
export const getStatusColor = (status) => {
    switch (status) {
        case "Success":
            return SingletonModule.statusColors.success;
        case "Failed":
            return SingletonModule.statusColors.warning;
        case "Pending":
            return SingletonModule.statusColors.caution;
        case "Refund":
            return SingletonModule.statusColors.refund;
        default:
            return "#fff" ;
    }
};

export const getStatusSubText = (status, percent) => {
    if (!status) return question_icon;
    switch (status.toLocaleLowerCase()) {
        case "success":
            return "Completed";
        case "pending":
            return `${Number(percent).toFixed(0)}% Completed`;
        case "refund":
            return "Refunded";
        default:
            return status;
    }
}
export const getStatusTime = (status, seconds, initDate, token, fromChain, toChain) => {
    if (!status) return "--";

    //check if seconds is 0
    if (!seconds || seconds === 0) {
        //get time from initdate
        const date = new Date(initDate);
        //get total seconds 
        seconds = Math.floor((new Date() - date) / 1000);
    }

    //calculate hours, minutes and seconds
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds - (hours * 3600)) / 60);
    const remainingSeconds = seconds - (hours * 3600) - (minutes * 60);    
    let time = `${remainingSeconds.toFixed(0)}s`;

    if (minutes > 0) time = `${minutes}m ${time}`;
    if (hours > 0) time = `${hours}h ${time}`;

    //Get average time
    const avgTime = SingletonModule.getAverageTime(token, fromChain, toChain);      
    const avgHours = Math.floor(avgTime / 3600);
    const avgMinutes = Math.floor((avgTime - (avgHours * 3600)) / 60);
    const avgRemainingSeconds = avgTime - (avgHours * 3600) - (avgMinutes * 60);
    let avgTimeString = `${avgRemainingSeconds.toFixed(0)}s`;

    if (avgMinutes > 0) avgTimeString = `${avgMinutes}m ${avgTimeString}`;
    if (avgHours > 0) avgTimeString = `${avgHours}h ${avgTimeString}`;

    switch (status.toLocaleLowerCase()) {
        case "pending":
            return `${time} [avg: ${avgTimeString}]`;
        case "refund":
        case "success":
            return `Took ${time}`;
        default:
            return ``;
    }
}
export const getAverageTimeText = (token, fromChain, toChain) => {  
    
    //Get average time
    const avgTime = SingletonModule.getAverageTime(token, fromChain, toChain);      
    const avgHours = Math.floor(avgTime / 3600);
    const avgMinutes = Math.floor((avgTime - (avgHours * 3600)) / 60);
    const avgRemainingSeconds = avgTime - (avgHours * 3600) - (avgMinutes * 60);
    let avgTimeString = `${avgRemainingSeconds.toFixed(0)}s`;

    if (avgMinutes > 0) avgTimeString = `${avgMinutes}m ${avgTimeString}`;
    if (avgHours > 0) avgTimeString = `${avgHours}h ${avgTimeString}`;

    return avgTimeString;
}

export const checkStatusTimeColor = (status, initDate, token, fromChain, toChain) => {
    if (!status) return "#fff";
    //check if seconds is 0
        
    let seconds = 0;
    if (initDate) {
        //get time from initdate
        const date = new Date(initDate);
        //get total seconds 
        seconds = Math.floor((new Date() - date) / 1000);
           
    }

    const avgTime = SingletonModule.getAverageTime(token, fromChain, toChain);
      
    switch (status.toLocaleLowerCase()) {
        case "success":
            return SingletonModule.statusColors.success;
        case "pending":
            if (seconds < avgTime*1.25) return SingletonModule.statusColors.normal;
            if (seconds < avgTime*2) return SingletonModule.statusColors.caution;
            return SingletonModule.statusColors.warning;
        case "refund":
            return SingletonModule.statusColors.refund;
        default:
            return "#fff" ;
    }
};

//Status Getters
export const getStatusIcon = (status) => {
    if (!status) return question_icon;
    switch (status.toLocaleLowerCase()) {
        case "success":
            return success_icon;
        case "pending":
            return processing_icon;
        case "refund":
            return refund_icon;
        default:
            return question_icon;
    }
}
export const getStatusIconClass = (status) => {
    if (!status) return question_icon;
    switch (status.toLocaleLowerCase()) {
        case "pending":
            return "rotating-image";
        default:
            return "status-icon";
    }
}
export const getTransferIconClass = (status) => {
    if (!status) return question_icon;
    switch (status.toLocaleLowerCase()) {
        case "pending":
            return "fade-image";
        default:
            return "status-icon";
    }
}
export const formatUSD = (amount) => {
    if (!amount) return "--";
    return `$${Number(amount).toFixed(2)}`;
}
