import React, { useEffect, useMemo, useState } from "react";
import {
    Typography,
    Paper,
    useTheme,
    useMediaQuery,
    Grid,
    Button,
    Tooltip,
} from "@mui/material";
import { CheckCircle, Cancel, Error } from "@mui/icons-material";
import useStyles from "./TxOverviewStyles";
import Others from "../../../reuseable/Others/Others";
import { detect } from "detect-browser";
import SleakBar from "../../../reuseable/SleakBar/SleakBar";
import { capitalize } from "../../../utils/apiutils";
import { format } from "timeago.js";
import { StandardSkeleton } from "../../../reuseable/TblChunks";
import { TX_STATUS } from "../../../utils/constants";
import { Link } from 'react-router-dom';
import { abbreviateAddress,
    checkTypeColor,
    convertUTCtoLocal,
    getStatusIcon,
    getStatusIconClass,
    getStatusSubText,
    getStatusTime,
    getTransferIconClass,
    formatUSD,
    getAverageTimeText,
    checkStatusTimeColor } from "../../../utils/apiutils";
import {
    allWordsCapitalize,
    getChainIcon,
    truncateStr,
} from "../../../utils/apiutils";
import { format as formatTimeAgo } from "timeago.js";
import glitterLogo from "../../../assets/images/logo.png";
import SingletonModule from "../../../SingletonModule";
import { toDate } from "date-fns";

import { ProtocolCard, StatusCard, AmountCard, TransactionCard }from "../../../components/Cards";

const TxOverview = ({ isDark = true, tx, txLoading, network }) => {
    const theme = useTheme();
    const browser = detect();
    const { getTxUrlByChain, getTxAddressUrlByChain, getExplorerAddressUrl, getExplorerTxnUrl } = Others({ isDark });

    const smallPoint = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    const { classes, cx } = useStyles({
        isDark,
        tx,
        browserName: browser?.name ?? "",
    });
    const { CopyToClipboard } = Others({
        isDark,
    });

    const txnDisplay = (txnArray) => {
        if (txnArray === undefined || txnArray === null || txnArray === "") {
            return "None";
        } else {
            let visible = "None";
            txnArray.forEach(element => {
                if (element !== null) {
                    visible = "";
                }
            });
            return visible;
        }
    };

    const Title = ({ children }) => {
        return (
            <Grid item md={2} sm={12} pl={2} xs={12} sx={{ textAlign: "left" }}>
                <Typography
                    component="span"
                    variant="p"
                    className={classes.tileTitle}
                    style={{ color: "#fff" }}
                >
                    {children} &nbsp;
                </Typography>
            </Grid>
        );
    };
    const Value = ({ children }) => {
        return (
            <Grid item md={10} sm={12} pl={2} xs={12} sx={{ textAlign: "left", flexGrow: 1 }} >
                <Typography
                    component="span"
                    variant="p"
                    className={classes.tileTitle}
                    sx={{ fontWeight: "400 !important" }}
                >
                    {children}
                </Typography>
            </Grid>
        );
    };

    //Main Page Return
    return (
        <Paper
            //className={classes.paper}
            style={{ backgroundColor: "transparent", paddingTop: "12px" }}
            elevation={0}
        >
            <SleakBar my={7} px={20} py={2}>
                <Typography
                    textTransform="uppercase"
                    fontWeight="bold"
                    sx={{ width: "100%", color: "white" }}
                >
                  Transaction Overview
                </Typography>
            </SleakBar>
            {txLoading ? (
                <StandardSkeleton h={"200px"} />
            ) : (
                <Grid >

                    <ProtocolCard tx={tx}/>
                    <StatusCard tx={tx}/>
                    <AmountCard tx={tx}/>
                    <TransactionCard 
                        cardLabel="Initial Txn:"
                        txnNetwork={tx.initNetwork}
                        txnDate={tx.initDate}
                        txnBlock={tx.initBlock}
                        txnID={tx.initID}
                        txnAddress={tx.initAddress}
                        txnToken={tx.initToken}
                    />
                    <TransactionCard 
                        cardLabel="Completed Txn:"
                        txnNetwork={tx.completedNetwork}
                        txnDate={tx.completedDate}
                        txnBlock={tx.completedBlock}
                        txnID={tx.completedID}
                        txnAddress={tx.completedAddress}
                        txnToken={tx.completedToken}
                    />
                    {tx.transferStartID && (
                        <TransactionCard
                            cardLabel="Internal Txn: (Transfer Start)"
                            txnBlock={tx.transferStartBlock}
                            txnID={tx.transferStartID}
                        />
                    )}
                    {tx.transferStartID && (
                        <TransactionCard
                            cardLabel="Internal Txn: (Transfer End)"
                            txnBlock={tx.transferEndBlock}
                            txnID={tx.transferEndID}
                        />
                    )}
                  
                </Grid>
            )}
        </Paper>
    );
};
export default React.memo(TxOverview);
