import { createSlice } from "@reduxjs/toolkit";
import { NotificationManager } from "react-notifications";

const slice = createSlice({
  name: "home",
  initialState: {
    loading: false,
    txs: [],
    historyData: {},
    totalTxsCount: 0,
    xgli: {
      price: null,
      marketCap: null,
      volume: null,
    },
    txsHistory: [],
    error: "",
  },
  reducers: {
    getTxs: (state) => {
      state.loading = true;
    },
    getTxsSuccess: (state, { payload }) => {
      state.loading = false;
      if (payload) {
        state.txs = [...payload.transactions];
        state.totalTxsCount = payload.totalCount;
      }
    },
    getTxsFailed: (state, { payload }) => {
      state.loading = false;
      state.txs = [];
    },
    getHistoryDataTxns: (state) => {
      state.loading = true;
    },
    getHistoryDataSuccess: (state, { payload }) => {
      state.loading = false;
      state.historyData = {...payload};
    },
    getHistoryDataFailed: (state, { payload }) => {
      state.loading = false;
      state.historyData = {};
    },
    getXgliInfo: (state) => {
      state.loading = true;
    },
    getXgliInfoSuccess: (state, { payload }) => {
      state.loading = false;
      if (payload) {
        state.xgli = {
          price: payload.priceUSD,
          marketCap: payload.marketCapCirculating,
          volume: payload.circulating,
        };
      }
    },
    getXgliInfoFailed: (state, { payload }) => {
      state.loading = false;
      state.xgli = {
        price: null,
        marketCap: null,
        volume: null,
      };
    },
    getTxsHistory: (state) => {
      state.loading = true;
    },
    getTxsHistorySuccess: (state, { payload }) => {
      state.loading = false;
      state.txsHistory = payload;
    },
    getTxsHistoryFailed: (state, { payload }) => {
      state.loading = false;
      state.txsHistory = [];
    },
  },
});

export const {
  getTxs,
  getTxsSuccess,
  getTxsFailed,
  getXgliInfo,
  getXgliInfoSuccess,
  getXgliInfoFailed,
  getTxsHistory,
  getTxsHistorySuccess,
  getTxsHistoryFailed,
  getHistoryDataTxns,
  getHistoryDataSuccess, 
  getHistoryDataFailed
} = slice.actions;

export default slice.reducer;
