import {
    Chip,
    CircularProgress,
    Grid,
    Tooltip,
    Typography,
    useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import useStyles from "./AllTxsStyles";
import TxsTable from "./components/TxsTable/TxsTable";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllTxs } from "./reducers";
import Others from "../../reuseable/Others/Others";
import Papa from "papaparse";
import SingletonModule from "../../SingletonModule";

export default function AllTxs({ isDark = true }) {
    const dispatch = useDispatch();
    const { BackButton } = Others({ isDark });
    const { classes, cx } = useStyles({ isDark });
    const smallPoint = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    const {
        txs,
        loading: txLoading,
        totalCount,
    } = useSelector((state) => state.allTxs);
    const params = useParams();
    const rowsPerPage = params?.chain?.length > 0 ? 500 : 20;

    const [progress, setProgress] = useState(0);
    const [sortBy, setSortBy] = useState("date_desc");
    const [filter, setFilter] = useState("all");
    const [network] = useState("mainnet");
    const [page, setPage] = useState(0);
    const [chainFilterApplied] = useState(false);

    useEffect(() => {
    // console.log("captured chain name", params?.chain, params?.chain?.length);
        dispatch(
            getAllTxs({
                status: "all",
                newPage: 0,
                rowsPerPage: 20,
                sort: "date_desc",
                chain: params?.chain || '',
            })
        );
    }, []);

    useEffect(() => {
    // refetch data each 20 secs
        const interval = setInterval(() => {
            dispatch(
                getAllTxs({ status: filter, sort: sortBy, newPage: page, rowsPerPage })
            );
        }, 20000);
        return () => clearInterval(interval);
    }, [txLoading]);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) =>
                prevProgress >= 100 ? 0 : prevProgress + 5
            );
        }, 1000);
        return () => {
            clearInterval(timer);
        };
    }, []);

    const fetchAllTxs = ({
        filter,
        newPage,
        rowsPerPage,
        sort = "date_desc",
    }) => {
        dispatch(
            getAllTxs({
                status: filter,
                newPage: newPage,
                rowsPerPage,
                sort,
            })
        );
        setProgress(0);
        setFilter(filter);
        setSortBy(sort);
    };
    // const refreshTxs = ({ filter, page, rowsPerPage, sort = "date_desc" }) => {
    //     console.log("refreshing", filter, page, rowsPerPage, sort);
    //     dispatch(getAllTxs({ status: filter, newpage: page, rowsPerPage, sort }));
    //     setProgress(0);
    // };

    const getFormattedDate = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, "0");
        const day = String(now.getDate()).padStart(2, "0");
        const hours = String(now.getHours()).padStart(2, "0");
        const minutes = String(now.getMinutes()).padStart(2, "0");
        const seconds = String(now.getSeconds()).padStart(2, "0");
        return `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;
    };

    const downloadCSV = () => {
        const csv = Papa.unparse(txs);
        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        const formattedDate = getFormattedDate();
        link.setAttribute("download", `transactions_${formattedDate}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <Grid>
            <Grid className={classes.backContainer}>
                <BackButton newTab={false} text="Back" />
            </Grid>
            <Typography
                variant="h6"
                align="center"
                textTransform="uppercase"
                className={classes.sourceClass}
                style={{ color: isDark ? "#ffffff" : "#000" }}
            >
                <span>TRANSACTIONS</span>
            </Typography>
            <Grid container justifyContent="space-between">
                <Typography component="h6" variant="p" textAlign="left" fontSize="14px">
                    <Typography variant="span" className={classes.tileTitle}>
                        {" ("}
            Showing the latest {txs?.length ?? 0} records
                    </Typography>
                    <Typography
                        variant="span"
                        className={cx(classes.tokenName, classes.tileTitle)}
                    >
                        {")"}
                    </Typography>
                </Typography>
                <div style={{ flexDirection: "row" }}>
                    <Chip
                        className={classes.chipItem}
                        label="Export to CSV"
                        onClick={() => downloadCSV()}
                        sx={{
                            marginTop: smallPoint ? "10px" : "0px",
                        }}
                        style={{ marginRight: 6 }}
                    />
                    {!chainFilterApplied && (
                        <Tooltip title="Refreshing data in 20 secs">
                            <Chip
                                className={classes.chipItem}
                                label="Refresh data"
                                onDelete={() =>
                                    fetchAllTxs({ filter, newPage: page, rowsPerPage })
                                }
                                onClick={() =>
                                    fetchAllTxs({ filter, newPage: page, rowsPerPage })
                                }
                                sx={{
                                    marginTop: smallPoint ? "10px" : "0px",
                                }}
                                deleteIcon={
                                    // <Refresh style={{ color: isDark ? "#ffffff" : "#000000" }} />
                                    <CircularProgress
                                        variant="determinate"
                                        value={progress}
                                        className={classes.loaderIcon}
                                    />
                                }
                            />
                        </Tooltip>
                    )}
                </div>
            </Grid>
            <br />
            {console.log("updating txns table")}
            <TxsTable
                isDark={isDark}
                _txs={txs}
                chainFilterApplied={chainFilterApplied}
                totalCount={totalCount || 0}
                fetchAllTxs={fetchAllTxs}
                txLoading={txLoading}
                network={network}
                refreshTxs={fetchAllTxs}
                sortBy={sortBy}
                setSortBy={setSortBy}
                filter={filter}
                setFilter={setFilter}
                page={page}
                setPage={setPage}
            />
        </Grid>
    );
}
