import React, { useEffect, useState } from "react";
import {
    Paper,
    IconButton,
    useTheme,
    Box,
    useMediaQuery,
    Snackbar,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import {
    KeyboardArrowLeft,
    KeyboardArrowRight,
} from "@mui/icons-material";

import useTxnTableStyles from "../../../../components/Tables/common";
import { detect } from "detect-browser";
import { TX_STATUS } from "../../../../utils/constants";
import { useNavigate } from "react-router-dom";
import TxnTableCardTemplate from "../../../../components/Tables/txnTableCardTemplate";

const currencies = [
    { name: 'Algorand', link: '/txs/algorand' },
    { name: 'Solana', link: '/txs/solana' },
    { name: 'Ethereum', link: '/txs/ethereum' },
    { name: 'Avalanche', link: '/txs/avalanche' },
    { name: 'Polygon', link: '/txs/polygon' },
    { name: 'TRON', link: '/txs/tron' },
];

const rowsPerPage = 20;

const TxsTable = ({
    isDark = true,
    _txs,
    totalCount,
    fetchAllTxs,
    txLoading,
    refreshTxs,
    sortBy,
    setSortBy,
    filter,
    setFilter,
    page,
    setPage,
    chainFilterApplied = false,
}) => {
    const theme = useTheme();
    const browser = detect();

    const [order, setOrder] = useState("desc");
    const [orderBy, setOrderBy] = useState("fromTimestamp");

    const smallPoint = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    const { classes, cx } = useTxnTableStyles({
        isDark,
        _txs,
        browserName: browser?.name ?? "",
    });
  
    const [txs, setTxs] = useState([]);
    const [showSnackBar, setShowSnackbar] = React.useState(false);
    const [hoveredIndex, setHoveredIndex] = useState(-1);

    useEffect(() => {
        setTxs(_txs);
    }, [_txs]);

    //Time to local
    
    const filterToggle = async () => {
        let i = TX_STATUS.indexOf(filter);
        i = ++i % TX_STATUS.length;

        setFilter(TX_STATUS[i]);

        if (TX_STATUS[i] === "all") {
            await fetchAllTxs({
                filter: null,
                newPage: page,
                rowsPerPage,
                sort: sortBy,
            });
        } else {
            await fetchAllTxs({
                filter: TX_STATUS[i],
                newPage: page,
                rowsPerPage,
                sort: sortBy,
            });
            setTxs(_txs.filter((tx) => tx.status === TX_STATUS[i]));
        }
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        fetchAllTxs({
            filter,
            newPage: newPage,
            rowsPerPage,
            sort: sortBy,
        });
    };

    const stableSort=(array, comparator) =>{
        const stabilizedThis = array?.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
       
        return stabilizedThis.map((el) => el[0]);
    }

    const getComparator=(order, orderBy) =>{
        return order === "desc"
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }
    
    const descendingComparator=(a, b, orderBy) =>{
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function TablePaginationActions(props) {
        const { count, page, rowsPerPage, onPageChange } = props;

        const handleFirstPageButtonClick = (event) => {
            onPageChange(event, 0);
        };

        const handleBackButtonClick = (event) => {
            onPageChange(event, page - 1);
        };

        const handleNextButtonClick = (event) => {
            onPageChange(event, page + 1);
        };

        const handleLastPageButtonClick = (event) => {
            onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };

        return (
            <Box
                sx={{
                    flexShrink: 0,
                    ml: 2.5,
                    display: "flex",
                    justifyContent: "space-around",
                    width: smallPoint ? "90%" : "auto",
                }}
            >
                <IconButton
                    className={classes.pagingButton}
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label="first page"
                >
                    <p className={classes.tablePaginationExtremes}>First</p>
                </IconButton>
                <IconButton
                    className={classes.pagingButton}
                    onClick={handleBackButtonClick}
                    disabled={page === 0}
                    aria-label="previous page"
                >
                    <KeyboardArrowLeft className={classes.forwardBackIcon} />
                </IconButton>
                <IconButton
                    className={classes.pagingButton}
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    <KeyboardArrowRight className={classes.forwardBackIcon} />
                </IconButton>
                <IconButton
                    className={classes.pagingButton}
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="last page"
                >
                    <p className={classes.tablePaginationExtremes}>Last</p>
                </IconButton>
            </Box>
        );
    }

    const navigate = useNavigate();
    const rowsPerPage = 20;

    function handleClick(x) {
        //if (x?.releaseID) {
        console.log("going to details page", JSON.stringify(x));
        navigate(`/tx/${x?.initID}`);
        //} else {
        //    setShowSnackbar(true);
        //}
    }
    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => setShowSnackbar(false)}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);

        const sortBy =
      property === "fromAmount" && isAsc
          ? "amount_desc"
          : property === "fromAmount" && !isAsc
              ? "amount_asc"
              : property === "fromTimestamp" && isAsc
                  ? "date_desc"
                  : "date_asc";

        setSortBy(sortBy);

        fetchAllTxs({
            filter,
            newPage: page,
            rowsPerPage,
            sort: sortBy,
        });
    };

    return (

        //Return Table
        <Paper
            className={classes.paper}
            style={{ backgroundColor: "transparent" }}
            elevation={0}
        >
            <TxnTableCardTemplate 
                theme={theme}
                loading={txLoading}
                isDark={isDark}
                txs={txs}
                classes={classes}
                cx={cx}
                onRequestSort={handleRequestSort}
                order={order}
                orderBy={orderBy}
                filterToggle={filterToggle}
                filter={filter}
                hoveredIndex={hoveredIndex}
                setHoveredIndex={setHoveredIndex}
                handleClick={handleClick}
                rowsPerPage={rowsPerPage}
                stableSort={stableSort}
                getComparator={getComparator}
                txLoading={txLoading}
                chainFilterApplied={chainFilterApplied}
                totalCount={totalCount}
                page={page}
                handleChangePage={handleChangePage}
                TablePaginationActions={TablePaginationActions}
                refreshTxs={refreshTxs}
                smallPoint={smallPoint}
            >

            </TxnTableCardTemplate>

            {/* Table */}
          
            <Snackbar
                open={showSnackBar}
                autoHideDuration={6000}
                onClose={() => setShowSnackbar(false)}
                message="This transaction has incomplete details, please try later."
                action={action}
            />
        </Paper>
    );
};
export default React.memo(TxsTable);
