import {
    Card,
    CardContent,
    CardMedia,
    Grid,
    Typography,
    useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import BarChart from "../../components/Charts/BarChart";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Graph } from "../Home/components/graph";
import filledCheckBox from "../../assets/images/filledCheckBox.png";
import emptyCheckBox from "../../assets/images/emptyCheckBox.png";
import TransactionHistoryImage from "../../assets/images/transactionhistory.png";
import useStyles from "../Home/HomeStyles";
import axios from "axios";
import MultiAxisLineChart from "../../components/Charts/MultiAxisLineChart";
import Others from "../../reuseable/Others/Others";

const barOptions = {
    maintainAspectRatio: false,
    indexAxis: "y",
    responsive: true,
};
export default function SingleChart({ isDark = true }) {
    const { classes, cx } = useStyles({ isDark });
    const { BackButton } = Others({ isDark });
    const smallPoint = useMediaQuery((theme) => theme.breakpoints.down("sm"));

    const params = useParams();
    const navigate = useNavigate();
    const {
        txs,
        totalTxsCount,
        xgli,
        txsHistory = [],
        loading,
        historyData,
    } = useSelector((state) => state.home);

    const [chartData, setChartData] = useState({});
    const [tokenChartData, setTokenChartData] = useState({});
    const [selectedChart, setSelectedChart] = useState("TRANSACTION_HISTORY"); // TRANSACTION_HISTORY, TOKEN_USERS, CHAIN_USERS
    const [isLoading, setIsLoading] = useState(true);
    const [histories, setHistories] = useState([]);
    const [showData1, setShowData1] = useState(true);
    const [showData2, setShowData2] = useState(false);
    const [showData3, setShowData3] = useState(false);
    const [showData4, setShowData4] = useState(false);
    const [showData5, setShowData5] = useState(false);
    const [showData6, setShowData6] = useState(false);

    const visibleData = [
        showData1 ? histories[0] : null,
        showData2 ? histories[1] : null,
        showData3 ? histories[2] : null,
        showData4 ? histories[3] : null,
        showData5 ? histories[4] : null,
        showData6 ? histories[5] : null,
    ];

    function capitalizeFirstLetter(str) {
        const strMappings = {
            usdc: "USDC",
            sol: "SOL",
            algo: "ALGO",
            euroc: "EUROC",
            nosec: "NOSEC",
        };

        if (str in strMappings) {
            return strMappings[str];
        } else {
            return str.charAt(0).toUpperCase() + str.slice(1);
        }
    }

    useEffect(() => {
        console.log("chart type to show", params?.name);
        if (params?.name?.length > 0) {
            setSelectedChart(params?.name);
        }
    }, [params?.name]);

    useEffect(() => {
        const fetchTokenHistory = async () => {
            const urls = [
                "https://api-v2.glitterfinance.org/api/tokens/volume/usdc",
                "https://api-v2.glitterfinance.org/api/tokens/volume/sol",
                "https://api-v2.glitterfinance.org/api/tokens/volume/algo",
                "https://api-v2.glitterfinance.org/api/tokens/volume/euroc",
            ];

            try {
                const fetchPromises = urls.map((url) => axios.get(url));
                const responses = await Promise.all(fetchPromises);
                const parsedResponses = responses.map((response) => response.data);

                const fetchedHistories = parsedResponses.map(
                    (response) => response.history
                );
                // console.log("fetchedHistories", fetchedHistories);
                setHistories(fetchedHistories);
            } catch (error) {
                console.error("Error fetching token history:", error);
            }
        };

        fetchTokenHistory();
    }, []);

    useEffect(() => {
        if (historyData) {
            setIsLoading(true);
            const chainData = historyData?.byChain;
            const labels = chainData?.map((d) => capitalizeFirstLetter(d.chain));
            const values = chainData?.map((d) => d.uniqueAddresses);
            const formattedChartData = {
                labels: labels,
                datasets: [
                    {
                        label: "Unique Users",
                        data: values,
                        backgroundColor: [
                            "rgba(255, 99, 132, 0.2)",
                            "rgba(54, 162, 235, 0.2)",
                            "rgba(255, 206, 86, 0.2)",
                            "rgba(75, 192, 192, 0.2)",
                        ],
                        borderColor: [
                            "rgba(255, 99, 132, 1)",
                            "rgba(54, 162, 235, 1)",
                            "rgba(255, 206, 86, 1)",
                            "rgba(75, 192, 192, 1)",
                        ],
                        borderWidth: 1,
                    },
                ],
            };
            // console.log("formattedChartData", JSON.stringify(formattedChartData));
            setChartData(formattedChartData);
            const tokenData = historyData?.byToken;
            console.log("tokenData", tokenData)
            const tokenLabels = tokenData?.map((d) => capitalizeFirstLetter(d.token));
            const tokenValues = tokenData?.map((d) => d.uniqueAddresses);
            const formattedTokenChartData = {
                labels: tokenLabels,
                datasets: [
                    {
                        label: "Unique Users",
                        data: tokenValues,
                        backgroundColor: [
                            "rgba(255, 99, 132, 0.2)",
                            "rgba(54, 162, 235, 0.2)",
                            "rgba(255, 206, 86, 0.2)",
                            "rgba(75, 192, 192, 0.2)",
                            "rgba(153, 102, 255, 0.2)",
                        ],
                        borderColor: [
                            "rgba(255, 99, 132, 1)",
                            "rgba(54, 162, 235, 1)",
                            "rgba(255, 206, 86, 1)",
                            "rgba(75, 192, 192, 1)",
                            "rgba(153, 102, 255, 1)",
                        ],
                        borderWidth: 1,
                    },
                ],
            };
            setTokenChartData(formattedTokenChartData);
            setTimeout(() => {
                setIsLoading(false);
            }, 1000);
        }
    }, [historyData]);

    const renderChart = () => {
        const renderCheckboxes = () => {
            const checkboxData = [
                { label: "USDC", showData: showData1, setShowData: setShowData1 },
                { label: "SOL", showData: showData2, setShowData: setShowData2 },
                { label: "ALGO", showData: showData3, setShowData: setShowData3 },
                { label: "EUROC", showData: showData4, setShowData: setShowData4 },
                { label: "NOSEC", showData: showData5, setShowData: setShowData5 },
            ];

            return checkboxData.map(({ label, showData, setShowData }) => (
                <div
                    key={label}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 2,
                    }}
                >
                    <img
                        src={showData ? filledCheckBox : emptyCheckBox}
                        alt="checkbox"
                        onClick={() => setShowData(!showData)}
                        style={{
                            cursor: "pointer",
                            marginRight: smallPoint ? 2 : 8,
                            width: smallPoint ? 20 : 30,
                            height: smallPoint ? 20 : 30,
                        }}
                    />
                    <label
                        onClick={() => setShowData(!showData)}
                        style={{
                            marginRight: smallPoint ? 2 : 24,
                            fontSize: smallPoint ? 12 : 24,
                        }}
                    >
                        {label}
                    </label>
                </div>
            ));
        };

        switch (selectedChart) {
            case "TRANSACTION_HISTORY":
                return (
                    <>
                        <Graph txsHistory={txsHistory} from={"ALL_CHARTS"} />
                    </>
                );
            case "TOKEN_USERS":
                return (
                    !!tokenChartData &&
          tokenChartData?.labels?.length > 0 && (
                        <>
                            <BarChart chartData={tokenChartData} from={"ALL_CHARTS"} />
                        </>
                    )
                );
            case "CHAIN_USERS":
                return (
                    !!chartData &&
          chartData?.labels?.length > 0 && (
                        <>
                            <BarChart chartData={chartData} from={"ALL_CHARTS"} />
                        </>
                    )
                );
            case "TIME_AND_TXN":
                return (
                    <>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                flexWrap: "wrap",
                                justifyContent: "center",
                                gap: 8,
                                marginBottom: 12,
                            }}
                        >
                            {renderCheckboxes()}
                        </div>
                        {histories && (
                            <MultiAxisLineChart data={visibleData} type={selectedChart} />
                        )}
                    </>
                );
            case "TIME_AND_VOL_USD":
                return (
                    <>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                flexWrap: "wrap",
                                justifyContent: "center",
                                gap: 8,
                                marginBottom: 12,
                            }}
                        >
                            {renderCheckboxes()}
                        </div>
                        {histories && (
                            <MultiAxisLineChart data={visibleData} type={selectedChart} />
                        )}
                    </>
                );
            default:
                return null;
        }
    };

    const chartLabels = {
        TRANSACTION_HISTORY: "Transaction history last 14 days",
        TOKEN_USERS: "Token Users",
        CHAIN_USERS: "Chain Users",
        TIME_AND_TXN: "Time vs Transactions per chain",
        TIME_AND_VOL_USD: "Time vs Volume (USD) per chain",
    };

    return (
        <>
            <Grid
                sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "start",
                    flexDirection: "column",
                }}
            >
                <BackButton
                    newTab={false}
                    text="Back to Deep Analytics"
                    href="/charts"
                />
            </Grid>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "start",
                    width: "100%",
                }}
            >
                <Typography
                    variant="h7"
                    // align="left"
                    textTransform="uppercase"
                    className={classes.sourceClass}
                >
                    {chartLabels[selectedChart] || chartLabels.DEFAULT}
                </Typography>
            </div>
            <Grid
                container
                spacing={2}
                style={{
                    justifyContent: smallPoint ? "center" : "flex-end",
                    marginTop: 20,
                    backgroundColor: "rgba(0,0,0,0.6)",
                    padding: 16,
                    borderRadius: 12,
                    minHeight: smallPoint ? "50svh" : "250px",
                }}
            >
                {renderChart()}
            </Grid>
        </>
    );
}
