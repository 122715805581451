import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme, { isDark }) => ({
  backContainer: {
    display: "flex",
    justifyContent: "start",
    alignItems: "start",
    flexDirection: "column",
  },
  sourceClass: {
    color: isDark ? "#ffffff" : "#000",
    fontWeight: "800",
    fontStyle: "normal",
    fontSize: "1.8rem !important",
    fontFamily: "ProximaNova",
    // lineHeight: "41px",
    textAlign: "left",
    marginBottom: "1px",

    [theme.breakpoints.down("md")]: {
      fontSize: "1.4rem",
      lineHeight: "1.3",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.0rem",
      lineHeight: "1",
      marginBottom: "20px",
    },
  },
  sourceClass2: {
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.0rem !important",
      marginBottom: "10px !important",
    },
  },
  chipItem: {
    color: "white",
    border: "1px solid #ffffff78",
  },
  pinIcon: {
    color: "#fff",
    position: "absolute",
    right: "15px",
  },
  tileTitle: {
    color: "rgb(118, 208, 213)",
    fontSize: "14px",
    fontStyle: "normal",
    fontFamily: "Barlow",
    fontWeight: 600,
    lineHeight: "16px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px",
      lineHeight: "15px",
    },
  },
  tokenName: {
    color: "rgba(255, 255, 255, 0.5)",
    fontWeight: "400 !important",
  },
  badgeOverview: {
    marginBottom: "2px",
    height: "10px",
    width: "10px",
    borderRadius: "50%",
    background:
      "linear-gradient(160deg, rgb(255 255 255 / 63%) 7.41%, rgb(22 22 22 / 18%) 93.56%)",
    [theme.breakpoints.down("sm")]: {
      background: "transparent",
    },
  },
  viewTxBtn: {
    height: "30px",
    width: "30px",
    background: isDark
      ? "linear-gradient(145deg, rgb(255 255 255 / 13%) 7.41%, rgb(22 22 22 / 18%) 93.56%)"
      : "",
    border: !isDark ? "1px solid #62686e" : "1px solid rgb(118 208 213 / 29%)",
    color: "#76D0D5",
    borderRadius: "10px",
    marginRight: "10px",
  },
  loaderIcon: {
    width: "33px !important",
    height: "15px !important",
    color: isDark ? "#fff !important" : "#000 !important",
    padding: "0px 5px !important",
  },
  tableCell: {
    fontSize: 13,
    color: isDark ? "#fff" : "#000",
    padding: "0px 2px",
    borderBottom: `1px solid ${isDark ? "#76d0d582" : "#D8DADA"}`,
    fontFamily: "Barlow",
    textTransform: "capitalize",
    [theme.breakpoints.down("md")]: {
      width: "85px",
      minWidth: "85px",
    },
  },
}));

export default styles;
