import React, {useEffect} from "react";
import { useNavigate } from "react-router-dom";

export default function NotFound() {
  
  let navigate = useNavigate()

  useEffect(() => {
   setTimeout(() => {
    navigate('/')
   }, 3000);
  }, [])
  
  return (
    <div
      className="flexCenter"
      style={{
        height: "40vh",
      }}
    >
      Search Not found. Taking you back to home.
    </div>
  );
}
