import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme, { isDark }) => ({
  sourceClass: {
    fontWeight: "800",
    fontStyle: "normal",
    fontSize: "1.8rem !important",
    fontFamily: "ProximaNova",
    lineHeight: "41px",
    textAlign: "left",
    marginBottom: "20px",
    marginTop: "20px",

    [theme.breakpoints.down("md")]: {
      fontSize: "1.4rem !important",
      lineHeight: "1.3",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "26px !important",
      lineHeight: "31px",
      textAlign: "left",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px !important",
      lineHeight: "1",
      marginBottom: "20px",
    },
  },
  tileTitle: {
    color: "aliceblue",
    opacity: 0.6,
    fontSize: "14px",
    fontStyle: "normal",
    fontFamily: "Barlow",
    fontWeight: 600,
    lineHeight: "16px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px !important",
      lineHeight: "15px !important",
    },
  },
  viewTxBtn: {
    height: "30px",
    width: "28px",
    minWidth: "28px",
    background: isDark
      ? "linear-gradient(145deg, rgb(255 255 255 / 13%) 7.41%, rgb(22 22 22 / 18%) 93.56%)"
      : "",
    border: !isDark ? "1px solid #62686e" : "1px solid rgb(118 208 213 / 29%)",
    color: "#76D0D5",
    borderRadius: "10px",
    marginRight: "10px",
  },
  tokenName: {
    color: "rgba(255, 255, 255, 0.5)",
    fontWeight: "400 !important",
  },
  graphContainer: {
    padding: "7px 2%",
  },
  graphSkel: {
    height: "120px",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      height: "100px",
    },
  },
  paginationContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 10,
    alignContent: "center",
    alignItems: "center",
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
  },
}));

export default styles;
