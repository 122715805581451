import React, { useEffect, useState } from "react";

import { CircularProgress, Tooltip } from "@mui/material";
import useStyles from "../ServerStatusStyles";

export default function Progress({ isDark = true, isIdle, loading }) {
  const { classes } = useStyles({ isDark });
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setProgress(0);
  }, [loading]);

  useEffect(() => {
    if (!isIdle) {
      const timer = setInterval(() => {
        setProgress((prevProgress) =>
          prevProgress >= 100 ? 0 : prevProgress + 100 / 60
        );
      }, 1000);
      return () => {
        clearInterval(timer);
      };
    }
  }, [isIdle]);

  return (
    <Tooltip title="Refreshing data in 60 secs" placement="top">
      <CircularProgress
        variant="determinate"
        value={progress}
        className={classes.loaderIcon}
      />
    </Tooltip>
  );
}
