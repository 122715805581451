/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import planetIcon from "../../assets/images/planetsphere_122046 1.svg";
import checklistIcon from "../../assets/images/checklistIcon.svg";
import useStyles from "./HomeStyles";
import SleakBar from "../../reuseable/SleakBar/SleakBar";
import { Graph } from "./components/graph";
import LatestTxsTbl from "./components/LatestTxsTbl";
import {
    getHistoryDataTxns,
    getTxs,
    getTxsHistory,
    getXgliInfo,
} from "./reducers";
import { useDispatch, useSelector } from "react-redux";
import { Button, useMediaQuery } from "@mui/material";
import { TblNoData } from "../../reuseable/TblChunks";
import Others from "../../reuseable/Others/Others";
import { commaSeperated } from "../../utils/apiutils";
import BarChart from "../../components/Charts/BarChart";
import { ProgressBar } from "react-loader-spinner";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import PropTypes from 'prop-types';
import SingletonModule from "../../SingletonModule";

//Validate the props passed to the component
Home.propTypes = {
    isDark: PropTypes.bool,
};

function Home({ isDark = true }) {
    const dispatch = useDispatch();
    const { BackButton } = Others({ isDark });
    const { classes, cx } = useStyles({ isDark });
    const smallPoint = useMediaQuery((theme) => theme.breakpoints.down("md"));
    const [chartData, setChartData] = useState({});
    const [tokenChartData, setTokenChartData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isRotationPaused, setIsRotationPaused] = useState(false);

    const {
        txs,
        totalTxsCount,
        xgli,
        txsHistory = [],
        loading,
        historyData,
    } = useSelector((state) => state.home);

    console.log("historyData ======", historyData);

    useEffect(() => {
        setIsLoading(true);
        dispatch(getTxs());
        dispatch(getHistoryDataTxns());
        dispatch(getXgliInfo());
        dispatch(
            getTxsHistory({
                page: 0,
                rowsPerPage: 30,
            })
        );
        setTimeout(() => {
            setIsLoading(false);
        }, 1000);
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (isRotationPaused == false) {
                setCurrentIndex((currentIndex) => (currentIndex + 1) % 3);
            }
        }, 20000);
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        setCurrentIndex(0);
        if (historyData) {
            setIsLoading(true);
            const chainData = historyData?.byChain;
            const labels = chainData?.map((d) => chainProper(d.chain));
            const values = chainData?.map((d) => d.uniqueAddresses);
            const formattedChartData = {
                labels: labels,
                datasets: [
                    {
                        label: "Unique Users",
                        data: values,
                        backgroundColor: SingletonModule.graphColors,
                        borderColor: [
                            "rgba(255, 255, 255, 1)",
                        ],
                        borderWidth: 2,
                    },
                ],
            };
            console.log("formattedChartData", JSON.stringify(formattedChartData));
            setChartData(formattedChartData);
            const tokenData = historyData?.byToken;
            const tokenLabels = tokenData?.map((d) => tokenProper(d.token));
            const tokenValues = tokenData?.map((d) => d.uniqueAddresses);
            const formattedTokenChartData = {
                labels: tokenLabels,
                datasets: [
                    {
                        label: "Unique Users",
                        data: tokenValues,
                        backgroundColor: [
                            "#ff355ecc",
                            "#fd5b78cc",
                            "#ff6037cc",
                            "#ff9966cc",
                            "#ff9933cc",
                            "#ffcc33cc",
                            "#ffff66cc",
                            "#ccff00cc",
                            "#66ff66cc",
                            "#aaf0d1cc",
                            "#16d0cbcc",
                            "#50bfe6cc",
                            "#9c27b0cc",
                            "#ee34d2cc",
                            "#ff00cccc",
                        ],
                        borderColor: [
                            "rgba(255, 255, 255, 1)",
                        ],
                        borderWidth: 2,
                    },
                ],
            };
            setTokenChartData(formattedTokenChartData);
            setTimeout(() => {
                setIsLoading(false);
            }, 1000);
        }
    }, [historyData]);

    const { price, marketCap, volume } = xgli;

    const data = [
        {
            logo: planetIcon,
            title: "All Time Volume",
            price: historyData?.volumeUSD?.toFixed(2),
            secTokenPrice: "",
            secToken: "",
        },
        {
            logo: checklistIcon,
            title: "Total Bridge Transactions",
            price: historyData?.txns,
            secTokenPrice: "",
            secToken: "",
        },
        {
            logo: checklistIcon,
            title: "Total Bridge Active Users",
            price: historyData?.uniqueAddresses,
            secTokenPrice: "",
            secToken: "",
        },
        {
            logo: checklistIcon,
            title: "Total Bridge Fees",
            price: historyData?.feesUSD?.toFixed(2),
            secTokenPrice: "",
            secToken: "",
        },
    ];

    function tokenProper(str) {
        const strMappings = {
            usdc: "USDC",
            xsol: "xSOL",
            sol: "SOL",
            xalgo: "xALGO",
            algo: "ALGO",
            euroc: "EUROC",  
            nosec: "NOSEC",                
            xnosec: "xNOSEC",        
        };

        if (str in strMappings) {
            return strMappings[str];
        } else {
            return str.charAt(0).toUpperCase() + str.slice(1);
        }
    }
    function chainProper(str) {
        const strMappings = {
            algorand: "Algorand",
            arbitrum: "Arbitrum",
            avalanche: "Avalanche",
            binance: "Binance",
            optimism: "Optimism",
            zkevm: "zkEVM",
            polygon: "Polygon",
            solana: "Solana",
            ethereum: "Ethereum",
            tron: "Tron",          
        };

        if (str in strMappings) {
            return strMappings[str];
        } else {
            return str.charAt(0).toUpperCase() + str.slice(1);
        }
    }

    const handlePrevClick = () => {
    // decrement current slide index
        setIsRotationPaused(true);
        setCurrentIndex((currentIndex) => (currentIndex - 1) % 3);
    };

    const handleNextClick = () => {
    // increment current slide index
        setIsRotationPaused(true);
        setCurrentIndex((currentIndex) => (currentIndex + 1) % 3);
    };

    if (isLoading == true)
        return (
            <ProgressBar
                height="80"
                width="80"
                ariaLabel="progress-bar-loading"
                wrapperStyle={{}}
                wrapperClass="progress-bar-wrapper"
                borderColor="rgb(118, 208, 213)"
                barColor="#ffffff"
            />
        );
    return (
        <Grid>
            <Grid
                sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "start",
                    flexDirection: "column",
                }}
            >
                <BackButton
                    newTab={true}
                    text="Back to Glitter Home"
                    href="http://glitterfinance.org/"
                />

                <BackButton
                    newTab={true}
                    text="Back to Glitter Bridge"
                    href="https://bridge.glitterfinance.org/"
                />
            </Grid>
            <Typography
                variant="h6"
                align="center"
                textTransform="uppercase"
                className={classes.sourceClass}
            >
        Glitter Bridge Explorer
            </Typography>
            <Grid container columnSpacing={2}>
                <Grid item md={12 / 2} sm={12} xs={12}>
                    {data?.map((x, i) => (
                        <React.Fragment key={i}>
                            <SleakBar my={10} px={10} py={2}>
                                <img src={x.logo} height="30" width="30" />
                                <Grid pl={1}>
                                    <Typography
                                        component="h6"
                                        variant="p"
                                        textTransform="uppercase"
                                        className={classes.tileTitle}
                                    >
                                        {x.title}
                                    </Typography>
                                    <Typography component="h6" variant="p">
                                        {x.title == "Total Bridge Transactions" ||
                      x.title == "Total Bridge Active Users"
                                            ? commaSeperated(x.price ?? "Loading...")
                                            : "$ " + commaSeperated(x.price ?? "Loading...")}
                                        <Typography
                                            variant="span"
                                            className={cx(classes.tokenName, classes.tileTitle)}
                                        >
                                            {x.secTokenPrice != "" ? "  (" : ""}
                                            {commaSeperated(x.secTokenPrice ?? "Loading...")}{" "}
                                        </Typography>
                                        <Typography variant="span" className={classes.tileTitle}>
                                            {x.secToken}
                                            <Typography
                                                variant="span"
                                                className={cx(classes.tokenName, classes.tileTitle)}
                                            >
                                                {x.secTokenPrice != "" ? ")" : ""}
                                            </Typography>
                                        </Typography>
                                    </Typography>
                                </Grid>
                            </SleakBar>
                        </React.Fragment>
                    ))}
                </Grid>
                <Grid item md={12 / 2} sm={12} xs={12}>
                    <SleakBar my={7} px={20} py={2}>
                        <Grid width="100%" height="90%" className={classes.graphContainer}>
                            {txs.length === 0 ? (
                                <Grid className={classes.graphSkel}>
                                    <TblNoData
                                        w={"100%"}
                                        isDark={isDark}
                                        msg={`No ${""} Data Available`}
                                    />
                                </Grid>
                            ) : (
                                <div
                                    style={{
                                        position: "relative",
                                        paddingBottom: 35,
                                        height: smallPoint ? "auto" : "auto",
                                    }}
                                >
                                    {currentIndex == 0 ? (
                                        <div>
                                            <Typography
                                                component="h6"
                                                variant="p"
                                                textTransform="uppercase"
                                                sx={{
                                                    fontSize: smallPoint ? 10 : 14,
                                                }}
                                            >
                        Transaction history last 14 days
                                            </Typography>
                                            <Graph txsHistory={txsHistory} />
                                        </div>
                                    ) : currentIndex == 1 ? (
                                        <div>
                                            <Typography
                                                component="h6"
                                                variant="p"
                                                textTransform="uppercase"
                                                sx={{
                                                    fontSize: smallPoint ? 10 : 14,
                                                }}
                                            >
                        Token Users
                                            </Typography>
                                            {!!tokenChartData &&
                        tokenChartData?.labels?.length > 0 ? (
                                                    <BarChart chartData={tokenChartData} />
                                                ) : null}
                                        </div>
                                    ) : (
                                        <div>
                                            <Typography
                                                component="h6"
                                                variant="p"
                                                textTransform="uppercase"
                                                sx={{
                                                    fontSize: smallPoint ? 10 : 14,
                                                }}
                                            >
                        Chain Users
                                            </Typography>
                                            {!!chartData && chartData?.labels?.length > 0 ? (
                                                <BarChart chartData={chartData} />
                                            ) : null}
                                        </div>
                                    )}
                                    <div className={classes.paginationContainer}>
                                        <Button
                                            variant="text"
                                            style={{ color: "#ffffff", fontSize: 10 }}
                                            onClick={() => handlePrevClick()}
                                        >
                      Previous
                                        </Button>
                                        <div
                                            style={{
                                                height: 10,
                                                width: 10,
                                                marginRight: 5,
                                                borderRadius: 100,
                                                backgroundColor:
                          currentIndex == 0 ? "#ffffff" : "#3c3c3c",
                                            }}
                                        />
                                        <div
                                            style={{
                                                height: 10,
                                                width: 10,
                                                marginRight: 5,
                                                borderRadius: 100,
                                                backgroundColor:
                          currentIndex == 1 ? "#ffffff" : "#3c3c3c",
                                            }}
                                        />
                                        <div
                                            style={{
                                                height: 10,
                                                width: 10,
                                                marginRight: 5,
                                                borderRadius: 100,
                                                backgroundColor:
                          currentIndex == 2 ? "#ffffff" : "#3c3c3c",
                                            }}
                                        />
                                        <Button
                                            variant="text"
                                            style={{ color: "#ffffff", fontSize: 10 }}
                                            onClick={() => handleNextClick()}
                                        >
                      Next
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </Grid>
                    </SleakBar>
                </Grid>
            </Grid>

            <LatestTxsTbl isDark={isDark} txLoading={loading} txs={txs.slice(0, 5)} />
        </Grid>
    );
}

export default Home;
