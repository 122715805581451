import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";

import SleakBar from "../../../reuseable/SleakBar/SleakBar";
import { PushPin, FileCopyOutlined } from "@mui/icons-material";
import { capitalize, getChainIcon, truncateStr } from "../../../utils/apiutils";
import { StandardSkeleton } from "../../../reuseable/TblChunks";

export default function AddressTile({
    index,
    item: { address = "", chain = "" },
    smallPoint,
    classes,
    txLoading,
    isDark = true,
    handleStarClick,
    isItemExistInLocalStorage,
}) {
    const [isCopied, setIsCopied] = useState(false);

    return txLoading ? (
        <StandardSkeleton h={50} />
    ) : (
        <SleakBar my={10} px={0} py={2}>
            <Grid sx={{ width: "100%" }}>
                <SleakBar my={0} px={20} py={2}>
                    <Typography
                        component="span"
                        variant="p"
                        className={classes.tileTitle}
                        style={{ color: "#fff" }}
                    >
            Address#0{index + 1} &nbsp;
                    </Typography>

                    <Typography
                        component="span"
                        variant="p"
                        id={"address" + index}
                        className={classes.tileTitle}
                    >
                        {smallPoint ? truncateStr(address || "", 16) : address}
                    </Typography>
                    {isCopied ? (
                        <Typography
                            component="span"
                            variant="p"
                            className={classes.tileTitle}
                            style={{ color: "#fff", padding: "0px 10px" }}
                        >
              Copied
                        </Typography>
                    ) : (
                        <Tooltip title="Copy address">
                            <IconButton
                                onClick={() => {
                                    navigator.clipboard.writeText(address);
                                    setIsCopied(true);
                                    setTimeout(() => setIsCopied(false), 2000);
                                }}
                                style={{
                                    padding: "10px",
                                }}
                                edge="end"
                            >
                                <FileCopyOutlined
                                    style={{
                                        color: isDark ? "#ffffff" : "#000000",
                                        fontSize: "16px",
                                    }}
                                />
                            </IconButton>
                        </Tooltip>
                    )}

                    {/* show pin icon on first two items only */}
                    {index < 2 && (
                        <IconButton
                            className={classes.pinIcon}
                            onClick={() =>
                                handleStarClick({
                                    item: address,
                                })
                            }
                        >
                            <Tooltip
                                title={
                                    isItemExistInLocalStorage(address) ? "Pinned" : "Unpinned"
                                }
                            >
                                <PushPin
                                    sx={{
                                        transform: !isItemExistInLocalStorage(address)
                                            ? "rotate(45deg)"
                                            : "",
                                    }}
                                />
                            </Tooltip>
                        </IconButton>
                    )}
                </SleakBar>

                <Grid container sx={{ display: "flex" }}>
                    <Grid item md={5} p={2} sx={{ width: "auto", display: "flex" }}>
                        <img src={getChainIcon(chain)} height="30" width="30" />
                        <Grid pl={1}>
                            <Typography
                                component="h6"
                                variant="p"
                                className={classes.tileTitle}
                            >
                Account Type
                            </Typography>
                            <Typography component="h6" variant="p">
                                {capitalize(chain || "")}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </SleakBar>
    );
}
