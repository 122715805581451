import React from 'react';
import {
    Alert,
    Button,
    IconButton,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { FileCopyOutlined } from "@mui/icons-material";
import { useState } from "react";
import {
    CheckCircle,
    Warning,
    RemoveCircle,
    Settings,
} from "@mui/icons-material";

import algoLogoDark from "../../assets/images/algo.png";
import algoLogoWhite from "../../assets/images/algo-white.png";
import solanaLogo from "../../assets/images/solana.svg";
import xAlgoLogo from "../../assets/images/xalgo.png";
import xSolLogo from "../../assets/images/xsol.png";
import useStyles from "./OthersStyles";
import { EXPLORER_STATUS, TRON } from "../../utils/constants";

const Others = ({ isDark = true }) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const { classes, cx } = useStyles({ isDark });

    const AlertBar = ({ message }) => {
        return (
            <Alert
                variant="outlined"
                severity="error"
                className={cx(
                    isDark ? classes.paperBgDark : classes.paperBgLight,
                    classes.myAlert
                )}
                style={{
                    color: isDark ? "#fff" : "#000",
                }}
            >
                {message}
            </Alert>
        );
    };

    const BackButton = ({ text, newTab, href }) => {
        return !newTab ? (
            <Button
                size="small"
                onClick={() => navigate(-1)}
                className={classes.activeBtn}
            >
                <ChevronLeftIcon className={classes.backicon} />
                {text}
            </Button>
        ) : (
            <Button
                href={href}
                target="_blank"
                size="small"
                className={classes.activeBtn}
            >
                <ChevronLeftIcon className={classes.backicon} />
                {text}
            </Button>
        );
    };

    const ChainLogoList = ({ isDark = true }) => {
        const chainLogoList = [
            {
                key: "ALGO",
                icon: isDark ? algoLogoWhite : algoLogoDark,
            },
            {
                key: "xSOL",
                icon: xSolLogo,
            },
            {
                key: "SOL",
                icon: solanaLogo,
            },
            {
                key: "xALGO",
                icon: xAlgoLogo,
            },
        ];
        return chainLogoList;
    };

    const getExplorerBaseUrl = (network) => {
        if (!network) return "";

        switch (network.toLowerCase()) {
            case "algorand":
                return "https://algoexplorer.io/";
            case "solana":
                return "https://explorer.solana.com/";
            case "ethereum":
                return "https://etherscan.io/";
            case "avalanche":
                return "https://snowtrace.io/";
            case "tron":
                return "https://tronscan.org/#/";
            case "polygon":
                return "https://polygonscan.com/";
            case "binance":
                return "https://bscscan.com/";
            case "arbitrum":
                return "https://arbiscan.io/";
            case "optimism":
                return "https://optimistic.etherscan.io/";
            case "zkevm":
                return "https://zkevm.polygonscan.com/";
            default:
                return "";
        }
    }
    const getExplorerTxnUrl=(network, txnId) =>{
        const baseUrl = getExplorerBaseUrl(network);

        if (network === "tron") return `${baseUrl}transaction/${txnId}`;
        return `${baseUrl}tx/${txnId}`;
    }
    const getExplorerAddressUrl=(network, address) =>{
        const baseUrl = getExplorerBaseUrl(network);
        return `${baseUrl}address/${address}`;
    }

    const algorandTxUrl = (network, algorandTxId) =>
        `https://${
            network === "testnet" ? "testnet." : ""
        }algoexplorer.io/tx/${algorandTxId}`;

    const solanaTxnSignatureUrl = (network, solanaTxId) =>
        `https://solscan.io/tx/${solanaTxId}?${
            network === "mainnet" ? "" : "cluster=testnet"
        }`;

    const ethereumTxnUrl = (network, ethereumTxId) =>
        `https://${
            network === "mainnet" ? "etherscan.io" : "goerli.etherscan.io/"
        }/tx/${ethereumTxId}`;

    const avalancheTxnUrl = (network, avalancheTxId) =>
        `https://${
            network === "testnet" ? "testnet." : ""
        }snowtrace.io/tx/${avalancheTxId}`;

    const tronTxnUrl = (network, tronTxId) =>
        `https://${
            network === "testnet" ? "shasta." : ""
        }tronscan.org/#/transaction/${tronTxId}`;

    const polygonTxnUrl = (network, polygonTxId) =>
        `https://${
            network === "testnet" ? "mumbai." : ""
        }polygonscan.com/tx/${polygonTxId}`;

    const getTxUrlByChain = (chain, network, txId) => {
        switch (chain) {
            case "algorand":
                return algorandTxUrl(network, txId);
            case "solana":
                return solanaTxnSignatureUrl(network, txId);
            case "ethereum":
                return ethereumTxnUrl(network, txId);
            case "avalanche":
                return avalancheTxnUrl(network, txId);
            case "polygon":
                return polygonTxnUrl(network, txId);
            case TRON:
                return tronTxnUrl(network, txId);
            default:
                return "";
        }
    };

    const algorandTxUrlWithAddress = (network, algorandAddress) =>
        `https://${
            network === "testnet" ? "testnet." : ""
        }algoexplorer.io/address/${algorandAddress}`;

    const solanaTxnSignatureUrlWithAddress = (
        network,
        solanaAddress
    ) =>
        `https://solscan.io/address/${solanaAddress}?${
            network === "mainnet" ? "" : "cluster=testnet"
        }`;

    const ethereumTxnUrlWithAddress = (network, ethereumAddress) =>
        `https://${
            network === "mainnet" ? "etherscan.io" : "goerli.etherscan.io/"
        }/address/${ethereumAddress}`;

    const avalancheTxnUrlWithAddress = (
        network,
        avalancheAddress
    ) =>
        `https://${
            network === "testnet" ? "testnet." : ""
        }snowtrace.io/address/${avalancheAddress}`;

    const tronTxnUrlWithAddress = (network, tronAddress) =>
        `https://${
            network === "testnet" ? "shasta." : ""
        }tronscan.org/#/address/${tronAddress}`;

    const polygonTxnUrlWithAddress = (network, polygonAddress) =>
        `https://${
            network === "testnet" ? "mumbai." : ""
        }polygonscan.com/address/${polygonAddress}`;

    const getTxAddressUrlByChain = (chain, network, address) => {
        switch (chain) {
            case "algorand":
                return algorandTxUrlWithAddress(network, address);
            case "solana":
                return solanaTxnSignatureUrlWithAddress(network, address);
            case "ethereum":
                return ethereumTxnUrlWithAddress(network, address);
            case "avalanche":
                return avalancheTxnUrlWithAddress(network, address);
            case "polygon":
                return polygonTxnUrlWithAddress(network, address);
            case TRON:
                return tronTxnUrlWithAddress(network, address);
            default:
                return "";
        }
    };

    const CopyToClipboard = ({ text, tooltipText="Copy Address" }) => {
        const [isCopied, setIsCopied] = useState(false);
        return isCopied ? (
            <Typography
                component="span"
                variant="p"
                className={classes.tileTitle}
                style={{ color: "#fff", padding: "0px 10px" }}
            >
        Copied
            </Typography>
        ) : (
            <Tooltip title={tooltipText}>
                <IconButton
                    onClick={() => {
                        navigator.clipboard.writeText(text);
                        setIsCopied(true);
                        setTimeout(() => setIsCopied(false), 2000);
                    }}
                    style={{
                        padding: "0px 10px",
                    }}
                    edge="end"
                >
                    <FileCopyOutlined
                        style={{
                            color: isDark ? "#ffffff" : "#000000",
                            fontSize: "16px",
                        }}
                    />
                </IconButton>
            </Tooltip>
        );
    };

    const checkColor = (status) => {
        switch (status) {
            case EXPLORER_STATUS[0]:
                return {
                    msgHeader: "All services online",
                    msg: "All services online",
                    color: theme.palette.success.main,
                    icon: (
                        <Tooltip title="Operational" placement="top">
                            <CheckCircle
                                className={classes.statusIcon}
                                style={{ color: theme.palette.success.main }}
                            />
                        </Tooltip>
                    ),
                };
            case EXPLORER_STATUS[1]:
                return {
                    msgHeader:
            "Some services are currently degraded. We are working on restoring service as soon as possible. Please check out our twitter for any service notifications: https://twitter.com/GlitterFinance",
                    msg: (
                        <span>
                            <span>Some services are currently degraded. </span>
                            <br />
                            <span style={{ fontSize: "0.6rem", lineHeight: "1.0" }}>
                We are working on restoring service as soon as possible. Please
                check out our twitter for any service notifications:
                https://twitter.com/GlitterFinance
                            </span>
                        </span>
                    ),
                    color: "#f49342",
                    icon: (
                        <Tooltip title="Degraded" placement="top">
                            <Warning
                                className={classes.statusIcon}
                                style={{ color: "#f49342" }}
                            />
                        </Tooltip>
                    ),
                };
            case EXPLORER_STATUS[2]:
                return {
                    msgHeader:
            "Some services are currently unavailable. We are working on restoring service as soon as possible. Please check out our twitter for any service notifications: https://twitter.com/GlitterFinance",
                    msg: (
                        <span>
                            <span>Some services are currently unavailable. </span>
                            <br />
                            <span style={{ fontSize: "0.6rem" }}>
                We are working on restoring service as soon as possible. Please
                check out our twitter for any service notifications:
                https://twitter.com/GlitterFinance
                            </span>
                        </span>
                    ),
                    color: theme.palette.error.main,
                    icon: (
                        <Tooltip title="Downtime" placement="top">
                            <RemoveCircle
                                className={classes.statusIcon}
                                style={{ color: theme.palette.error.main }}
                            />
                        </Tooltip>
                    ),
                };
            case EXPLORER_STATUS[3]:
                return {
                    msgHeader: "Some services are currently under maintenance",
                    msg: "Some services are currently under maintenance",
                    color: "#5F59F7",
                    icon: (
                        <Tooltip title="Maintenance" placement="top">
                            <Settings
                                className={classes.statusIcon}
                                style={{ color: "#5F59F7" }}
                            />
                        </Tooltip>
                    ),
                };
            default:
                return {
                    msgHeader: "All services are online",
                    msg: "All services are online",
                    color: "#fff",
                    icon: (
                        <Tooltip title="Operational" placement="top">
                            <CheckCircle
                                className={classes.statusIcons}
                                style={{ color: theme.palette.success.main }}
                            />
                        </Tooltip>
                    ),
                };
        }
    };

    return {
        AlertBar,
        ChainLogoList,
        algorandTxUrl,
        solanaTxnSignatureUrl,
        ethereumTxnUrl,
        avalancheTxnUrl,
        getTxUrlByChain,
        getExplorerAddressUrl,
        getExplorerBaseUrl,
        getExplorerTxnUrl,
        BackButton,
        CopyToClipboard,
        checkColor,
        getTxAddressUrlByChain
    };
};

export default Others;
