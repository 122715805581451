import { combineReducers, configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
// import logger from 'redux-logger';
import { all, fork } from "redux-saga/effects";

import HomeReducer from "../pages/Home/reducers";
import HomeSaga from "../pages/Home/sagas";
import AllTxsReducer from "../pages/AllTxs/reducers";
import AllTxsSaga from "../pages/AllTxs/sagas";
import TxProfileReducer from "../pages/TxProfile/reducers";
import TxProfileSaga from "../pages/TxProfile/sagas";
import AddressProfileReducer from "../pages/AddressProfile/reducers";
import AddressProfileSaga from "../pages/AddressProfile/sagas";
import ServerStatusReducer from "../pages/ServerStatus/reducers";
import ServerStatusSaga from "../pages/ServerStatus/sagas";

const forkAll = (...sagas) => all(sagas.map((saga) => fork(saga)));

const rootSaga = function* saga() {
  yield forkAll(
    HomeSaga,
    AllTxsSaga,
    TxProfileSaga,
    AddressProfileSaga,
    ServerStatusSaga
  );
};

const rootReducer = {
  home: HomeReducer,
  allTxs: AllTxsReducer,
  txProfile: TxProfileReducer,
  addressProfile: AddressProfileReducer,
  serverStatus: ServerStatusReducer,
};

const redux = combineReducers(rootReducer);
const saga = createSagaMiddleware();
const middleware = [saga];

const store = configureStore({
  reducer: redux,
  devTools: true,
  middleware,
});

saga.run(rootSaga);
export default store;
