import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme) => ({
    alertBanner: {
        background: "rgb(170, 60, 160)",
        color: "#fff",
        padding: "10px",
        textAlign: "center",
        fontSize: "12px",
        fontWeight: "normal",
    },
    rootContainer: {
        background: "#fff",
    },
    root: {
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        height: "100vh",
    },
    rootDark: {
        background: theme.palette.update2Palette.mainBgGradient2Dark,
    },
    rootLight: {
        background: theme.palette.update2Palette.mainBgGradient2Light,
    },
    container: {
        display: "flex",
        flex: 1,
        maxWidth: "100%",
        height: "75%%",
        // [theme.breakpoints.down("sm")]: {
        //     width: "100%",
        //     maxWidth: "100%",
        // },
    },
    main: {
        flex: 1,
        paddingTop: "10px",
        backgroundPosition: "-4.4% 114%, 101% -3%",
        backgroundRepeat: "no-repeat, no-repeat",
        backgroundSize: "16%, 8.5%",
        backgroundOrigin: "content-box",
        position: "relative",
        width: "100%",
       
        [theme.breakpoints.down("lg")]: {
            backgroundPosition: "-4.4% 114%, 101% -3%",
        },
        [theme.breakpoints.down("md")]: {
            backgroundPosition: "-4.4% 105%, 101% -1%",
        },
        [theme.breakpoints.down("sm")]: {
            backgroundSize: "27%, 16%",
            backgroundPosition: "-10.4% 100%, 101% -1%",
            display: "inline-block",
            textAlign: "center",
        },
    },
    tabMarginTop: {
        color: "#fff",
        flexGrow: 1,
        width: "90%",
        margin: "auto",
        marginTop: "2%",
        minHeight: "515px",
        position: "relative",
        [theme.breakpoints.down("md")]: {
            minHeight: "70%",
        },
        [theme.breakpoints.down("sm")]: {
            minHeight: "676px",
            width: "83%",
            marginTop: "8%",
            zIndex: 1,
            position: "relative",
        },
        [theme.breakpoints.down("sm")]: {
            // minHeight: "auto",
            paddingBottom: 0,
            marginTop: "25px",
            borderRadius: "10px !important",
        },
    },
}));

export default styles;
