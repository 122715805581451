import { createSlice } from "@reduxjs/toolkit";
import { NotificationManager } from "react-notifications";

const slice = createSlice({
    name: "allTxs",
    initialState: {
        loading: false,
        txs: [],
        totalCount: 0,
        error: "",
    },
    reducers: {
        getAllTxs: (state, { payload }) => {
            state.loading = true;
        },
        getAllTxsSuccess: (state, { payload }) => {
            state.loading = false;
            state.txs = payload.transactions;
            state.totalCount = payload.totalCount;
        },
        getAllTxsFailed: (state, { payload }) => {
            state.loading = false;
            state.txs = [];
            state.totalCount = 0;
        },
    },
});

export const { getAllTxs, getAllTxsSuccess, getAllTxsFailed } = slice.actions;

export default slice.reducer;
