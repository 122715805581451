import {
    Chip,
    CircularProgress,
    Grid,
    IconButton,
    Tooltip,
    Typography,
    useMediaQuery,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";

import useStyles from "./AddressProfileStyles";
import TxsTable from "./components/TxsTable/TxsTable";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAddressProfile } from "./reducers";
import Others from "../../reuseable/Others/Others";
import { QuestionMark } from "@mui/icons-material";
import { txs as _txs } from "../../utils/data";
import AddressTile from "./components/AddressTile";
import { StandardSkeleton } from "../../reuseable/TblChunks";

const rowsPerPage = 20;

export default function AddressProfile({ isDark = true }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const { BackButton } = Others({ isDark });
    const { classes, cx } = useStyles({ isDark });
    const smallPoint = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    const {
        txDetails,
        accounts,
        loading: txLoading,
    } = useSelector((state) => state.addressProfile);
    const addresses = JSON.parse(localStorage.getItem("addresses")) ?? [];

    const [flag, setFlag] = useState(false);
    const [progress, setProgress] = useState(100);
    const [txs, setTxs] = useState([]);
    const [sortBy, setSortBy] = useState("date_desc");
    const [filter, setFilter] = useState("all");
    const [page, setPage] = useState(0);
    // const [filterByAddress, setFilterByAddress] = useState(0);
    const [network] = useState("mainnet");
    const [searchedAddress, setSearchedAddress] = useState(
        addresses ? addresses : []
    );

    useEffect(() => {
        setTxs([txDetails?.txs]);
    }, []);
    useEffect(() => {
        dispatch(getAddressProfile({ addresses: searchedAddress }));
    }, []);
    useEffect(() => {
    // debugger;
        if (
            params?.id?.length === 66 ||
      params?.id?.length === 58 ||
      (params?.id?.length >= 32 && params?.id?.length <= 44) ||
      params?.id?.length === 103
        ) {
            if (params?.id.includes("_")) {
                // eslint-disable-next-line no-unsafe-optional-chaining
                const [address1, address2] = params?.id.split("_");
                if (
                    !searchedAddress?.includes(address1) &&
          !searchedAddress?.includes(address2)
                ) {
                    setSearchedAddress([...searchedAddress, address1, address2]);
                    dispatch(
                        getAddressProfile({
                            addresses: [...searchedAddress, address1, address2],
                        })
                    );
                }
            } else {
                // if searched address not already in localstorage then add it

                // if addresses array contains params.id then don't add it
                if (!addresses?.includes(params?.id) && addresses?.length < 2) {
                    setSearchedAddress([...addresses, params?.id]);
                    dispatch(
                        getAddressProfile({
                            addresses: [...addresses, params?.id],
                        })
                    );
                }
            }
        } else {
            navigate(`/notfound/${params?.id}`);
        }
    }, [params?.id]);

    const fetchAllTxs = ({
        filter,
        newPage,
        rowsPerPage,
        sort = "date_desc",
    }) => {
        dispatch(
            getAddressProfile({
                addresses: searchedAddress,
                status: filter,
                newPage,
                rowsPerPage,
                sort,
            })
        );
        setProgress(0);
        setFilter(filter);
        setSortBy(sort);
    };

    const isItemExistInLocalStorage = (item) => {
        const _addresses = localStorage.getItem("addresses");
        if (_addresses) {
            const parsed_addresses = JSON.parse(_addresses);
            const _addressIndex = parsed_addresses.findIndex((x) => x === item);
            if (_addressIndex !== -1) {
                return true;
            }
        }
        return false;
    };

    const handleStarClick = ({ item }) => {
    // if address find in local storage remove it otherwise add it
        const _addresses = localStorage.getItem("addresses");
        //save array in local storage
        if (_addresses) {
            const parsed_addresses = JSON.parse(_addresses);
            const _addressIndex = parsed_addresses.findIndex((x) => x === item);
            if (_addressIndex !== -1) {
                parsed_addresses.splice(_addressIndex, 1);
                localStorage.setItem("addresses", JSON.stringify(parsed_addresses));
            } else {
                parsed_addresses.push(item);
                localStorage.setItem("addresses", JSON.stringify(parsed_addresses));
            }
        } else {
            localStorage.setItem("addresses", JSON.stringify([item]));
        }
        setFlag(!flag);
    };
    const overViewInstructions =
    "Please Note: You can pin max two addresses & below in Transactions section there will be mixed txs of all address.";

    return (
        <>
            <Grid className={classes.backContainer}>
                <BackButton newTab={false} text="Back" />
            </Grid>
            <Typography
                variant="h6"
                align="center"
                textTransform="uppercase"
                className={cx(classes.sourceClass, classes.sourceClass2)}
                style={{ color: isDark ? "#ffffff" : "#000" }}
            >
        Account Search:
            </Typography>

            {/* Overview */}

            <Typography
                variant="h5"
                align="center"
                textTransform="uppercase"
                className={(classes.sourceClass, classes.sourceClass2)}
                style={{
                    color: isDark ? "#ffffff" : "#000",
                    fontSize: "15px !important",
                    lineHeight: "0px !important",
                    fontWeight: "500 !important",
                    marginTop: "30px ",
                }}
            >
        Overview{" "}
                <Tooltip title={overViewInstructions} placement="top">
                    <IconButton className={classes.badgeOverview}>
                        <QuestionMark sx={{ fontSize: 12 }} />
                    </IconButton>
                </Tooltip>
            </Typography>

            {txLoading ? (
                <StandardSkeleton h={50} />
            ) : (
                accounts?.map((item, index) => (
                    <Fragment key={index}>
                        <AddressTile
                            index={index}
                            item={item}
                            smallPoint={smallPoint}
                            classes={classes}
                            isDark={true}
                            handleStarClick={handleStarClick}
                            isItemExistInLocalStorage={isItemExistInLocalStorage}
                        />
                    </Fragment>
                ))
            )}

            {/* TRANSACTIONS */}
            <Typography
                variant="h5"
                align="center"
                textTransform="uppercase"
                className={cx(classes.sourceClass, classes.sourceClass2)}
                sx={{
                    marginTop: "30px ",
                    fontWeight: "500 !important",
                }}
            >
        TRANSACTIONS
            </Typography>
            <Grid container justifyContent="space-between">
                <Typography component="h6" variant="p" textAlign="left" fontSize="14px">
                    {txDetails?.totalCount > 0
                        ? `More than > ${txDetails?.totalCount - 1} transactions found`
                        : "0 transactions found"}
                    <Typography variant="span" className={classes.tileTitle}>
                        {" ("}
            Showing the latest {txDetails?.txs?.length ?? 0} records
                    </Typography>
                    <Typography
                        variant="span"
                        className={cx(classes.tokenName, classes.tileTitle)}
                    >
                        {")"}
                    </Typography>
                </Typography>
                {false && (
                    <Tooltip title="Refreshing data in 20 secs">
                        <Chip
                            className={classes.chipItem}
                            label="Refresh data"
                            onDelete={() =>
                                fetchAllTxs({ filter, newPage: page, rowsPerPage })
                            }
                            onClick={() =>
                                fetchAllTxs({ filter, newPage: page, rowsPerPage })
                            }
                            sx={{
                                marginTop: smallPoint ? "10px" : "0px",
                            }}
                            deleteIcon={
                                // <Refresh style={{ color: isDark ? "#ffffff" : "#000000" }} />
                                <CircularProgress
                                    variant="determinate"
                                    value={progress}
                                    className={classes.loaderIcon}
                                />
                            }
                        />
                    </Tooltip>
                )}
            </Grid>
            <br />
            <TxsTable
                isDark={isDark}
                txs={txDetails?.txs}
                setTxs={setTxs}
                totalCount={txDetails?.totalCount}
                fetchAllTxs={fetchAllTxs}
                txLoading={txLoading}
                network={network}
                refreshTxs={fetchAllTxs}
                filter={filter}
                setFilter={setFilter}
                sortBy={sortBy}
                setSortBy={setSortBy}
                page={page}
                setPage={setPage}
            />
        </>
    );
}
