import { Circle, Menu, OpenInNew } from "@mui/icons-material";
import {
    Alert,
    AppBar,
    Box,
    Button,
    Divider,
    Drawer,
    FormControlLabel,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Switch,
    Toolbar,
    Tooltip,
    Typography,
    useMediaQuery,
} from "@mui/material";
import StackedLineChartIcon from "@mui/icons-material/StackedLineChart";
import React, { useEffect, useState } from "react";
import IdleTimer from "react-idle-timer";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import logoWithCyril from "../../assets/images/Glitter_logotype_horizontal-01.png";
import config from "../../config.json";
import { getServerStatus } from "../../pages/ServerStatus/reducers";
import Others from "../../reuseable/Others/Others";
import SearchBar from "../../reuseable/SearchBar/SearchBar";
import { EXPLORER_STATUS } from "../../utils/constants";
import useStyles from "./HeaderStyles";

const MIN = 60 * 1000;
const drawerWidth = 240;
function Header({ toggleDrawer, isDark = true }) {
    const { classes, cx } = useStyles();
    const dispatch = useDispatch();
    const params = useParams();
    const [checked, setChecked] = React.useState(true);
    const smallPoint = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    const navigate = useNavigate();
    const [isIdle, setIsIdle] = useState(false);
    const { serverStatus } = useSelector((state) => state.serverStatus);
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const handleChange = (event) => {
        setChecked(event.target.checked);
        if (event.target.checked == true) {
            localStorage.setItem("explorernetwork", "mainnet");
            window.location.reload();
        } else {
            localStorage.setItem("explorernetwork", "testnet");
            window.location.reload();
        }
    };

    const drawer = (
        <MobileNavigationLinks
            classes={classes}
            isDark={isDark}
            serverStatus={serverStatus}
            checked={checked}
            handleChange={handleChange}
            navigate={navigate}
        />
    );

    const container =
    window !== undefined ? () => window.document.body : undefined;

    useEffect(() => {
        setNetworkIfNotPresent();
        const currentNetwork = localStorage.getItem("explorernetwork");
        if (currentNetwork == "mainnet") {
            setChecked(true);
        } else {
            setChecked(false);
        }
        dispatch(getServerStatus());
    }, []);

    const setNetworkIfNotPresent = () => {
        const currentNetwork = localStorage.getItem("explorernetwork");
        if (currentNetwork) {
            return;
        } else {
            localStorage.setItem("explorernetwork", "mainnet");
        }
    };

    useEffect(() => {
    // if not idle and not on status page
        if (!isIdle && params?.["*"] !== "status") {
            const statusPingInterval = setInterval(async () => {
                dispatch(getServerStatus());
                console.log("ping in header");
            }, config.settings.polling_interval);
            return () => clearInterval(statusPingInterval);
        }
    }, [isIdle]);

    const url = window.location.pathname;

    return (
        <Box>
            <IdleTimer
                ref={(ref) => {
                    // eslint-disable-next-line no-self-assign
                    ref = ref;
                }}
                element={document}
                onIdle={() => setIsIdle(true)}
                onActive={() => setIsIdle(false)}
                timeout={5 * MIN}
            />
            <AppBar
                component="nav"
                position="static"
                className={classes.appbar}
                elevation={0}
            >
                <Toolbar
                    classes={{
                        root: classes.toolbarRoot,
                    }}
                    disableGutters
                >
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: "18px",
                        }}
                    >
                        <Box>
                            <Logo classes={classes}></Logo>
                        </Box>
                        <Box
                            sx={{
                                flexGrow: 1,
                                display: {
                                    md: "flex",
                                    xs: "none",
                                },
                            }}
                        >
                            <SearchBar isDark={isDark} />
                        </Box>
                        <Box
                            sx={{
                                display: {
                                    lg: "flex",
                                    xs: "none",
                                },
                                alignItems: "center",
                                gap: "8px",
                            }}
                        >
                            <NavigationLinks
                                classes={classes}
                                isDark={isDark}
                                serverStatus={serverStatus}
                                checked={checked}
                                navigate={navigate}
                                handleChange={handleChange}
                            />
                        </Box>
                        <Box sx={{ display: { lg: "none", xs: "flex", gap: "16px" } }}>
                            <ExplorerStatus
                                isDark={isDark}
                                serverStatus={checkStatus(serverStatus?.overall)}
                            />
                            <IconButton
                                color="secondary"
                                aria-label="open drawer"
                                edge="start"
                                onClick={handleDrawerToggle}
                                sx={{ padding: 0 }}
                            >
                                <Menu
                                    sx={{
                                        fontSize: "42px",
                                    }}
                                />
                            </IconButton>
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                anchor="right"
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: "block", lg: "none" },
                    "& .MuiDrawer-paper": {
                        boxSizing: "border-box",
                        width: drawerWidth,
                        background:
              "linear-gradient(180deg, #2E0035 0%, #160A1D 50%, #2A4C4B 100%)",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "start",
                    },
                }}
            >
                {drawer}
            </Drawer>
        </Box>
    );
}

function NavigationLinks(props) {
    const { classes, isDark, serverStatus, checked, handleChange, navigate } =
    props;

    return (
        <>
            <DeepAnalyticsButton
                deepanalytics={classes.deepanalytics}
                navigate={navigate}
            />
            <ExplorerStatus
                isDark={isDark}
                serverStatus={checkStatus(serverStatus?.overall)}
            />
            {/* <FormControlLabel
                control={
                    <Switch
                        checked={checked}
                        onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                        style={{ alignItems: "center" }}
                    />
                }
                label={checked ? "Mainnet" : "Testnet"}
            /> */}
            <DocumentationLink />
        </>
    );
}

function MobileNavigationLinks(props) {
    const { classes, isDark, serverStatus, checked, handleChange, navigate } =
    props;

    return (
        <List>
            <ListItem>
                <ListItemButton
                    onClick={() => navigate("/charts")}
                    sx={{ color: "white" }}
                >
                    <ListItemIcon>
                        <StackedLineChartIcon sx={{ color: "white" }} />
                    </ListItemIcon>
                    <ListItemText sx={{ color: "white" }}>Deep Analytics</ListItemText>
                </ListItemButton>
            </ListItem>
            <Divider />
            <ListItem>
                <ListItemButton
                    onClick={() =>
                        checked
                            ? handleChange({ target: { checked: false } })
                            : handleChange({ target: { checked: true } })
                    }
                >
                    <ListItemIcon>
                        <Circle sx={{ color: checked ? "#f66fc0" : "#294c4a" }} />
                    </ListItemIcon>
                    <ListItemText sx={{ color: "white" }}>
                        {checked ? "Mainnet" : "Testnet"}
                    </ListItemText>
                </ListItemButton>
            </ListItem>
            <ListItem>
                <ListItemButton
                    onClick={() =>
                        window.open(
                            "https://docs.glitterfinance.org/glitter-bridge-explorer/glitter-bridge-explorer"
                        )
                    }
                >
                    <ListItemIcon sx={{ color: "white" }}>
                        <OpenInNew></OpenInNew>
                    </ListItemIcon>
                    <ListItemText sx={{ color: "white" }}>Docs</ListItemText>
                </ListItemButton>
            </ListItem>
        </List>
    );
}
function DocumentationLink() {
    return (
        <Button
            variant="text"
            href="https://docs.glitterfinance.org/glitter-bridge-explorer/glitter-bridge-explorer"
            target="_blank"
            rel="noopener"
            sx={{
                fontSize: ".875rem",
                color: "white",
                fontWeight: "normal",
                textDecoration: "none",
                display: "flex",
                alignItems: "center",
                gap: "4px",
                "&:hover": {
                    color: "#76D0D5",
                },
            }}
        >
      docs <OpenInNew sx={{ fontSize: ".875rem" }} />
        </Button>
    );
}

function Logo(props) {
    return (
        <Link
            to="/"
            className={props.classes.logoDiv}
            style={{
                textDecoration: "none",
                justifyContent: "space-between",
                marginRight: 24,
            }}
        >
            <img
                src={logoWithCyril}
                className={props.classes.logoImg}
                alt="Glitter finance"
            />
        </Link>
    );
}

function NetworkSwitch(props) {
    return (
        <FormControlLabel
            control={
                <Switch
                    checked={props.checked}
                    onChange={props.handleChange}
                    inputProps={{
                        "aria-label": "controlled",
                    }}
                    style={{
                        alignItems: "center",
                    }}
                />
            }
            label={props.checked == true ? "Mainnet" : "Testnet"}
        />
    );
}

function DeepAnalyticsButton(props) {
    return (
        <Typography
            variant="h7"
            className={props.deepanalytics}
            style={{
                color: "#76D0D5",
                cursor: "pointer",
            }}
            onClick={() => props.navigate("/charts")}
        >
      Deep Analytics
        </Typography>
    );
}

function ExplorerStatus({ isDark, serverStatus }) {
    const { classes } = useStyles();
    const { checkColor } = Others({ isDark });

    return (
        <Typography
            variant="h7"
            className={classes.status}
            style={{
                color: isDark ? "#ffffff" : "black",
                fontWeight: "700",
                fontSize: 13,
                display: "flex",
                gap: "2px",
            }}
        >
      Health:
            <Link to="/status">
                <Tooltip
                    title={
                        `${checkColor(serverStatus)?.msgHeader} . Click to see details` ??
            "--"
                    }
                    placement="top"
                >
                    <Typography
                        component="div"
                        className={classes.statusIcon}
                        style={{
                            backgroundColor: `${checkColor(serverStatus)?.color}`,
                        }}
                    />
                </Tooltip>
            </Link>
        </Typography>
    );
}

function checkStatus(service) {
    let status = EXPLORER_STATUS[0];

    if (service?.available === true) status = EXPLORER_STATUS[0];
    if (service?.degraded === true) status = EXPLORER_STATUS[1];
    if (service?.maintenance === true) status = EXPLORER_STATUS[3];
    if (service?.available === false) status = EXPLORER_STATUS[2];
    return status;
}

export { Header };
