import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { NotificationManager } from "react-notifications";

const STATUS = [
    "operational",
    "degraded",
    "downtime",
    "maintenance",
    "explorerDowntime",
];
const STANDARD_TIME = 1 * 1 * 60 * 1000;

const slice = createSlice({
    name: "serverStatus",
    initialState: {
        loading: false,
        serverStatus: {
            explorerServer: {
                available: true,
                maintenance: false,
                degraded: false,
                lastPinged: "",
                secondsSincePing: 0,
            },
            overall: {
                available: true,
                maintenance: false,
                degraded: false,
                lastPinged: "",
                secondsSincePing: 0,
            },
            usdcServer: {
                available: true,
                maintenance: false,
                degraded: false,
                lastPinged: "",
                secondsSincePing: 0,
            },
            algorand: {
                available: true,
                maintenance: false,
                degraded: false,
                lastPinged: "",
                secondsSincePing: 0,
                lastPing: 0,
            },
            solana: {
                available: true,
                maintenance: false,
                degraded: false,
                lastPinged: "",
                secondsSincePing: 0,
            },
        },
        error: "",
    },
    reducers: {
        getServerStatus: (state) => {
            state.loading = true;
        },
        getServerStatusSuccess: (state, { payload }) => {
            state.loading = false;

            state.serverStatus = {
                ...state.serverStatus,
                explorerServer: {
                    available: true,
                    maintenance: false,
                    degraded: false,
                    lastPinged: "",
                    secondsSincePing: 0,
                },
            };
            if (payload?.Overall) {
                const {
                    available,
                    maintenance,
                    degraded,
                    lastPinged,
                    secondsSincePing,
                // eslint-disable-next-line no-unsafe-optional-chaining
                } = payload?.Overall;
                state.serverStatus = {
                    ...state.serverStatus,
                    overall: {
                        available,
                        maintenance,
                        degraded,
                        lastPinged,
                        secondsSincePing,
                    },
                };
            }
            if (payload?.USDC) {
                const {
                    available,
                    maintenance,
                    degraded,
                    lastPinged,
                    secondsSincePing,
                // eslint-disable-next-line no-unsafe-optional-chaining
                } = payload?.USDC;
                state.serverStatus = {
                    ...state.serverStatus,
                    usdcServer: {
                        available,
                        maintenance,
                        degraded,
                        lastPinged,
                        secondsSincePing,
                    },
                };
            }
            if (payload?.Algorand) {
                const {
                    available,
                    maintenance,
                    degraded,
                    lastPinged,
                    secondsSincePing,
                // eslint-disable-next-line no-unsafe-optional-chaining
                } = payload?.Algorand;
                state.serverStatus = {
                    ...state.serverStatus,
                    algorand: {
                        available,
                        maintenance,
                        degraded,
                        lastPinged,
                        secondsSincePing,
                    },
                };
            }
            if (payload?.Solana) {
                const {
                    available,
                    maintenance,
                    degraded,
                    lastPinged,
                    secondsSincePing,
                // eslint-disable-next-line no-unsafe-optional-chaining
                } = payload?.Solana;
                state.serverStatus = {
                    ...state.serverStatus,
                    solana: {
                        available,
                        maintenance,
                        degraded,
                        lastPinged,
                        secondsSincePing,
                    },
                };
            }
        },
        getServerStatusFailed: (state, { payload }) => {
            state.loading = false;

            state.serverStatus = {
                ...state.serverStatus,
                explorerServer: {
                    available: false,
                    maintenance: false,
                    degraded: false,
                    lastPinged: "",
                    secondsSincePing: 0,
                },
                overall: {
                    available: false,
                    maintenance: false,
                    degraded: false,
                    lastPinged: "",
                    secondsSincePing: 0,
                },
                usdcServer: {
                    available: false,
                    maintenance: false,
                    degraded: false,
                    lastPinged: "",
                    secondsSincePing: 0,
                },
                algorand: {
                    available: false,
                    maintenance: false,
                    degraded: false,
                    lastPinged: "",
                    secondsSincePing: 0,
                },
                solana: {
                    available: false,
                    maintenance: false,
                    degraded: false,
                    lastPinged: "",
                    secondsSincePing: 0,
                },
            };
        },
        getAlgorandLastBlock: (state, { payload }) => {},
        getAlgorandLastBlockSuccess: (state, { payload }) => {},
        getAlgorandLastBlockFailed: (state, { payload }) => {},
    },
});

export const {
    getServerStatus,
    getServerStatusSuccess,
    getServerStatusFailed,
    getAlgorandLastBlock,
    getAlgorandLastBlockSuccess,
    getAlgorandLastBlockFailed,
} = slice.actions;

export default slice.reducer;
