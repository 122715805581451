import { create } from "apisauce";
import qs from "query-string";
import { NotificationManager } from "react-notifications";
import config from "../config.json";

const request = async (method, path, data) => {
    console.log('getting explorernetwork')
    const network = localStorage.getItem('explorernetwork')
    console.log('got explorernetwork', network)

    const baseURLCheck = config["APIs"]["bridgeExplorerUrl"];
    const mainNetUrl = "https://api-v2.glitterfinance.org/api/";
    const testnetUrl = "https://api-testnet.glitterfinance.org/api/";
    const baseURL = network == "mainnet"?mainNetUrl:testnetUrl;

    const client = create({
        baseURL,
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
    });
    let response = undefined;
    try {
        if (
            method === "GET" &&
      (typeof data === "object" || typeof data === "string")
        ) {
            path += "?" + qs.stringify(data);
        }
        if (method !== "GET") {
            response = await client[method.toLowerCase()](path, data);
        } else {
            response = await client[method.toLowerCase()](path);
        }
    } catch (error) {
        response = error.response;
    }
    if (response.problem === "TIMEOUT_ERROR") {
        NotificationManager.error("TIMEOUT_ERROR: Check your network connection");
        const resp = JSON.stringify(response);
        throw Object(JSON.parse(resp));
    }
    if (response.problem === "NETWORK_ERROR") {
    // NotificationManager.error("Check your network connection");
        const resp = JSON.stringify(response);
        throw Object(JSON.parse(resp));
    }
    if (response.status === 400) {
        const resp = JSON.stringify(response.data);
        throw Object(JSON.parse(resp));
    }
    if (response.status >= 400) {
        if (response.status === 401) {
            const resp = JSON.stringify(response.data);
            throw Object(JSON.parse(resp));
        }
        if (response.status === 422) {
            const resp = JSON.stringify(response.data);
            throw Object(JSON.parse(resp));
        }
        if (response.status === 404) {
            const resp = JSON.stringify(response.data);
            throw Object(JSON.parse(resp));
        }
        if (response.status === 403) {
            const resp = JSON.stringify(response.data);
            throw Object(JSON.parse(resp));
        }
        if (response.status === 500) {
            if (response.data?.message) {
                // NotificationManager.error(response.data?.message?.slice(0, 21));
                throw Object(JSON.parse(response));
            }
            // internal server error
            // NotificationManager.error("Internal Server Error");
            const resp = JSON.stringify(response.data);
            throw Object(JSON.parse(resp));
            // if (process.env.REACT_APP_ENVIRONMENT !== "production") {
            // } else {
            //   throw Object(INTERNAL_SERVER_ERROR);
            // }
        }
        if (response.status === 502) {
            // NotificationManager.error("Internal Server Error");
            const resp = JSON.stringify(response.data);
            throw Object(JSON.parse(resp));
        }
        const resp = JSON.stringify(response.data);
        throw Object(`Unhandled Error: ${JSON.parse(resp)}`);
    }
    if (response.problem) {
        const resp = JSON.stringify(response.problem);
        throw Object(JSON.parse(resp));
    }
    return response.data;
};

export default request;
