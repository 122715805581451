import React from 'react';
import { Box, Typography, useTheme, CardMedia, Tooltip } from '@mui/material';
import { CardTemplate, useCardStyles } from "./common";
import { getChainIcon, convertUTCtoLocal } from "../../utils/apiutils";
import { format as formatTimeAgo } from "timeago.js";
import { Link } from 'react-router-dom';
import Others from "../../reuseable/Others/Others";
import SingletonModule from "../../SingletonModule";

export default function TransactionCard({ 
    isDark = true,
    cardLabel,
    txnNetwork, 
    txnDate,
    txnBlock,
    txnID, 
    txnAddress,
    txnToken }) {

    //Local Constants
    const theme = useTheme();
    const classes = useCardStyles(theme);
    const { CopyToClipboard } = Others({
        isDark,
    });
    const { getExplorerAddressUrl, getExplorerTxnUrl } = Others({ isDark });

    return (

        <CardTemplate cardLabel={cardLabel} >

            <Box sx={{ display: 'flex', flexDirection: 'column', padding: "8px", width:"100%" }}>

                {/* Txn panel */}
                {txnNetwork && (
                    <Box sx={{ display: 'flex', flexDirection: 'row', width:"100%" }}>

                        {/* Txn Image */}
                        <Tooltip title={SingletonModule.getOfficialChainName(txnNetwork)} >
                            <div>
                                <CardMedia 
                                    component="img"
                                    sx={{ width: 48, height: 48, padding: "8px" }}
                                    image={getChainIcon(txnNetwork)}
                                    alt="Live from space album cover"
                                />
                            </div>
                        </Tooltip>
              
                        {/* Txn Image Side Text */}
                        <Box sx={{ display: 'flex', flexDirection: 'column', padding:"4px" }}>

                            {/* Txn Network */}
                            <Typography paddingBottom="4px" className={classes.cardLabel}>
                                {SingletonModule.getOfficialChainName(txnNetwork)}
                            </Typography>

                            {/* Txn Time */}
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                <Typography className={classes.cardText}>  
                                    {convertUTCtoLocal(txnDate)}
                                </Typography>    
                                <Typography className={classes.cardTextDull}>
                                    {'\u00A0'}[{formatTimeAgo(txnDate)}]
                                </Typography>
                            </Box>             
                        </Box>                  

                    </Box>
                )}

                {/* Txn Details Block */}                       
                {txnToken && (<Box className = {classes.textBoxRow} >                                                                   
                    <Typography className={classes.cardText} >Token:</Typography>
                    <Typography className={classes.cardTextBright}>  
                        {'\u00A0'}{txnToken}
                    </Typography>                                
                </Box>    
                )}

                {/* Txn Hash */}
                {txnID && (<Box className = {classes.textBoxRow} >                     
                    <Typography className={classes.cardText}>Txn: </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', wordBreak: 'break-all' }}>
                        <Link
                            className={classes.cardLink}
                            to={getExplorerTxnUrl(txnNetwork, txnID)}
                            target="_blank"
                            style={{ paddingLeft: '4px' }}
                        >
                            {txnID ?? ""}
                        </Link>
                        <CopyToClipboard text={txnID} tooltipText="Copy Txn Hash" />
                    </Box>
                </Box>
                )}

                {/* Address Hash */}
                {txnAddress && (<Box className = {classes.textBoxRow} >                     
                    <Typography className={classes.cardText}>Address: </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', wordBreak: 'break-all' }}>
                        <Link
                            className={classes.cardLink}
                            to={getExplorerAddressUrl(txnNetwork, txnAddress)}
                            target="_blank"
                            style={{ paddingLeft: '4px' }}
                        >
                            {txnAddress ?? ""}
                        </Link>
                        <CopyToClipboard text={txnAddress} tooltipText="Copy Address" />
                    </Box>
                </Box>
                )}

                {/* Gas Amount*/}
                {txnBlock && (<Box className = {classes.textBoxRow} >                                                                 
                    <Typography className={classes.cardText} >Block:</Typography>
                    <Typography className={classes.cardTextBright}>  
                        {'\u00A0'}{txnBlock}
                    </Typography>                                
                </Box>       
                )}          
               
            </Box>

        </CardTemplate>
    );
}
