import { takeLatest, call, put } from "redux-saga/effects";

import {
    getServerStatus,
    getServerStatusSuccess,
    getServerStatusFailed,
    getAlgorandLastBlock,
    getAlgorandLastBlockSuccess,
    getAlgorandLastBlockFailed,
} from "./reducers";
import config from "../../config.json";

import request from "../../utils/apisauce";
import { sleep } from "../../utils/apiutils";

function* getServerStatusSaga() {
    let action;

    // try 6 times using while loop to get the server status, if not server is down then call getServerStatusFailed

    let count = 0;

    while (count < config.settings.polling_retry) {
        try {
            const response = yield call(request, "GET", "health");
            action = getServerStatusSuccess(response);
            yield put(action);
            break;
        } catch (e) {
            count++;
            if (count === config.settings.polling_retry) {
                action = getServerStatusFailed(e);
                yield put(action);
            }
            yield call(sleep, config.settings.polling_interval2);
            continue;
        }
    }
}

function* getAlgorandLastBlockSaga() {
    let action;
    try {
        const response = yield call(
            request,
            "GET",
            "https://mainnet-api.algonode.cloud/v2/status"
        );
        action = getAlgorandLastBlockSuccess(response);
    } catch (e) {
        action = getAlgorandLastBlockFailed(e);
    }
    yield put(action);
}

export default function* saga() {
    yield takeLatest(getServerStatus, getServerStatusSaga);
    yield takeLatest(getAlgorandLastBlock, getAlgorandLastBlockSaga);
}
