import { takeLatest, call, put } from "redux-saga/effects";
import {
    getTxProfile,
    getTxProfileSuccess,
    getTxProfileFailed,
} from "./reducers";

import request from "../../utils/apisauce";

function* getTxProfileSaga({ payload }) {
    let action;
    const url = `txns/${payload?.txHash}`;
    // let url = `txns/id?txnID=${payload?.txHash}`;

    try {
        const response = yield call(request, "GET", url);
        action = getTxProfileSuccess(response);
    } catch (e) {
        action = getTxProfileFailed(e);
    }
    yield put(action);
}

export default function* saga() {
    yield takeLatest(getTxProfile, getTxProfileSaga);
}
