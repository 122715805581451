import React from "react";
import { Route, Routes } from "react-router-dom";
import { HomeLayout } from "./layout/Layout";

import Home from "./pages/Home/Home";
import AllTxs from "./pages/AllTxs/AllTxs";
import AddressProfile from "./pages/AddressProfile/AddressProfile";
import TxProfile from "./pages/TxProfile/TxProfile";
import NotFound from "./pages/NotFound/NotFound";
import ServerStatus from "./pages/ServerStatus/ServerStatus";
import AllCharts from "./pages/Charts/AllCharts";
import SingleChart from "./pages/Charts/SingleChart";

export default function Root() {
    return (
        <>
            <Routes>
                <Route
                    path="/"
                    element={
                        <HomeLayout>
                            <Home />
                        </HomeLayout>
                    }
                />
                <Route
                    path="/txs"
                    element={
                        <HomeLayout>
                            <AllTxs />
                        </HomeLayout>
                    }
                />
                <Route
                    path="/txs/:chain"
                    element={
                        <HomeLayout>
                            <AllTxs />
                        </HomeLayout>
                    }
                />
                <Route
                    path="/address/:id"
                    element={
                        <HomeLayout>
                            <AddressProfile />
                        </HomeLayout>
                    }
                />
                <Route
                    path="/tx/:id"
                    element={
                        <HomeLayout>
                            <TxProfile />
                        </HomeLayout>
                    }
                />
                <Route
                    path="/status"
                    element={
                        <HomeLayout>
                            <ServerStatus />
                        </HomeLayout>
                    }
                />
                <Route
                    path="/notfound"
                    element={
                        <HomeLayout>
                            <NotFound />
                        </HomeLayout>
                    }
                />
                <Route
                    path="*"
                    element={
                        <HomeLayout>
                            <NotFound />
                        </HomeLayout>
                    }
                />
                <Route
                    path="/charts"
                    element={
                        <HomeLayout>
                            <AllCharts />
                        </HomeLayout>
                    }
                />
                <Route
                    path="/charts/:name"
                    element={
                        <HomeLayout>
                            <SingleChart />
                        </HomeLayout>
                    }
                />
            </Routes>
        </>
    );
}
