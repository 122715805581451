import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { CardTemplate, useCardStyles } from "./common";

import { formatUSD, } from "../../utils/apiutils";

export default function StatusCard({ tx }) {
    const theme = useTheme();
    const classes = useCardStyles(theme);

    return (
        <CardTemplate cardLabel="Amount:">

            <Box sx={{ display: 'flex', flexDirection: 'column', padding: "8px" }}>                            

                {/* Deposit Amount*/}
                <Box className = {classes.textBoxRow} >                                                   
                    <Typography className={classes.cardText} >Deposited:</Typography>
                    <Typography className={classes.cardTextBright}>  
                        {'\u00A0'}{tx.initAmount} {tx.initToken}
                    </Typography> 
                    <Typography className={classes.cardTextDull} >
                        {'\u00A0'}[{formatUSD(tx.initAmountUSD)}]
                    </Typography>                                   
                </Box>      

                {/* Fee Amount*/}
                <Box className = {classes.textBoxRow} >                                                                
                    <Typography className={classes.cardText} >Bridge Fee:</Typography>
                    <Typography className={classes.cardTextBright}>  
                        {'\u00A0'}{-tx.feeAmount} {tx.completedToken}
                    </Typography> 
                    <Typography className={classes.cardTextDull} >
                        {'\u00A0'}[{formatUSD(-tx.feeAmountUSD)}]
                    </Typography>                                   
                </Box>    

                {/* Gas Amount*/}
                <Box className = {classes.textBoxRow} >                                                                      
                    <Typography className={classes.cardText} >Gas Deducted:</Typography>
                    <Typography className={classes.cardTextBright}>  
                        {'\u00A0'}{tx.gasAmountDeducted} {tx.completedToken}
                    </Typography> 
                    <Typography className={classes.cardTextDull} >
                        {'\u00A0'}[{formatUSD(tx.gasAmountDeductedUSD)}]
                    </Typography>                                   
                </Box>    

                {/* Received*/}
                <Box className = {classes.textBoxRow} >                                                                      
                    <Typography className={classes.cardText} >Received:</Typography>
                    <Typography className={classes.cardTextBright}>  
                        {'\u00A0'}{tx.completedAmount} {tx.completedToken}
                    </Typography> 
                    <Typography className={classes.cardTextDull} >
                        {'\u00A0'}[{formatUSD(tx.completedAmountUSD)}]
                    </Typography>                                   
                </Box>    

            </Box>

        </CardTemplate>
    );
}
