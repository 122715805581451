import { TableBody, TableCell, TableRow, Skeleton } from "@mui/material";
import React from 'react';

import Others from "./Others/Others";

const TbleSkeleton = ({ rows, cols, tableCell }) => {
    return (
        <TableBody>
            {Array(rows)
                .fill(0)
                .map((_, index) => (
                    <TableRow key={index}>
                        {Array(cols)
                            .fill(0)
                            .map((_, index) => (
                                <TableCell
                                    key={index}
                                    className={tableCell}
                                    align="center"
                                    component="th"
                                    scope="row"
                                >
                                    <Skeleton
                                        animation="wave"
                                        width="100%"
                                        height={50}
                                        sx={{
                                            background:
                        "linear-gradient(10deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%)",
                                        }}
                                    />
                                </TableCell>
                            ))}
                    </TableRow>
                ))}
        </TableBody>
    );
};

const StandardSkeleton = ({ h, w, type }) => {
    return (
        <Skeleton
            variant={type ? type : "rounded"}
            height={h ? h : 20}
            width={w ? w : "100%"}
            sx={{
                background:
          "linear-gradient(10deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%)",
            }}
        />
    );
};

const TblNoData = ({ isDark, msg, clr, w }) => {
    const { AlertBar } = Others({ isDark });
    return (
        <>
            <TableBody
                style={{
                    position: "absolute",
                    width: w ? w : "100%",
                }}
            >
                <AlertBar message={msg} isDark={isDark} clr={clr} />
            </TableBody>
        </>
    );
};

export { TbleSkeleton, TblNoData, StandardSkeleton };
