import {
    Badge,
    Grid,
    IconButton,
    TextField,
    Tooltip,
    useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import HelpIcon from "../../assets/images/Help.svg";

import SearchbarIcon from "../../assets/images/Searchbar Icon.svg";

const useStyles = makeStyles()((theme, { isDark }) => ({
    searchBarRoot: {
        background: "transparent",
        color: isDark ? "rgb(255 255 255 / 61%)" : "#000000",
        height: "40px !important",
        border: isDark
            ? "1px solid rgb(118 208 213 / 50%) !important"
            : "1px solid #000000",
        borderRadius: "10px",
        "& .MuiOutlinedInput-root": {
            width: "100%",
            paddingRight: "4px !important",
            paddingBottom: "2px !important",
            height: "40px !important",
            [theme.breakpoints.down("sm")]: {
                minWidth: "200px",
                width: "100%",
            },
        },
        "& .MuiOutlinedInput-input": {
            color: isDark ? "rgb(255 255 255 / 61%)" : "#000000",
        },
        "& .MuiInputLabel-root": {
            color: isDark ? "rgb(255 255 255 / 61%)" : "#000000",
        },
        // "& .MuiOutlinedInput-root fieldset": {
        //     borderColor: isDark ? "rgb(255 255 255 / 61%)" : "#000000",
        // },

        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
            color: isDark ? "rgb(255 255 255 / 61%)" : "#000000",
        },
        "& .MuiInputLabel-root.Mui-focused": {
            color: isDark ? "rgb(255 255 255 / 61%)" : "#000000",
        },
        "& .MuiOutlinedInput-root fieldset": {
            borderColor: "transparent",
        },
        "&:hover fieldset": {
            border: "none",
        },
        "& .MuiOutlinedInput-root.Mui-focused fieldset": {
            borderColor: "transparent",
            borderRadius: "10px",
        },
        [theme.breakpoints.down("sm")]: {
            minWidth: "200px",
            width: "100%",
        },
    },
    fieldset: {},
    helpicon: {
        height: 15,
        // height: 15,
        position: "absolute",
        top: "-2px",
        left: "4px",
    },
}));

export default function SearchBar({ isDark = true }) {
    const { classes } = useStyles({ isDark });
    const navigate = useNavigate();
    const [val, setVal] = useState("");
    const smallPoint = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    const chains = ["algorand", "solana", "ethereum", "avalanche", "polygon", "tron"];

    const handleSearch = () => {
    // if the search is valid tx hash then load tx page
        if (val.length === 52 || val.length === 88 || val.length === 66) {
            navigate(`/tx/${val}`);
        } else if (
            val.length === 42 ||
      val.length === 58 ||
      (val.length >= 32 && val.length <= 44) ||
      val.length === 103
        ) {
            // if val contains comma then seperate it by _
            let _val = val;
            if (val.includes(",")) {
                _val = val.replace(/,/g, "_");
            }

            navigate(`/address/${_val}`);
        } else {
            const searchTerm = val?.trim().toLowerCase()
            if(searchTerm.length > 0 && chains.some(chain => searchTerm.includes(chain))){
                console.log('filter by chain', searchTerm)
                navigate(`/txs/${searchTerm}`);
                window.location.reload()
            }else{
                navigate(`/notfound/${val}`);
            }
        }
    };

    const insturctions = `Please note how to search: 
  i - Search by Single Tx Hash or Single Address.
   ii - Search by comma seperated Address Hash of different chains i.e 0x12345,0x463448`;

    return (
        <Badge
            badgeContent={
                !smallPoint && (
                    <Tooltip title={insturctions} placement="top">
                        <img src={HelpIcon} alt="help icon" className={classes.helpicon} />
                    </Tooltip>
                )
            }
            color="primary"
            sx={{
                width: "100%",
                color: "white",
                cursor: "pointer",
                "& .MuiBadge-badge": {
                    background: "transparent",
                },
            }}
        >
            <TextField
                fullWidth
                autoComplete="off"
                placeholder="Search by Keyword, TXs, address"
                classes={{
                    root: classes.searchBarRoot,
                }}
                value={val}
                onChange={(e) => setVal(e.target.value)}
                onKeyDown={(e) => {
                    if (e.keyCode == 13) {
                        handleSearch();
                    }
                }}
                id="csvFile"
                variant="outlined"
                InputProps={{
                    endAdornment: (
                        <IconButton onClick={handleSearch} edge="end">
                            <img src={SearchbarIcon} height="40" width="46" />
                        </IconButton>
                    ),
                }}
            />
        </Badge>
    );
}
