import React, { useEffect, useMemo, useState } from "react";
import {
    Typography,
    Paper,
    Badge,
    IconButton,
    Tooltip,
    TableContainer,
    TablePagination,
    createTheme,
    useTheme,
    TableSortLabel,
    Grid,
    CircularProgress,
    Box,
    useMediaQuery,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
import { FilterList, Refresh } from "@mui/icons-material";
import {
    ArrowRightAlt,
    KeyboardArrowLeft,
    KeyboardArrowRight,
} from "@mui/icons-material";

import useStyles from "./TxsTableStyles";
import { TbleSkeleton, TblNoData } from "../../../../reuseable/TblChunks";
import headCells from "./headCells";
import Others from "../../../../reuseable/Others/Others";
import {
    allWordsCapitalize,
    getChainIcon,
    truncateStr,
} from "../../../../utils/apiutils";
import { useNavigate } from "react-router-dom";
import { format } from "timeago.js";
import { detect } from "detect-browser";
import { TX_STATUS } from "../../../../utils/constants";
import { useParams } from "react-router-dom";
import TxnTableHeader from "../../../../components/Tables/Headers/txnTableHeader";
import TxnTableCardTemplate from "../../../../components/Tables/txnTableCardTemplate";

const TxsTable = ({
    isDark = true,
    txs,
    setTxs,
    totalCount,
    fetchAllTxs,
    txLoading,
    network,
    fromChain,
    refreshTxs,
    filter,
    setFilter,
    sortBy,
    setSortBy,
    page,
    setPage,
    chainFilterApplied = false
}) => {
    const theme = useTheme();
    const browser = detect();

    const smallPoint = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    const { classes, cx } = useStyles({
        isDark,
        txs,
        browserName: browser?.name ?? "",
    });
    const { getTxUrlByChain } = Others({ isDark });

    const [order, setOrder] = useState("desc");
    const [orderBy, setOrderBy] = useState("fromTimestamp");
    const [rowsPerPage] = useState(20);
    const [hoveredIndex, setHoveredIndex] = useState(-1);

    const navigate = useNavigate();

    function handleClick(x) {
        //if (x?.releaseID) {
        console.log("going to details page", JSON.stringify(x));
        navigate(`/tx/${x?.initID}`);
        //} else {
        //    setShowSnackbar(true);
        //}
    }

    const checkColor = (status) => {
        switch (status) {
            case TX_STATUS[0]:
                return theme.palette.success.main;
            case TX_STATUS[1]:
                return theme.palette.error.main;
            case TX_STATUS[2]:
                return theme.palette.warning.main;
            case TX_STATUS[3]:
                return "#76D0D5";
            default:
                return isDark ? "#fff" : "#000";
        }
    };
    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);

        const sortBy =
      property === "fromAmount" && isAsc
          ? "amount_desc"
          : property === "fromAmount" && !isAsc
              ? "amount_asc"
              : property === "fromTimestamp" && isAsc
                  ? "date_desc"
                  : "date_asc";

        setSortBy(sortBy);

        fetchAllTxs({
            filter,
            newPage: page,
            rowsPerPage,
            sort: sortBy,
        });
    };
    function getComparator(order, orderBy) {
        return order === "desc"
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }
    function stableSort(array, comparator) {
        const stabilizedThis = array?.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis?.map((el) => el[0]);
    }
    const filterToggle = async () => {
        let i = TX_STATUS.indexOf(filter);
        i = ++i % TX_STATUS.length;

        setFilter(TX_STATUS[i]);

        if (TX_STATUS[i] === "all") {
            await fetchAllTxs({
                filter: null,
                newPage: page,
                rowsPerPage,
                sort: sortBy,
            });
        } else {
            await fetchAllTxs({
                filter: TX_STATUS[i],
                newPage: page,
                rowsPerPage,
                sort: sortBy,
            });
            setTxs(txs.filter((tx) => tx.status === TX_STATUS[i]));
        }
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        fetchAllTxs({
            filter,
            newPage,
            rowsPerPage,
            sort: sortBy,
        });
    };

    const IconButtonComp = ({ txHash, src, ...props }) => (
        <IconButton
            {...props}
            href={txHash}
            target="_blank"
            style={{
                padding: "5px",
            }}
        >
            {src === "--" ? (
                "--"
            ) : (
                <img src={src} alt="--" className={classes.buttonIcon} />
            )}
        </IconButton>
    );
    function TablePaginationActions(props) {
        const { count, page, rowsPerPage, onPageChange } = props;

        const handleFirstPageButtonClick = (event) => {
            onPageChange(event, 0);
        };

        const handleBackButtonClick = (event) => {
            onPageChange(event, page - 1);
        };

        const handleNextButtonClick = (event) => {
            onPageChange(event, page + 1);
        };

        const handleLastPageButtonClick = (event) => {
            onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };

        return (
            <Box
                sx={{
                    flexShrink: 0,
                    ml: 2.5,
                    display: "flex",
                    justifyContent: "space-around",
                    width: smallPoint ? "90%" : "auto",
                }}
            >
                <IconButton
                    className={classes.pagingButton}
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label="first page"
                >
                    <p className={classes.tablePaginationExtremes}>First</p>
                </IconButton>
                <IconButton
                    className={classes.pagingButton}
                    onClick={handleBackButtonClick}
                    disabled={page === 0}
                    aria-label="previous page"
                >
                    <KeyboardArrowLeft className={classes.forwardBackIcon} />
                </IconButton>
                <IconButton
                    className={classes.pagingButton}
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    <KeyboardArrowRight className={classes.forwardBackIcon} />
                </IconButton>
                <IconButton
                    className={classes.pagingButton}
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="last page"
                >
                    <p className={classes.tablePaginationExtremes}>Last</p>
                </IconButton>
            </Box>
        );
    }

    console.log("txs", txs)

    return (
        <Paper
            className={classes.paper}
            style={{ backgroundColor: "transparent" }}
            elevation={0}
        >
            <TxnTableCardTemplate 
                theme={theme}
                loading={txLoading}
                isDark={isDark}
                txs={txs}
                classes={classes}
                cx={cx}
                onRequestSort={handleRequestSort}
                order={order}
                orderBy={orderBy}
                filterToggle={filterToggle}
                filter={filter}
                hoveredIndex={hoveredIndex}
                setHoveredIndex={setHoveredIndex}
                handleClick={handleClick}
                rowsPerPage={rowsPerPage}
                stableSort={stableSort}
                getComparator={getComparator}
                txLoading={txLoading}
                chainFilterApplied={chainFilterApplied}
                totalCount={totalCount}
                page={page}
                handleChangePage={handleChangePage}
                TablePaginationActions={TablePaginationActions}
                refreshTxs={refreshTxs}
                smallPoint={smallPoint}
            >

            </TxnTableCardTemplate>
           
        </Paper>
    );
};
export default React.memo(TxsTable);
