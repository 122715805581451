import { takeLatest, call, put } from "redux-saga/effects";
import {
    getTxs,
    getTxsSuccess,
    getTxsFailed,
    getXgliInfo,
    getXgliInfoSuccess,
    getXgliInfoFailed,
    getTxsHistory,
    getTxsHistorySuccess,
    getTxsHistoryFailed,
    getHistoryDataSuccess,
    getHistoryDataFailed,
} from "./reducers";

import request from "../../utils/apisauce";

function* getTxsSaga({ payload }) {
    let action;
    try {
        const response = yield call(request, "GET", "txns/search?sort=date_desc");
        action = getTxsSuccess(response);
    } catch (e) {
        action = getTxsFailed(e);
    }
    yield put(action);
}

function* getHistoryDataTxns({ payload }) {
    let action;
    try {
        const response = yield call(request, "GET", "addresses/history");
        action = getHistoryDataSuccess(response);
    } catch (e) {
        action = getHistoryDataFailed(e);
    }
    yield put(action);
}

function* getXgliInfoSaga({ payload }) {
    let action;
    try {
        const response = yield call(request, "GET", "tokens/glitter/xgli");
        action = getXgliInfoSuccess(response);
    } catch (e) {
        action = getXgliInfoFailed(e);
    }
    yield put(action);
}
function* getTxsHistorySaga({ payload }) {
    let action;
    const { newPage = 0, rowsPerPage = 30 } = payload;
    try {
        const response = yield call(
            request,
            "GET",
            `txns/history?skip=${newPage}&take=${rowsPerPage}`
        );
        action = getTxsHistorySuccess(response);
    } catch (e) {
        action = getTxsHistoryFailed(e);
    }
    yield put(action);
}

export default function* saga() {
    yield takeLatest(getTxs, getTxsSaga);
    yield takeLatest(getXgliInfo, getXgliInfoSaga);
    yield takeLatest(getTxsHistory, getTxsHistorySaga);
    yield takeLatest(getTxs, getHistoryDataTxns);
}
