import { HelpOutline } from "@mui/icons-material";
import { Box, Typography, useMediaQuery } from "@mui/material";
import Slider from "@mui/material/Slider";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Line } from "react-chartjs-2";

const strMappings = ["USDC", "SOL", "ALGO", "EUROC", "NOSEC"];

const MultiAxisLineChart = ({ data, type }) => {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    const defaultValue = useMemo(() => findFirstAndLastDates(data), [data]);
    const [value, setValue] = useState([
        defaultValue.lastDate,
        defaultValue.firstDate,
    ]);

    const filtered = useCallback(
        (dataset) => {
            return value[0] !== null && value[1] !== null
                ? dataset.filter((item) => {
                    const date = new Date(item.date);
                    const needle = date.getTime();
                    return value[0] <= needle && needle <= value[1];
                })
                : dataset;
        },
        [value]
    );

    const datasets = useMemo(() => {
        return data
            .map((dataset, index) => {
                if (!dataset) return null;

                const colors = [
                    "#FF6384", // Light red / pink
                    "#36A2EB", // Light blue
                    "#FFCE56", // Yellow
                    "#4BC0C0", // Turquoise
                    "#9966FF", // Light purple
                    "#2775ca", // Blue
                ];

                return {
                    label: `${strMappings[index]}`,
                    data: dataset.map((item) => ({
                        x: item.date,
                        y: type === "TIME_AND_TXN" ? item?.txns : item?.volumeUSD,
                    })),
                    borderColor: colors[index] + "CC",
                    backgroundColor: colors[index] + "8C",
                    borderWidth: 1,
                    borderRadius: 1,
                    borderJoinStyle: "round",
                    pointStyle: false,
                    fill: true,
                };
            })
            .filter(Boolean);
    }, [data, filtered, type]);

    const chartData = {
        datasets,
    };

    const options = {
        responsive: true,
        aspectRatio: isMobile ? 1 : 2,
        maintainAspectRatio: true,
        plugins: {
            tooltip: {
                mode: "index",
            },
        },
        scales: {
            x: {
                type: "time",
                time: {
                    unit: "day",
                    displayFormats: {
                        day: "MMM d yyyy",
                        month: "MMM D",
                        year: "MMM YYYY",
                    },
                },
                ticks: {
                    reverse: true,
                },
                grid: {
                    color: "transparent",
                },
            },
            y: {
                type: "linear",
                position: "left",
                title: {
                    display: true,
                    text: type === "TIME_AND_TXN" ? "Transactions" : "Volume (USD)",
                },
                grid: {
                    drawOnChartArea: true,
                    color: "#FFFFFF0D",
                },
            },
        },
    };

    const handleChange = (_, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
    // Probably a better way is to not render chart if no data is present, or pass in proper array instead of nullish,
    // But, for now, we update the range from that.
        const values = findFirstAndLastDates(data);
        setValue([values.lastDate, values.firstDate]);
    }, [data]);

    return (
        <>
            <Line data={chartData} options={options} />
            {defaultValue.firstDate !== defaultValue.lastDate && (
                <Box
                    sx={{
                        px: "48px",
                        width: "100%",
                    }}
                >
                    <div>
                        <Typography
                            variant="caption"
                            color="#898989"
                            sx={{
                                marginTop: 2,
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                                py: 0,
                                lineHeight: "100%",
                            }}
                        >
                            <HelpOutline fontSize="19" />
                            <span>Drag to filter</span>
                        </Typography>
                    </div>
                    <Slider
                        value={value}
                        min={defaultValue.lastDate}
                        max={defaultValue.firstDate}
                        step={86400_000} // 1 day in milliseconds
                        onChange={handleChange}
                        disableSwap
                        sx={{
                            height: 48,
                            color: "#8989898C",
                            "& .MuiSlider-thumb": {
                                color: "#3f7371",
                                borderRadius: 1,
                                height: 52,
                            },
                        }}
                    />
                </Box>
            )}
        </>
    );
};

function findFirstAndLastDates(arr) {
    let firstDate = null;
    let lastDate = null;

    for (const subArr of arr) {
        if (subArr && subArr.length > 0) {
            // Update the firstDate if it is null or the date is earlier than the current firstDate
            if (!firstDate || new Date(subArr.at(-1).date) < new Date(firstDate)) {
                firstDate = subArr.at(-1).date;
            }

            // Update the lastDate if it is null or the date is later than the current lastDate
            if (!lastDate || new Date(subArr[0].date) > new Date(lastDate)) {
                lastDate = subArr[0].date;
            }
        }
    }

    console.log({
        firstDate: firstDate ? new Date(firstDate) : null,
        lastDate: lastDate ? new Date(lastDate) : null,
    });

    return {
        firstDate: firstDate ? new Date(firstDate).getTime() : null,
        lastDate: lastDate ? new Date(lastDate).getTime() : null,
    };
}

export default MultiAxisLineChart;
