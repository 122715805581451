import React from "react";
import { Box } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme, { mx = 0, my = 0, px = 0, py = 0 }) => {
  return {
    sleakBar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "left",
      textAlign: "left",
      minHeight: "45px",
      margin: `${my}px ${mx}px`,
      padding: `${py}px ${px}px`,
      borderRadius: "13px !important",
      borderColor: "transparent",
      background:
        "linear-gradient(10deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%)",
      boxShadow: "0px 1px 12px rgb(0 0 0 / 15%)",
      "& .MuiAlert-icon": {
        color: ({ isDark }) => (isDark ? "#FFFFFF" : "#000000"),
      },
      [theme.breakpoints.down("sm")]: {
        minHeight: "auto",
        padding: `${10}px ${10}px`,
      },
    },
  };
});

export default function SleakBar({ mx, my, px, py, children }) {
  const { classes } = useStyles({ mx, my, px, py });

  return <Box className={classes.sleakBar}>{children}</Box>;
}
