import React, { useEffect, useState } from "react";
import { Box, TableRow, IconButton, CircularProgress, TablePagination, Typography, useTheme, TableBody, Table, CardMedia, Tooltip, useMediaQuery, Card, TableContainer } from '@mui/material';
import { FilterList, Refresh } from "@mui/icons-material";
import useTxnTableStyles from "../common";
import { detect } from "detect-browser";

export default function TxnTableFooter({
    txs,
    txLoading,
    filter,
    chainFilterApplied,
    totalCount,
    rowsPerPage,
    page,
    handleChangePage,
    TablePaginationActions,
    isDark,
    refreshTxs,
    smallPoint,
    classes,
    cx
}
){

    if (!classes) return;

    return (
        <>
            {chainFilterApplied ? null : (
                <TablePagination
                    labelRowsPerPage=""
                    rowsPerPageOptions={[20]}
                    component="div"
                    count={totalCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    ActionsComponent={TablePaginationActions}
                    classes={{
                        root: cx(
                            isDark
                                ? classes.tablePaginationRootLight
                                : classes.tablePaginationRootDark,
                            classes.tablePaginationRoot
                        ),
                        toolbar: classes.tablePaginationToolbar,
                    }}
                    labelDisplayedRows={({ from, to, count }) => (
                        <>
                            <div className={classes.tablePaginationLabel}>
                                {!smallPoint && (
                                    <Typography
                                        component="h6"
                                        variant="p"
                                        textAlign="left"
                                        fontSize="14px"
                                        sx={{ position: "absolute", left: "0" }}
                                    >
                      Showing number of records:
                                        <Typography variant="span" className={classes.tileTitle}>
                                            {" "}
                                            {txs.length}
                                        </Typography>
                                    </Typography>
                                )}
                                <Tooltip title="Click to refresh the data">
                                    {!txLoading ? (
                                        <IconButton
                                            onClick={() =>
                                                refreshTxs({ filter, newPage: page, rowsPerPage })
                                            }
                                        >
                                            <Refresh
                                                style={{ color: isDark ? "#ffffff" : "#000000" }}
                                            />
                                        </IconButton>
                                    ) : (
                                        <Box>
                                            <CircularProgress className={classes.loaderIcon} />
                                        </Box>
                                    )}
                                </Tooltip>
                                <p
                                    style={{
                                        margin: 0,
                                        fontWeight: 600,
                                        color: isDark ? "#ffffff" : "#000000",
                                    }}
                                >
                                    {from}-{to}
                                </p>
                  &nbsp;
                                <span style={{ color: isDark ? "#ffffff" : "#000000" }}>
                    of {count !== -1 ? count : `more than ${to}`}
                                </span>
                                <br />
                            </div>
                        </>
                    )}
                />
            )}
        </>
    );
}
