import React, { useState } from "react";

import cicleToprightGroupedBothCircles from "../assets/images/toprightGroupedBothCircles.svg";
import circleOnTopLeft from "../assets/images/circleOnTopRight.svg";
import circleOnTopLeftLight from "../assets/images/circleOnTopLeftLight.svg";
// import PreferencesDrawer from "../components/PreferencesDrawer/PreferencesDrawer";
import { Header } from "../components/Header/Header";
import useStyles from "./LayoutStyles";
import { Grid, useMediaQuery } from "@mui/material";
import Footer from "../components/Footer/Footer";
import SearchBar from "../reuseable/SearchBar/SearchBar";

export const HomeLayout = ({ children }) => {
    const { classes, cx } = useStyles();
    const [isDark, setIsDark] = useState(true);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const mediumPoint = useMediaQuery((theme) => theme.breakpoints.down("md"));

    const url = window.location.pathname;

    return (
        <div className={classes.rootContainer}>
            {false && (
                <div className={classes.alertBanner}>
          Glitter is working hard to update this page. This page may not be
          fully responsive on 3/16/2023. Please check back on 3/17/2023 for full
          service!
                </div>
            )}
            <div
                className={cx(
                    isDark ? classes.rootDark : classes.rootLight,
                    classes.root
                )}
            >
                <Header
                    isDark={isDark}
                    isDrawerOpen={isDrawerOpen}
                    toggleDrawer={(isOpen) => setIsDrawerOpen(isOpen)}
                />

                <div className={classes.container}>
                    {/* <PreferencesDrawer
            isDark={isDark}
            network={network}
            setNetwork={setNetwork}
            setDarkMode={(isDarkMode) => {
              let bodyElem = document.body;
              if (bodyElem) {
                bodyElem.style.backgroundColor = !isDark
                  ? "var(--clr-dmc)"
                  : "var(--clr-lmclr)";
              }
              return setIsDark(isDarkMode);
            }}
            isDrawerOpen={isDrawerOpen}
            toggleDrawer={(isClosed) => setIsDrawerOpen(isClosed)}
            isAdvancedMode={isAdvancedMode}
            setAdvancedMode={(isAdvancedMode) =>
              setIsDrawerOpen(isAdvancedMode)
            }
          /> */}
                    <main
                        className={classes.main}
                        style={{
                            backgroundImage: `url(${
                                isDark ? circleOnTopLeft : circleOnTopLeftLight
                            }), url(${
                                isDark
                                    ? cicleToprightGroupedBothCircles
                                    : cicleToprightGroupedBothCircles
                            })`,
                        }}
                    >
                        {mediumPoint && (
                            <Grid
                                textAlign="left"
                                mb={mediumPoint ? 2 : ""}
                                mt={0.2}
                                container
                                spacing={1}
                                sx={{ width: "88%", margin: "0 auto" }}
                            >
                                <Grid item xs={12} sm={12} md={4} lg={4} className="flexCenter">
                                    <SearchBar isDark={true} />{" "}
                                </Grid>
                            </Grid>
                        )}
                        <Grid className={classes.tabMarginTop}>{children}</Grid>
                        <Footer isDark={isDark} />
                    </main>
                </div>
            </div>
        </div>
    );
};
