import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme, { isDark, _txs, browserName }) => {
    return {
        paper: {
            color: isDark ? "#fff" : "#000",
            width: "100%",
            margin: "auto",
            padding: "0px",
            marginTop: "3%",
            marginBottom: "8%",
            borderRadius: "17px",
            boxShadow: isDark ? "" : "0px 1px 12px rgb(0 0 0 / 15%)",
            [theme.breakpoints.down("md")]: {
                width: "100%",
            },
            [theme.breakpoints.down("sm")]: {
                width: "300px",
            },
        },
        tileTitle: {
            fontSize: "14px",
            fontStyle: "normal",
            fontFamily: "Barlow",
            fontWeight: 600,
            lineHeight: "16px",
            wordWrap: "break-word",
            [theme.breakpoints.down("sm")]: {
                fontSize: "13px",
                lineHeight: "10px",
            },
        },
        infoCell: {
            wordWrap: "break-word",
            borderBottom: `1px solid ${isDark ? "#76d0d582" : "#D8DADA"}`,
            padding: "10px 0px",
        },
        activeBtn: {
            fontFamily: "Montserrat",
            fontSize: "15px",
            lineHeight: "21px",
            color: "#76D0D5",
            fontWeight: "700",
            padding: "5px",
            border: "1px solid transparent",
            borderRadius: 25,
            // marginLeft: 10,
            [theme.breakpoints.down("sm")]: {
                marginLeft: 0,
                marginRight: "0 !important",
                fontSize: "16px",
            },
            [theme.breakpoints.down("xs")]: {
                fontSize: 10,
                padding: "6px",
                paddingRight: "14px",
                paddingLeft: "14px",
                width: "39px",
                height: "30px",
            },
        },
        priceButton: {
            background:
        "linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)",
            border: !isDark ? "1px solid #62686e" : "1px solid rgb(97 86 31 / 37%)",
            color: "rgba(255, 255, 255, 0.5)",
            fontSize: "10px",
            padding: "2px 13px",
            textTransform: "uppercase",
        },
        statusIcon: {
            fontSize: "16px",
        },
    };
});

export default styles;
