import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => {
    return {
        appbar: {
            backgroundColor: "transparent !important",
            padding: "20px 16px 0 ",
            maxWidth: "91.5%",
            width: "90%",
            margin: "0 auto",
            textAlign: "left",
            [theme.breakpoints.down("md")]: {
                padding: "30px 0",
            },
            [theme.breakpoints.down("sm")]: {
                padding: "25px 0 35px",
                maxWidth: "90%",
            },
        },
        toolbarGutters: {
            padding: "0",
        },

        menuBtn: {
            color: "#313149",
            padding: "6px",
            paddingRight: "12px",
            paddingLeft: "12px",
            fontSize: 16,
            fontWeight: "bold",
            border: "3px solid transparent",
            borderRadius: 50,
            marginLeft: 10,
            "&:hover": {
                backgroundImage: "linear-gradient(172deg, #f76fc1, #faf6f4) ",
                color: "#000000",
            },
            [theme.breakpoints.down("sm")]: {
                fontSize: 18,
                padding: "3px",
                paddingRight: "9px",
                paddingLeft: "9px",
            },
        },
        iconButtonRoot: {
            padding: 0,
        },
        btnMenu: {
            width: "auto",
            height: "45px",
            [theme.breakpoints.down("md")]: {
                width: "49px",
                height: "38px",
            },
            [theme.breakpoints.down("sm")]: {
                width: "38px",
                height: "30px",
            },
        },
        toolbarRoot: {
            padding: "0",
            minHeight: "58px",
            [theme.breakpoints.down("sm")]: {
                minHeight: "auto",
            },
        },
        contentContianer: {
            [theme.breakpoints.down("sm")]: {
                flexDirection: "row",
                justifyContent: "space-between !important",
            },
        },
        switchNetwork: {
            [theme.breakpoints.down("sm")]: {
                flexDirection: "row",
            },
        },
        logoDiv: {
            width: "auto",
        },
        logoImg: {
            height: "102px",
            width: "224px",
            verticalAlign: "middle",
            [theme.breakpoints.down("sm")]: {
                height: "60px",
                width: "133px",
            },
        },
        status: {
            display: "flex",
            alignItems: "center",
            //fontSize: 14,
            textAlign: "left",
            fontFamily: "ProximaNova",
            fontWeight: "400",
            fontStyle: "normal",
            fontSize: "17px",
            lineHeight: "23px",
            [theme.breakpoints.down("sm")]: {
                // marginRight: "10px",
                fontSize: "13px",
                lineHeight: "10px",
                position: "relative",
                // left: "20px",
            },
        },
        statusIcon: {
            height: "20px",
            width: "20px",
            borderRadius: 10,
            margin: "2px",
            transition: "all .5s ease",

            WebkitTransition: "all .5s ease",
            MozTransition: "all .5s ease",
            [theme.breakpoints.down("sm")]: {
                marginTop: "2px",
                height: "12px",
                width: "12px",
            },
        },
        deepanalytics: {
            height: "28px",
            fontFamily: "Montserrat",
            fontSize: "12px",
            borderRadius: 25,
            textAlign: "center",
            fontWeight: 500,
            background: "transparent",
            border: "1px solid #76D0D5",
            color: "#76D0D5",
            padding: "0px 8px",
            display: "flex",
            alignItems: "center",
            [theme.breakpoints.down("md")]: {
                fontSize: "16px",
            },
            [theme.breakpoints.down("sm")]: {
                fontSize: "12px",
                // height: "20px",
            },
            "&:hover": {
                background: "rgba(118, 208, 213, 0.1)",
                cursor: "pointer",
            },
        },
    };
});

export default useStyles;
