import React from 'react';
import { Box, Typography, useTheme, CardMedia, Tooltip } from '@mui/material';
import { checkTypeColor } from "../../utils/apiutils";
import glitterLogo from "../../assets/images/logo.png";
import { CardTemplate, useCardStyles } from "./common";

export default function ProtocolCard({ tx }) {
    const theme = useTheme();
    const classes = useCardStyles(theme);

    return (
        <CardTemplate cardLabel="Protocol:">

            {/* Protocol panel */}
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>

                {/* Protocol logo */}
                <Tooltip title={tx.protocol} >
                    <div>
                        <CardMedia
                            component="img"
                            sx={{ width: 64, height: 64, padding: "8px" }}
                            image={glitterLogo}
                            alt="Live from space album cover"
                        />
                    </div>
                </Tooltip>

                {/* Protocol Text */}
                <Box sx={{ display: 'flex', flexDirection: 'column', padding: "8px" }}>
                    <Typography paddingBottom="4px" className={classes.cardLabel}>
                        Bridge
                    </Typography>
                    <Typography color={checkTypeColor(tx.type)} minHeight="16px" fontSize="0.85em">
                        {tx.type}&nbsp;
                    </Typography>
                </Box>
            </Box>

        </CardTemplate>
    );
}
