import React from "react";
import {
    Paper,
    IconButton,
    Tooltip,
    TableContainer,
    useMediaQuery,
    Grid,
    Typography,
    Button,
    Snackbar,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import ArrowRightAlt from "@mui/icons-material/ArrowRightAlt";
import CloseIcon from "@mui/icons-material/Close";
import glitterLogo from "../../../assets/images/logo.png";
import { TbleSkeleton, TblNoData } from "../../../reuseable/TblChunks";

import Others from "../../../reuseable/Others/Others";
import { capitalize, getChainIcon, truncateStr } from "../../../utils/apiutils";
import { Link } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import SleakBar from "../../../reuseable/SleakBar/SleakBar";
import { format } from "timeago.js";
import { useNavigate } from "react-router-dom";

function LatestTxsTbl({
    isDark = true,
    txs,
    txLoading = false,
    network = "mainnet",
}) {
    const { classes, cx } = useStyles({ isDark });
    const { getTxUrlByChain } = Others({ isDark });

    const [showSnackBar, setShowSnackbar] = React.useState(false);

    const navigate = useNavigate();

    function handleClick(x) {
        if (x?.initID) {
            console.log("going to details page", JSON.stringify(x));
            navigate(`/tx/${x?.initID}`);
        } else {
            setShowSnackbar(true);
        }
    }

    const IconButtonComp = ({ txHash, src, ...props }) => (
        <IconButton
            {...props}
            href={txHash}
            target="_blank"
            style={{
                padding: "5px",
                color: "white",
            }}
        >
            {src === "--" ? (
                "--"
            ) : (
                <img src={src} alt="--" className={classes.buttonIcon} />
            )}
        </IconButton>
    );

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => setShowSnackbar(false)}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <Paper
            className={classes.paper}
            style={{ backgroundColor: "transparent" }}
            elevation={0}
        >
            <SleakBar my={7} px={20} py={2}>
                <Typography
                    textTransform="uppercase"
                    fontWeight="bold"
                    sx={{ width: "100%", color: "white" }}
                >
              Latest Transactions
                </Typography>
            </SleakBar>

            {/* Main Table Container */}
            <TableContainer className={classes.tableContainer}>
                <Table style={{ position: "relative" }} size="small">
                    {txLoading ? (
                        <TbleSkeleton rows={5} cols={4} tableCell={classes.tableCell} />
                    ) : txs?.length === 0 ? (
                        <Grid
                            sx={{ height: "100px" }}
                        >
                            <TblNoData isDark={isDark} msg={`No ${""} Data Available`} />
                        </Grid>
                    ) : (
                        <TableBody>
                          
                            {/* Table Rows */}
                            {txs.map((txn, index) => (
                                <TableRow key={txn.initID} onClick={() => handleClick(txn)} style={{ cursor:'pointer' }}>
                                    
                                    {/* Tnx ID */}
                                    <TableCell
                                        align="center"
                                        className={classes.tableCell}
                                        style={{ paddingLeft: "0", fontWeight: "bold", color: "white" }}
                                    >
                                        <Grid sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} >

                                            {/* Transaction Logo */}                                           
                                            <Tooltip title={txn.protocol} placement="top" >
                                                <img src={glitterLogo} height="30" width="30" />
                                                <Typography
                                                    component="div"
                                                    className={classes.statusIcon}
                                                />
                                            </Tooltip>

                                            {/* Transaction Label */}
                                            <Grid pl={1} textAlign="left">

                                                {/* Transaction ID */}
                                                <Typography component="h6" variant="p" className={classes.tileTitle} >
                                                    {truncateStr(txn.BridgeID || "", 20)}
                                                </Typography>

                                                {/* Transaction Time */}
                                                <Typography
                                                    component="h6"
                                                    variant="p"
                                                    className={classes.tokenName}
                                                >
                                                    {format(txn.initDate)}
                                                </Typography>

                                            </Grid>
                                        </Grid>
                                    </TableCell>                                   

                                    {/* Addresses */}
                                    <TableCell
                                        align="center"
                                        className={classes.tableCell}
                                        component="th"
                                        scope="row"
                                        style={{ minWidth: "130px" }}
                                    >
                                        <Grid
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                minWidth: "140px",
                                            }}
                                        >

                                            {/* From Address */}
                                            <Grid container>
                                                <Typography component="h6" variant="p" className={classes.tileSubtitle} minHeight="16px">  
                                                From:&nbsp;
                                                </Typography>                                                
                                                <Typography component="h6" variant="p" className={classes.tileTitle} >
                                                    {truncateStr(txn?.initAddress || "", 20)}
                                                </Typography>
                                            </Grid>

                                            {/* To Address */}
                                            <Grid container>
                                                <Typography component="h6" variant="p" fontSize={7} className={classes.toTx} >
                                                     To: &nbsp;                                                
                                                </Typography>
                                                <Typography
                                                    component="h6"
                                                    variant="p"
                                                    fontSize={7}
                                                    className={classes.toTx}
                                                    sx={{ color: "rgb(118, 208, 213) !important" }}
                                                >
                                                    {truncateStr(txn?.completedAddress || "", 20)}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </TableCell>

                                    {/* Amount */}
                                    <TableCell
                                        align="center"
                                        className={classes.tableCell}
                                        style={{
                                            color: isDark && "#ffffff",
                                        }}
                                    >
                                        <Grid sx={{ display: "flex", justifyContent: "center", alignItems: "center", }} >
                                            <Button
                                                size="small"
                                                // onClick={() => this.handleNext(activeStep)}
                                                disabled={true}
                                                cursor="unset"
                                                className={cx(classes.activeBtn, classes.priceButton)}
                                            >
                                                {parseFloat(txn?.initAmount ?? 0)?.toFixed(4) ?? "--"}
                                            </Button>
                                        </Grid>
                                    </TableCell>

                                    {/* Status */}
                                    <TableCell
                                        align="center"
                                        className={classes.tableCell}
                                        style={{
                                            color: isDark && "#ffffff",
                                            minWidth: "100px",
                                            display: "flex",
                                            paddingTop: "13px",
                                        }}
                                    >
                                        <Grid
                                            container
                                            className="flexCenter"
                                            sx={{ textTransform: "uppercase" }}
                                        >
                                            <Grid item cols={12 / 3} textAlign="right">
                                                <Typography
                                                    component="h6"
                                                    variant="p"
                                                    className={classes.tileSubtitle}
                                                    sx={{ fontWeight: "500", fontSize: "11px" }}
                                                >
                                                    {capitalize(txn.initToken || "")}
                                                </Typography>
                                            </Grid>
                                            <Grid item cols={12 / 3}>
                                                <ArrowRightAlt
                                                    style={{ height: "12px", color: "white" }}
                                                />
                                            </Grid>
                                            <Grid item cols={12 / 3} textAlign="left">
                                                <Typography
                                                    component="h6"
                                                    variant="p"
                                                    className={classes.tileSubtitle}
                                                    sx={{ fontWeight: "500", fontSize: "11px" }}
                                                >
                                                    {capitalize(txn.completedToken || "")}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </TableCell>

                                    {/* Txn IDs */}
                                    <TableCell
                                        className={classes.tableCell}
                                        style={{ paddingRight: 0 }}
                                        align="center"
                                    >
                                        <div
                                            style={{
                                                diplay: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                minWidth: "100px",
                                            }}
                                        >

                                            {/* Init Txn ID */}
                                            <Tooltip title={`See details on explorer`}>
                                                <span>
                                                    {txn?.initID ? (
                                                        <IconButtonComp
                                                            txHash={getTxUrlByChain(
                                                                txn?.initNetwork,
                                                                network,
                                                                txn?.initID
                                                            )}
                                                            src={getChainIcon(txn?.initNetwork)}
                                                        />
                                                    ) : (
                                                        "--"
                                                    )}
                                                </span>
                                            </Tooltip>

                                            <ArrowRightAlt style={{ height: "12px" }} />

                                            {/* Completed Txn ID */}
                                            <Tooltip title={`See details on explorer`}>
                                                <span>
                                                    {txn?.completedID ? (
                                                        <IconButtonComp
                                                            txHash={getTxUrlByChain(
                                                                txn?.completedNetwork,
                                                                network,
                                                                txn?.completedID
                                                            )}
                                                            src={getChainIcon(txn?.completedNetwork)}
                                                        />
                                                    ) : (
                                                        "--"
                                                    )}
                                                </span>
                                            </Tooltip>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    )}
                </Table>
            </TableContainer>
            <Link to="/txs" style={{ textDecoration: "none" }}>
                <Button
                    size="small"
                    className={cx(classes.activeBtn, classes.viewTxBtn)}
                >
          VIEW ALL TRANSACTIONS
                </Button>
            </Link>
            <Snackbar
                open={showSnackBar}
                autoHideDuration={6000}
                onClose={() => setShowSnackbar(false)}
                message="This transaction has incomplete details, please try later."
                action={action}
            />
        </Paper>
    );
}

export default LatestTxsTbl;

const useStyles = makeStyles()((theme, { isDark }) => ({
    paper: {
        width: "100%",
        margin: "auto",
        padding: "20px 0px",
        marginTop: "0%",
        marginBottom: "5%",
        borderRadius: "17px",
        boxShadow: isDark ? "" : "0px 1px 12px rgb(0 0 0 / 15%)",
        [theme.breakpoints.down("md")]: {
            width: "100%",
        },
        [theme.breakpoints.down("sm")]: {
            width: window.innerWidth * 0.85,
        },
    },
    tableContainer: {
        width: "100%",
        margin: "auto",
    },
    tableRoot: {
        postion: "relative",
    },
    tableCell: {
        fontSize: 13,
        color: isDark ? "#fff" : "#000",
        padding: "6px 0px",
        borderBottom: `none`,
        maxWidth: "320px",
        fontFamily: "Barlow",
        textTransform: "capitalize",
        minWidth: "160px",
    },
    buttonIcon: {
        height: "25px",
    },
    activeBtn: {
        fontFamily: "Montserrat",
        fontSize: "15px",
        lineHeight: "21px",
        color: "#76D0D5",
        fontWeight: "700",
        padding: "7px",
        paddingRight: "20px",
        paddingLeft: "20px",
        border: "1px solid transparent",
        borderRadius: 25,
        marginLeft: 10,
        [theme.breakpoints.down("sm")]: {
            marginLeft: 0,
            marginRight: "0 !important",
            fontSize: "16px",
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: 10,
            padding: "6px",
            paddingRight: "14px",
            paddingLeft: "14px",
            width: "39px",
            height: "30px",
        },
    },
    priceButton: {
        background:
      "linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)",
        border: !isDark ? "1px solid #62686e" : "1px solid rgb(97 86 31 / 37%)",
        color: "rgba(255, 255, 255, 0.5)",
        fontSize: "10px",
        padding: "2px 20px",
    },
    viewTxBtn: {
        background: isDark
            ? "linear-gradient(145deg, rgb(255 255 255 / 13%) 7.41%, rgb(22 22 22 / 18%) 93.56%)"
            : "",
        border: !isDark ? "1px solid #62686e" : "1px solid rgb(118 208 213 / 29%)",
        color: "#76D0D5",
        marginTop: 20,
    },
    txsBar: {
        width: "100%",
    },
    tileTitle: {
        color: "rgb(118, 208, 213)",
        fontSize: "14px",
        fontStyle: "normal",
        fontFamily: "Barlow",
        fontWeight: 600,
        lineHeight: "13px",
        [theme.breakpoints.down("sm")]: {
            fontSize: "10px",
            lineHeight: "10px",
        },
    },
    tokenName: {
        color: "rgba(255, 255, 255, 0.5)",
        fontWeight: "400 !important",
        fontSize: "10px",
    },
    tileSubtitle: {
        color: "white",
        fontSize: "14px",
    },
    toTx: {
        color: "white",
        fontSize: "10px",
        opacity: 0.7,
    },
}));
