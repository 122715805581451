import { takeLatest, call, put } from "redux-saga/effects";
import { getAllTxs, getAllTxsSuccess, getAllTxsFailed } from "./reducers";

import request from "../../utils/apisauce";

function* getAllTxsSaga({ payload }) {
    let action;
    // algorand, solana, ethereum, avalanche, polygon, tron
    let url = `txns/search?&skip=${
        parseInt(payload?.newPage) * parseInt(payload?.rowsPerPage)
    }&take=${payload?.rowsPerPage}&sort=${payload?.sort}${payload?.chain?.length>0?`&chain=${payload?.chain}`:''}`;

    try {
        if (payload?.status && payload?.status !== "all") {
            url += `&status=${payload?.status}`;
        }

        const response = yield call(request, "GET", url);        
        console.log("response", response);
        action = getAllTxsSuccess(response);
    } catch (e) {
        action = getAllTxsFailed(e);
    }
    yield put(action);
}

export default function* saga() {
    yield takeLatest(getAllTxs, getAllTxsSaga);
}
