import React, { useEffect, useState } from "react";
import { Box, TableRow, Typography, useTheme, TableBody, Table, CardMedia, Tooltip, useMediaQuery, Card, TableContainer } from '@mui/material';
import { format as formatTimeAgo } from "timeago.js";
import { Link } from 'react-router-dom';
import Others from "../../reuseable/Others/Others";
import TxnTableHeader from './Headers/txnTableHeader';
import { TblNoData } from './common';
import { TableSort, handleRequestSort } from "./tableSort";
import TxnRow from "./txnRow";
import TxnRowCard from "./txnRowCardv1";
import TxnRowCardV2 from "./txnRowCardv2";
import TxnTableFooter from "./Footers/txnTableFooter";
import TxnTableCardHeader from "./Headers/txnTableCardHeader";

import {
    allWordsCapitalize,
    getChainIcon,
    truncateStr,
} from "../../utils/apiutils";
import { Grid } from "react-loader-spinner";

export default function TxnTableCardTemplate({ 
    theme,
    loading,
    isDark,
    txs,
    classes,
    cx,
    onRequestSort,
    order,
    orderBy,
    filterToggle,
    filter,
    hoveredIndex,
    setHoveredIndex,
    handleClick,
    rowsPerPage,
    stableSort,
    getComparator,
    txLoading,
    chainFilterApplied,
    totalCount,
    page,
    handleChangePage,
    TablePaginationActions,
    refreshTxs,
    smallPoint,
}){

    //Local Constants
    const useTable = useMediaQuery(theme.breakpoints.up('lg'));
    const hasData = txs && txs?.length > 0;
   
    return (
        <>

            {/* //Loading Skeleton */}
            <Box display={loading ? "Block" : "None"}>
                <TblNoData name="NODATAFOUND"
                    isDark={isDark}                
                    msg={ `Data Is Loading...`}
                />
            </Box>

            {/* //Data has been loaded */}
            <Box display={loading ? "None" : "Block"}>
                {!useTable && (
                    <TableContainer style={{ width: "100%", margin: "auto" }}>
                        <Table style={{ position: "relative" }} size="small">
                            <TxnTableCardHeader 
                                cx={cx}
                                classes={classes}
                                order={order}
                                orderBy={orderBy}
                                isDark={isDark}
                                onRequestSort={onRequestSort}
                                filterToggle={filterToggle}
                                filter={filter}
                                hoveredIndex={hoveredIndex}
                                handleClick={handleClick}>                                   
                            </TxnTableCardHeader>
                              
                            <TableBody >
                                {stableSort(txs, getComparator(order, orderBy)).map((txn, index) => (

                                    // //Table Row <=== replace this with card
                                    <TxnRowCardV2 
                                        theme={theme}
                                        key={txn?.BridgeID}
                                        tx={txn}
                                        index={index}
                                        isDark={isDark}
                                        setHoveredIndex={setHoveredIndex}
                                        hoveredIndex={hoveredIndex}
                                        handleClick={handleClick}                                        
                                    />
                                )
                                )}
                            </TableBody>
                        </Table>         
                        <TxnTableFooter 
                            txs={txs}
                            txLoading={txLoading}
                            filter={filter}
                            chainFilterApplied={chainFilterApplied}
                            totalCount={totalCount}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            handleChangePage={handleChangePage}
                            TablePaginationActions={TablePaginationActions}
                            classes={classes}
                            isDark={isDark}
                            refreshTxs={refreshTxs}
                            cx={cx}
                            smallPoint={smallPoint}
                        />
                    </TableContainer>
                )}

                {useTable && (
                    <TableContainer style={{ width: "100%", margin: "auto" }}>
                        <Table style={{ position: "relative" }} size="small">
                            <TxnTableHeader 
                                cx={cx}
                                classes={classes}
                                order={order}
                                orderBy={orderBy}
                                isDark={isDark}
                                onRequestSort={onRequestSort}
                                filterToggle={filterToggle}
                                filter={filter}
                                hoveredIndex={hoveredIndex}
                                handleClick={handleClick}>                                   
                            </TxnTableHeader>
                            <TableBody>
                                {stableSort(txs, getComparator(order, orderBy)).map((txn, index) => (

                                    //Table Row
                                    <TxnRow 
                                        theme={theme}
                                        key={txn?.BridgeID}
                                        tx={txn}
                                        index={index}
                                        isDark={isDark}
                                        setHoveredIndex={setHoveredIndex}
                                        hoveredIndex={hoveredIndex}
                                        handleClick={handleClick}                                        
                                    />
                                )
                                )}
                            </TableBody>
                        </Table>         
                        <TxnTableFooter 
                            txs={txs}
                            txLoading={txLoading}
                            filter={filter}
                            chainFilterApplied={chainFilterApplied}
                            totalCount={totalCount}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            handleChangePage={handleChangePage}
                            TablePaginationActions={TablePaginationActions}
                            classes={classes}
                            isDark={isDark}
                            refreshTxs={refreshTxs}
                            cx={cx}
                            smallPoint={smallPoint}
                        />
                    </TableContainer>
                )}

                {!hasData && (

                //No Data
                    <TblNoData
                        isDark={isDark}
                        msg={`No ${
                            filter === "all"
                                ? ""
                                : allWordsCapitalize(
                                    filter === "failed" ? "incomplete" : filter
                                )
                        } Data Available`}
                    />

                )}
                    
            </Box>

        </>

    )

} 