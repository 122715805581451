import React from "react";
import {
    TimeScale,
    Chart as ChartJS,
    CategoryScale,
    PointElement,
    LinearScale,
    LineElement,
    Tooltip,
    Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { dateTimeFormate } from "../../../utils/apiutils";
import moment from "moment";
import { eachDayOfInterval } from "date-fns";
import { enUS } from "date-fns/locale";
import "chartjs-adapter-date-fns";
import { useMediaQuery } from "@mui/material";

ChartJS.register(
    TimeScale,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Filler
);

ChartJS.defaults.color = "#fff";

export function Graph({ txsHistory, from }) {
    const smallPoint = useMediaQuery((theme) => theme.breakpoints.down("md"));

    console.log("from in graph ====", from);
    let txs = txsHistory?.slice(0, 14) || [];
    txs = txs.reverse();

    const options = {
        responsive: true,
        elements: {
            point: {
                borderWidth: 1,
                borderColor: "#76D0D5",
                pointRadius: 2,
            },
            line: {
                borderWidth: 1.5,
            },
        },
        tooltips: {
            callbacks: {
                label: (tooltipItems, data) => {
                    return (
                        data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index] +
            ""
            // " M"
                    );
                },
            },
        },
        plugins: {
            legend: {
                labels: {
                    // This more specific font property overrides the global property
                    font: {
                        size: from == "ALL_CHARTS" ? 24 : 14,
                        weight: "400",
                    },
                },
            },
            tooltip: {
                backgroundColor: "rgba(255, 255, 255, 0.06)",
                borderColor: "rgba(97, 86, 31, 0.16)",
                borderWidth: 1,
                usePointStyle: false,
                callbacks: {
                    labelPointStyle: function (context) {
                        return {
                            borderWidth: 0,
                        };
                    },
                },
            },
        },
        scales: {
            x: {
                // type: "time",
                // time: {
                //   unit: "week",
                // },
                grid: {
                    display: false,
                },
            },

            y: {
                ticks: {
                    // padding: 5,
                    // Include a dollar sign in the ticks
                    callback: function (value, index, ticks) {
                        // return value + " M";
                        return value;
                    },
                },
                grid: {
                    drawBorder: false,
                    display: false,
                },
            },
        },
        maintainAspectRatio: false,
    };

    const getGradient = (ctx, chartArea) => {
        let width, height, gradient;
        const chartWidth = chartArea.right - chartArea.left;
        const chartHeight = chartArea.bottom - chartArea.top;
        if (!gradient || width !== chartWidth || height !== chartHeight) {
            // Create the gradient because this is either the first render
            // or the size of the chart has changed
            width = chartWidth;
            height = chartHeight;
            gradient = ctx.createLinearGradient(
                0,
                chartArea.bottom,
                0,
                chartArea.top
            );
            gradient.addColorStop(1, "rgb(118 208 213 / 45%)");
            gradient.addColorStop(0, "rgba(137, 137, 137, 0)");
        }

        return gradient;
    };

    const dateFormate = (stringIso) => {
        const date = moment(stringIso);
        // formate date like Nov 9
        return date.format("MMM D");
    };

    const labels = txs.map((tx) => dateFormate(tx.date || ""));

    const mydata = txs.map((tx) => tx.noOfTxs);

    const data = {
        labels,
        datasets: [
            {
                fill: true,
                label: "Transactions",
                data: mydata,
                color: "white",
                borderColor: "#76D0D5",
                backgroundColor: (context) => {
                    const chart = context.chart;
                    const { ctx, chartArea } = chart;
                    if (!chartArea) {
                        // This case happens on initial chart load
                        return null;
                    }
                    const gradient = getGradient(ctx, chartArea);
                    return gradient;
                },
                lineTension: 0.3,
                pointStyle: "none",
            },
        ],
    };

    return (
        <div
            style={{
                height: smallPoint ? "50vh" : "100%",
                width: "100%",
                justifyContent: "center",
            }}
        >
            <Line
                options={options}
                data={data}
                // style={{ maxHeight: from == "ALL_CHARTS" ? "100vh" : "200px",  }}
            >
                <p>Glitter chart</p>
            </Line>
        </div>
    );
}
