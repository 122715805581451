import { createSlice } from "@reduxjs/toolkit";
import { NotificationManager } from "react-notifications";

const slice = createSlice({
    name: "txProfile",
    initialState: {
        loading: false,
        tx: {},
        error: "",
    },
    reducers: {
        getTxProfile: (state) => {
            state.loading = true;
        },
        getTxProfileSuccess: (state, { payload }) => {
            state.loading = false;
            state.tx = payload;
        },
        getTxProfileFailed: (state) => {
            state.loading = false;
            state.tx = {};
        },
    },
});

export const { getTxProfile, getTxProfileSuccess, getTxProfileFailed } =
  slice.actions;

export default slice.reducer;
