import React from "react";
import PropTypes from "prop-types";
import { Typography, IconButton } from "@mui/material";

import discordSvg from "../../assets/images/discord.svg";
import github from "../../assets/images/github.svg";
import linkdin from "../../assets/images/linkdin.svg";
import instagram from "../../assets/images/instagram.svg";
import telegram from "../../assets/images/telegram.svg";
import twitter from "../../assets/images/twitter.svg";
import youtube from "../../assets/images/youtube.svg";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
    footer: {
        //position: "fixed",
        bottom: 0,
        width: "100%",
        paddingBottom: 10,
        zIndex: 40,
        position: "static",
        textAlign: "center",
        marginTop: "60px",
        marginBottom: "15px",
        [theme.breakpoints.down("sm")]: {
            marginTop: "30px",
            marginBottom: 0,
        },
    },
    socialIcon: {
        margin: 10,
        "&:hover": {
            color: "##51d4d8",
        },
        [theme.breakpoints.down("sm")]: {
            margin: 15,
            fontSize: 18,
        },
    },
    iconBtnRoot: {
        [theme.breakpoints.down("sm")]: {
            padding: 1,
        },
    },
    footerText: {
        color: "#000",
        fontFamily: "Barlow",
        fontWeight: "400",
        fontStyle: "normal",
        fontSize: "23px",
        lineHeight: "27px",
        [theme.breakpoints.down("sm")]: {
            fontSize: "16px",
            lineHeight: "10px",
            opacity: 0.6,
        },
    },
    footerTextDark: {
        color: "#ffffff",
        fontFamily: "Barlow",
        fontWeight: "400",
        fontStyle: "normal",
        fontSize: "21px",
        lineHeight: "27px",
        marginTop: "20px",
        marginBottom: "20px",
        opacity: 0.6,
        [theme.breakpoints.down("sm")]: {
            fontSize: "13px",
            lineHeight: "10px",
            marginBottom: "30px",
        },
    },
    mediumImg: {
        width: "35px",
        verticalAlign: "middle",
        marginLeft: 10,
        [theme.breakpoints.down("sm")]: {
            width: "26px",
        },
    },
    discordImg: {
        width: "32px",
        height: "23px",
        verticalAlign: "middle",
        marginLeft: 15,
        [theme.breakpoints.down("sm")]: {
            marginLeft: 15,
            width: "16px",
            height: "16px",
        },
    },
}));

export default function Footer(props) {
    const { classes } = useStyles();
    const { isDark } = props;
    return (
        <div
            className={classes.footer}
            style={{ backgroundColor: isDark ? "transparent" : "transparent" }}
        >
            <div
                //style={{ height: "110px" }}
            >
                <a
                    target="_blank"
                    href="https://discord.com/invite/P3mZkjsQMM"
                    rel="noopener noreferrer"
                >
                    <img
                        alt="discord"
                        target="_blank"
                        href="https://discord.com/invite/P3mZkjsQMM"
                        src={isDark ? discordSvg : discordSvg}
                        className={classes.discordImg}
                    />
                </a>
                <a
                    target="_blank"
                    href="https://github.com/Glitter-Finance"
                    rel="noopener noreferrer"
                >
                    <img
                        alt="discord"
                        target="_blank"
                        href="https://github.com/Glitter-Finance"
                        src={isDark ? github : github}
                        className={classes.discordImg}
                    />
                </a>
                <a
                    target="_blank"
                    href="https://www.linkedin.com/company/glitter-finance"
                    rel="noopener noreferrer"
                >
                    <img
                        alt="linkedin"
                        target="_blank"
                        href="https://www.linkedin.com/company/glitter-finance"
                        src={isDark ? linkdin : linkdin}
                        className={classes.discordImg}
                    />
                </a>
                <a
                    target="_blank"
                    href="https://www.instagram.com/glitter.finance/"
                    rel="noopener noreferrer"
                >
                    <img
                        alt="instagram"
                        target="_blank"
                        href="https://www.instagram.com/glitter.finance/"
                        src={isDark ? instagram : instagram}
                        className={classes.discordImg}
                    />
                </a>
                <a
                    target="_blank"
                    href="https://t.me/GlitterFinanceGlobal"
                    rel="noopener noreferrer"
                >
                    <img
                        alt="telegram"
                        target="_blank"
                        href="https://t.me/GlitterFinanceGlobal"
                        src={isDark ? telegram : telegram}
                        className={classes.discordImg}
                    />
                </a>
                <a
                    target="_blank"
                    href="https://twitter.com/GlitterFinance"
                    rel="noopener noreferrer"
                >
                    <img
                        alt="twitter"
                        target="_blank"
                        href="https://twitter.com/GlitterFinance"
                        src={isDark ? twitter : twitter}
                        className={classes.discordImg}
                    />
                </a>
                <a
                    target="_blank"
                    href="https://www.youtube.com/channel/UCWhZs21w5_9tcd3xtlL13pA"
                    rel="noopener noreferrer"
                >
                    <img
                        alt="youtube"
                        target="_blank"
                        href="https://www.youtube.com/channel/UCWhZs21w5_9tcd3xtlL13pA"
                        src={isDark ? youtube : youtube}
                        className={classes.discordImg}
                    />
                </a>
                <Typography
                    variant="h6"
                    className={isDark ? classes.footerTextDark : classes.footerText}
                >
          2022 Glitter Finance. All Rights Reserved.
                </Typography>
            </div>
        </div>
    );
}
Footer.propTypes = {
    isDark: PropTypes.bool.isRequired,
};
