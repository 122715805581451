import useTxnTableStyles from "./common";
import { useTheme } from "@emotion/react";
import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { TableRow, TableCell } from "@mui/material";
import { format as formatTimeAgo } from "timeago.js";
import { Link } from "react-router-dom";
import { abbreviateAddress, getChainIcon } from "../../utils/apiutils";
import { Tooltip } from "@mui/material";
import { Box } from "@mui/system";

import glitterLogo from "../../assets/images/logo.png";
import arrow_fat_right_filled from "../../assets/images/Indicators/arrow_fat_right_filled.png";

import { checkTypeColor,
    convertUTCtoLocal,
    getStatusIcon, 
    getStatusIconClass,
    getStatusSubText, 
    getStatusTime, 
    getTransferIconClass,
    checkStatusTimeColor,
    getStatusColor } from "../../utils/apiutils";
import Others from "../../reuseable/Others/Others";

import SingletonModule from "../../SingletonModule";

export default function TxnRowCardV2({
    theme,
    isDark,
    tx, 
    index,
    hoveredIndex, 
    setHoveredIndex,
    handleClick }) {

    //const theme = useTheme();
    const { classes } = useTxnTableStyles({ theme });

    const { getTxUrlByChain, getExplorerAddressUrl, getExplorerTxnUrl } = Others({
        isDark,
    });

    return (
        //Table Row
        <TableRow            
            key={tx?.BridgeID}
            mt={index == 0 ? 3 : ""}
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(-1)}
            style={{
                backgroundColor:
                                            hoveredIndex === index
                                                ? "rgba(255,255,255,0.2)"
                                                : "transparent",
                cursor: hoveredIndex === index ? "pointer" : "default",                                            
                height: "60px",
                                           
            }}
            onClick={() => handleClick(tx)}
        >

            {/* Card Cell */}
            <TableCell
                colSpan={3}               
                align="center"
                className={classes.tableCell}        
                style={{ minWidth: "400px",
                    padding: index === 0 ? "8px 0px 0px 0px" : "0px 0px 0px 0px" }}               
            >

                <Box sx={{ display: 'flex', flexDirection: 'column', padding: "4px" }}>

                    {/* Top Box */}
                    <Box sx={{ display: 'flex', flexDirection: 'row', padding: "8px" }}>

                        {/* Status Details */}
                        <div style={{ display: 'flex', width:"180px" }}>
                            <Grid sx={{ display: 'flex', flexDirection: 'row', padding: "4px" }}>

                                {/* Status Logo */}
                                <Grid
                                    item
                                    md={12 / 3}
                                    sm={12 / 3}
                                    lg={12 / 3}
                                    xs={12 / 3}
                                    textAlign="left"
                                    style={{ minWidth: "30px", maxWidth: "30px" }}       
                                >
                                    {/* Status Logo */}                                           
                                    <Tooltip title={tx.statusDescription} >
                                        <div>
                                            <img src={getStatusIcon(tx.status)} height="24" width="24" className={getStatusIconClass(tx.status)}/>
                                            <Typography
                                                component="div"
                                                className={classes.statusIcon}
                                            />
                                        </div>
                                    </Tooltip>
                                </Grid>

                                {/* Status Details */}
                                <Grid
                                    item
                                    md={12 / 3}
                                    sm={12 / 3}
                                    lg={12 / 3}
                                    xs={12 / 3}
                                    textAlign="left"
                                    pl={1}
                                    style={{ minWidth: "140px", paddingRight: "12px", width:"100%" }}       
                                >
                                    {/* Details */}
                                    <Typography component="h6" variant="p" className={classes.tileTitle} paddingBottom="4px">
                                        {getStatusSubText(tx.status, tx.percent)}
                                    </Typography>
                                    <Typography component="h6" variant="p" className={classes.tileSubtitle} color={checkStatusTimeColor(tx.status, tx.initDate, tx.baseToken, tx.initNetwork, tx.completedNetwork)} minHeight="16px" fontSize="0.85em">  
                                        {getStatusTime(tx.status, tx.timeToCompletion, tx.initDate, tx.baseToken, tx.initNetwork, tx.completedNetwork)} &nbsp;
                                    </Typography>                                                
                                                     
                                </Grid>
                     
                            </Grid>
                        </div>

                        {/* Transfer Details */}
                        <Grid sx={{ display: 'flex', flexDirection: 'row', padding: "4px", Width:"300px" }}>
                                                
                            {/* From Chain & Token */}
                            <Grid
                                item
                                md={12 / 3}
                                sm={12 / 3}
                                lg={12 / 3}
                                xs={12 / 3}
                                textAlign="left"
                                pl={1}
                                style={{ minWidth: "90px" }}       
                            >
                                {/* Details */}
                                <div style={{ textAlign: 'Right' }}>
                                    <Typography component="h6" variant="p" className={classes.tileTitle} paddingBottom="4px">
                                        {SingletonModule.getOfficialChainName(tx.initNetwork ?? "")} &nbsp;
                                    </Typography>
                                    <Typography component="h6" variant="p" className={classes.tileSubtitle} color="grey" minHeight="16px" fontSize="0.85em">  
                                        {SingletonModule.getOfficialChainName(tx.initToken ?? "")} &nbsp;
                                    </Typography> 
                                </div>                                                     
                                                     
                            </Grid>

                            {/* From Logo */}
                            <Grid
                                item
                                md={12 / 3}
                                sm={12 / 3}
                                lg={12 / 3}
                                xs={12 / 3}
                                textAlign="right"
                                style={{ minWidth: "30px", maxWidth: "30px", paddingRight: "5px" }}
                            >
                                {/* Init Logo */}                                           
                                <Tooltip title={SingletonModule.getOfficialChainName(tx.initNetwork)} >
                                    <div>
                                        <img src={getChainIcon(tx.initNetwork)} height="24" width="24" />
                                        <Typography
                                            component="div"
                                            className={classes.statusIcon}
                                        />
                                    </div>
                                </Tooltip>
                            </Grid>

                            {/* Arrow */}
                            <Grid item md={1.3} sm={1.3} lg={1.3} xs={1.3} style={{ minWidth: "24px", maxWidth:"24px" }} >
                                <img src={arrow_fat_right_filled} height="20" width="20" className={getTransferIconClass(tx.status)}/>
                            </Grid>

                            {/* To Logo */}
                            <Grid
                                item
                                md={12 / 3}
                                sm={12 / 3}
                                lg={12 / 3}
                                xs={12 / 3}
                                textAlign="right"
                                style={{ minWidth: "30px", maxWidth: "30px" }}
                            >
                                {/* Completed Logo */}                                           
                                <Tooltip title={SingletonModule.getOfficialChainName(tx.completedNetwork)} >
                                    <div>
                                        <img src={getChainIcon(tx.completedNetwork)} height="24" width="24" />
                                        <Typography
                                            component="div"
                                            className={classes.statusIcon}
                                        />
                                    </div>
                                </Tooltip>
                            </Grid>

                            {/* To Chain & Token */}
                            <Grid
                                item
                                md={12 / 3}
                                sm={12 / 3}
                                lg={12 / 3}
                                xs={12 / 3}
                                textAlign="left"
                                pl={1}
                                style={{ minWidth: "80px" }}       
                            >
                                {/* Details */}
                                <div style={{ textAlign: 'Left' }}>
                                    <Typography component="h6" variant="p" className={classes.tileTitle} paddingBottom="4px" >
                                        {SingletonModule.getOfficialChainName(tx.completedNetwork ?? "")} &nbsp;
                                    </Typography>
                                    <Typography component="h6" variant="p" className={classes.tileSubtitle} color="grey" minHeight="16px" fontSize="0.85em">  
                                        {tx.completedToken ?? ""} &nbsp;
                                    </Typography>   
                                </div>                                             
                                                     
                            </Grid>

                        </Grid>

                    </Box>

                    {/* Amount & Transfer Box */}
                    <Box sx={{ display: 'flex', flexDirection: 'row', padding: "8px" }}>
                        
                        {/* Amount */}
                        <div style={{ textAlign: 'left', minWidth:"177px" }}>

                            {/* Amount */}
                            <Typography component="h6" variant="p" className={classes.tileTitle} paddingBottom="4px" >
                                <div style={{ minWidth:"120px" }}>
                                    {tx?.initAmount ? parseFloat(tx?.initAmount ?? 0)?.toFixed(4) : ""}  {tx.baseToken}
                                </div>
                            </Typography>

                            {/* Amount USD*/}
                            <Typography component="h6" variant="p" className={classes.tileSubtitle} color="grey" minHeight="16px" fontSize="0.85em">  
                                                    (${tx?.initAmount ? parseFloat(tx?.initAmountUSD ?? 0)?.toFixed(2) : ""} USD)
                            </Typography>   
                        </div>     

                        {/* Transaction Details */}
                        <Grid sx={{ display: 'flex', flexDirection: 'row', padding: "4px", Width:"300px" }}>
                                                
                            {/* From Txn & Address */}
                            <Grid
                                item
                                md={12 / 3}
                                sm={12 / 3}
                                lg={12 / 3}
                                xs={12 / 3}
                                textAlign="left"
                                pl={1}
                                style={{ minWidth: "120px" }} >
                                                   
                                <div style={{ textAlign: 'Right', paddingRight:"5px" }}>
                                                        
                                    {/* Txn */}
                                    <Typography component="h6" variant="p" className={classes.tileTitle} paddingBottom="4px">
                                        <div>
                                            <text style={{ color: "grey" }} >Txn: </text>
                                            <Link 
                                                className={classes.link} 
                                                to={getExplorerTxnUrl(
                                                    tx?.initNetwork,
                                                    tx?.initID
                                                )} target="_blank" 
                                                style={{ color: theme.palette.success.main }}>                                                                        
                                                {abbreviateAddress(tx.initID) ?? "--"}
                                            </Link>
                                        </div>
                                    </Typography>

                                    {/* Address */}
                                    <Typography component="h6" variant="p" className={classes.tileTitle} style={{ paddingBottom: '4px', fontSize: '0.85em' }}>
                                        <div>
                                            <text style={{ color: "grey" }} >Addr: </text>
                                            <Link 
                                                className={classes.link} 
                                                to={getExplorerAddressUrl(
                                                    tx?.initNetwork,
                                                    tx?.initAddress
                                                )} target="_blank" 
                                                style={{ color: 'rgb(200, 255, 200)' }} >                                                                        
                                                {abbreviateAddress(tx.initAddress) ?? "--"}
                                            </Link>
                                        </div>
                                    </Typography>
                                </div>                                                     
                                                     
                            </Grid>
                                               
                            {/* Arrow */}
                            <Grid item md={1.3} sm={1.3} lg={1.3} xs={1.3} style={{ minWidth: "24px", maxWidth:"24px" }} >
                                <img src={arrow_fat_right_filled} height="20" width="20" className={getTransferIconClass(tx.status)}/>
                            </Grid>

                            {/* To Chain & Token */}
                            <Grid
                                item
                                md={12 / 3}
                                sm={12 / 3}
                                lg={12 / 3}
                                xs={12 / 3}
                                textAlign="left"
                                pl={1}
                                style={{ minWidth: "140px" }} >
                                                
                                {/* Details */}
                                <div style={{ textAlign: 'Left' }}>

                                    {/* Txn */}
                                    <Typography component="h6" variant="p" className={classes.tileTitle} paddingBottom="4px">
                                        <div>
                                            <text style={{ color: "grey" }} >Txn: </text>
                                            <Link 
                                                className={classes.link} 
                                                to={getExplorerTxnUrl(
                                                    tx?.completedNetwork,
                                                    tx?.completedID
                                                )} target="_blank" 
                                                style={{ color: theme.palette.success.main }}>                                                                        
                                                {abbreviateAddress(tx.completedID) ?? ""}
                                            </Link>
                                        </div>
                                    </Typography>

                                    {/* Address */}
                                    <Typography component="h6" variant="p" className={classes.tileTitle} style={{ paddingBottom: '4px', fontSize: '0.85em' }}>
                                        <div>
                                            <text style={{ color: "grey" }} >Addr: </text>
                                            <Link 
                                                className={classes.link} 
                                                to={getExplorerAddressUrl(
                                                    tx?.completedNetwork,
                                                    tx?.completedAddress
                                                )} target="_blank" 
                                                style={{ color: 'rgb(200, 255, 200)' }}>                                                                    
                                                {abbreviateAddress(tx.completedAddress) ?? ""}
                                            </Link>
                                        </div>
                                    </Typography>
                                                    
                                </div>                                             
                                                     
                            </Grid>

                        </Grid>                      
                       
                    </Box>

                    {/* Transfer Time */}
                    <Box sx={{ display: 'flex', padding: "8px" }}>

                        {/* Timestamp */}
                        <div style={{ textAlign: 'left', minWidth:"120px", width:"100%" }}>
                            {/* Details */}
                            <Typography className={classes.tileTitle} paddingBottom="4px">
                                {formatTimeAgo(tx.initDate)}
                            </Typography>
                            <Typography className={classes.tileSubtitle} color="grey" minHeight="16px" fontSize="0.85em">  
                                {convertUTCtoLocal(tx.initDate)} &nbsp;
                            </Typography>                                                
                                                     
                        </div>  

                    </Box>
                   
                </Box>

            </TableCell>
                                       
        </TableRow>
    );
}
